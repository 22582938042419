import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const ReferenceWrapper = styled.div`
  width: 100%;

  ${mediaMinWidth.xs} {
    width: calc(50% - 5px);
    max-width: 220px;
  }
`;

export const Reference = styled.p`
  ${({ theme: { fontSize, fontFamily, borderStyle, borderColors, borderWidth } }) => css`
    display: flex !important;
    align-items: center;
    min-height: 38px;
    border-bottom: ${borderWidth.xxs} ${borderStyle} ${borderColors.primary};
    font: ${fontSize.md} ${fontFamily.primary};

    ${mediaMinWidth.xxl} {
      min-height: 50px;
    }
  `}
`;
