import React from 'react';

import { Navigation } from '../../../declarations/routing';
import { NavigationLink, NavigationListTitle } from './index.style';

interface Props {
  title: string;
  items: Navigation[];
}

const NavigationList = ({ title, items }: Props) => (
  <div>
    <NavigationListTitle>{title}</NavigationListTitle>
    <nav>
      <ul>
        {items.map(({ title, path }) => (
          <li key={title}>
            <NavigationLink href={path}>{title}</NavigationLink>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

export default NavigationList;
