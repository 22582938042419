import React from 'react';
import { find } from 'lodash/fp';
import { PrimarySelect } from './index.style';

interface Props {
  name?: string;
  value?: string;
  showError?: boolean;
  unchangeableValue?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const userTypeOptions = [
  { value: 'engager', label: 'Engager' },
  { value: 'intermediary', label: 'Intermediary' },
  { value: 'worker', label: 'Worker' },
];

const userTypeDefault = { value: 'User Type', label: 'User Type' };

const findOption = (value?: string) => find(['value', value], userTypeOptions);

const UserTypeSelect = (props: Props) => (
  <PrimarySelect
    classNamePrefix="select"
    isDisabled={Boolean(props.unchangeableValue)}
    showError={props.showError}
    name={props.name}
    defaultValue={findOption(props.unchangeableValue) || userTypeDefault}
    options={userTypeOptions}
    isSearchable={false}
    value={findOption(props.value)}
    onChange={({ value }: { value: string }) => props.onChange && props.onChange(value)}
    onBlur={({ value }: { value: string }) => props.onBlur && props.onBlur(value)}
  />
);

export default UserTypeSelect;
