import styled from 'styled-components';

export const Pagination = styled.div`
  padding: 5px 0;
  color: ${({ theme: { keyColors } }) => keyColors.first};
  font: ${({ theme: { fontSize } }) => fontSize.sm} Lato;
  font-weight: bold;
  text-align: right;
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
`;
