import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DialogWithDescription from '../index';
import UploadRow from '../UploadRow';
import ViewRow from '../ViewRow';
import Loader from '../../../../Loader';
import { deleteEngagementDocument, uploadAttachedDocument } from '../../../../../redux/engagement/thunks';
import { AppState } from '../../../../../redux/reducers';
import { EngagementDocumentsInfo } from '../../../../../constants/engagement/actionTypes';
import { Container } from '../index.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AttachDialog = ({ isOpen, onClose }: Props) => {
  const { id } = useParams();
  const isDocumentLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isDocumentLoading);
  const engagementDocumentsInfo = useSelector<AppState, EngagementDocumentsInfo[]>(
    ({ engagement }) => engagement.engagementDocumentsInfo
  );

  const dispatch = useDispatch();

  const handleUpload = (file: File) => {
    dispatch(uploadAttachedDocument(id, file));
  };

  const handleDeleteDocument = (documentId: string) => {
    dispatch(deleteEngagementDocument(documentId, id));
  };

  return (
    <DialogWithDescription
      isOpen={isOpen}
      onClose={onClose}
      title="Attach Miscellaneous File(s)"
      description="Use this function to upload any miscellaneous files connected to this engagement. Please use a descriptive filename"
    >
      <Loader isLoading={isDocumentLoading}>
        <Container>
          {engagementDocumentsInfo.map((doc) => (
            <ViewRow key={doc.id} document={doc} onDelete={handleDeleteDocument} />
          ))}
          <UploadRow title="New File" handleUpload={handleUpload} />
        </Container>
      </Loader>
    </DialogWithDescription>
  );
};

export default AttachDialog;
