import styled, { css } from 'styled-components';
import { FormControl } from '@material-ui/core';
import { mediaMinWidth } from '../../../assets/styles/media';

export const MaterialFormControl = styled(FormControl)``;

export const InputLabel = styled.label`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    font-size: ${fontSize.xs};
    color: ${fontColors.primary};
    font-family: ${fontFamily.primary};
    margin-left: 10px;
    font-weight: bold;

    ${mediaMinWidth.xl} {
      font-size: ${fontSize.sm};
    }
  `}
`;
