import React, { MouseEvent } from 'react';

import { AvatarButton } from './index.style';
import { User } from '../../../constants/user/actionTypes';

interface Props {
  openMenu: (event: MouseEvent<HTMLButtonElement>) => void;
  user: User;
}

const getUserInitials = ({ firstName, lastName }: { firstName: string; lastName: string }): string =>
  firstName ? `${firstName[0]}${lastName[0]}` : '';

const Avatar = ({ openMenu, user }: Props) => (
  <AvatarButton aria-controls="menu" aria-haspopup="true" onClick={openMenu}>
    {getUserInitials(user)}
  </AvatarButton>
);

export default Avatar;
