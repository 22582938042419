import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { AvatarButton } from '../../Header/Avatar/index.style';
import { HeaderContainer, StyledHeader } from '../../Header/index.style';
import Logo from '../../Logo';

interface Props {
  children: React.ReactChild;
}

const SetUpAccountLayout = ({ children }: Props) => {
  const isBigScreen = useMediaQuery({ minWidth: 576 });

  return (
    <>
      {isBigScreen && (
        <HeaderContainer>
          <StyledHeader>
            <Logo />
            <AvatarButton />
          </StyledHeader>
        </HeaderContainer>
      )}
      {children}
    </>
  );
};

export default SetUpAccountLayout;
