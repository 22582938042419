import React from 'react';
import { Spinner } from '../../Spinner/index.styles';

interface Props {
  Component: React.ElementType;
  isLoading: boolean;
  maxWidth: number;
  label: string;
  onConfirm?: () => void;
  type?: string;
}

const ButtonWithSpinner = ({ Component, label, isLoading, maxWidth, onConfirm, type, ...props }: Props) => (
  <Component {...props} maxWidth={maxWidth} disabled={isLoading} onClick={onConfirm} type={type}>
    {label}
    {isLoading && <Spinner size={22} />}
  </Component>
);

export default ButtonWithSpinner;
