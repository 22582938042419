import {
  VERIFY_SDS_REQUEST,
  VERIFY_SDS_SUCCESS,
  VERIFY_SDS_ERROR,
  RESET_VERIFY_SDS_STATE,
  verificationSDSTypes,
  SdsVerificationAcceptanceEntry,
} from '../../constants/sds/actionTypes';

export interface SDSVerificationStore {
  errorMessage: string;
  isLoading: boolean;
  registrationState: string;
  registrationTime?: string;
  chainId: string;
  acceptanceEntries: SdsVerificationAcceptanceEntry[];
}

const initialState: SDSVerificationStore = {
  errorMessage: '',
  registrationState: '',
  registrationTime: '',
  isLoading: false,
  chainId: '',
  acceptanceEntries: [],
};

export default (state: SDSVerificationStore = initialState, action: verificationSDSTypes): SDSVerificationStore => {
  switch (action.type) {
    case VERIFY_SDS_REQUEST: {
      return { ...state, isLoading: true };
    }
    case VERIFY_SDS_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case VERIFY_SDS_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case RESET_VERIFY_SDS_STATE: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};
