import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FormTitleWrapper, LoginAvatar } from './index.styles';
import loginAvatar from '../../../../assets/images/loginMan.png';

interface Props {
  title: string;
}

const LoginFormTitle = (props: Props) => (
  <FormTitleWrapper container justify="space-between">
    <Grid item>
      <Typography component="h1" variant="h5">
        {props.title}
      </Typography>
    </Grid>
    <Grid item>
      <LoginAvatar src={loginAvatar} alt="loginAvatar" />
    </Grid>
  </FormTitleWrapper>
);

export default LoginFormTitle;
