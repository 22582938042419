import React from 'react';

import { Button, Pagination } from './index.style';

interface Props {
  page: number;
  totalPages: number;
  nextPage: () => void;
  previousPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
}

const TablePagination = ({ page, totalPages, previousPage, canNextPage, canPreviousPage, nextPage }: Props) => (
  <Pagination>
    <Button onClick={previousPage} disabled={!canPreviousPage}>
      {'<'}
    </Button>{' '}
    <span>
      <strong>
        {page + 1} of {totalPages}
      </strong>{' '}
    </span>
    <Button onClick={nextPage} disabled={!canNextPage}>
      {'>'}
    </Button>{' '}
  </Pagination>
);

export default TablePagination;
