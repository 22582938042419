import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AccountEdit from './AccountEdit';
import AccountInfo from './AccountInfo';
import Loader from '../../../components/Loader';
import BackButton from '../../../components/BackButton';
import { AccountInfoContent } from '../index.styles';

import { AppState } from '../../../redux/reducers';
import { ExtendedUser, User } from '../../../constants/user/actionTypes';
import { getUserInfo } from '../../../redux/user/thunks';

const Account = () => {
  const [isEditMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector<AppState, ExtendedUser>(({ user }) => user.fullValues);
  const userShort = useSelector<AppState, User>(({ user }) => user.values);
  const isLoading = useSelector<AppState, boolean>(({ user }) => user.isUserLoading);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [userShort]);

  return (
    <Loader isLoading={isLoading}>
      <AccountInfoContent>
        <BackButton onClick={history.goBack} />
        {isEditMode ? (
          <AccountEdit user={user} onCancel={() => setEditMode(false)} />
        ) : (
          <AccountInfo user={user} onEditClick={() => setEditMode(true)} />
        )}
      </AccountInfoContent>
    </Loader>
  );
};

export default Account;
