import React from 'react';
import { SelectComponentsConfig } from 'react-select';

import { PrimarySelect } from '../index.style';
import { StatusSelectWrapper } from './index.style';
import { ErrorMessage } from '../../inputs/InputErrorMessage';

interface Props {
  name: string;
  showError?: boolean;
  defaultValue?: { value: string | null; label: string };
  options: { value: string | null; label: string }[];
  onChange: (value: string) => void;
  defaultLabel?: string;
  components?: SelectComponentsConfig<any>;
}

const StatusSelect = ({ name, showError, onChange, options, defaultValue, ...props }: Props) => (
  <StatusSelectWrapper>
    <PrimarySelect
      classNamePrefix="select"
      name={name}
      showError={showError}
      defaultValue={defaultValue || { value: '', label: 'None' }}
      options={options}
      isSearchable={false}
      onChange={({ value }: { value: string }) => onChange(value)}
      {...props}
    />
    {showError && <ErrorMessage name={name} component="span" />}
  </StatusSelectWrapper>
);

export default StatusSelect;
