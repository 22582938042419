import React from 'react';
import { TableSortLabel } from '@material-ui/core';
import { ColumnInstance } from 'react-table';

interface Props<D extends {}> {
  column: ColumnInstance<D>;
}

const TableHeaderCell = <D extends {}>({ column }: Props<D>) => (
  <th {...column.getHeaderProps([{ className: column.className }])}>
    {column.canSort ? (
      <TableSortLabel
        active={column.isSorted}
        direction={column.isSortedDesc ? 'desc' : 'asc'}
        {...column.getSortByToggleProps()}
      >
        {column.render('Header')}
      </TableSortLabel>
    ) : (
      column.render('Header')
    )}
    <div>{column.canFilter ? column.render('Filter') : null}</div>
  </th>
);

export default TableHeaderCell;
