import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const ExpirationMessageContent = styled.div`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;

    & > span,
    a {
      font-family: ${fontFamily.primary};
      font-size: ${fontSize.sm};
      font-weight: bold;
      text-align: center;
      color: ${fontColors.primary};
    }

    a {
      text-decoration: underline !important;
    }

    & button {
      margin: 40px 0 0;
    }

    ${mediaMinWidth.xxl} {
      padding: 20px;
    }
  `}
`;
