import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';
import { ColumnContainer } from '../../containers';

export const DropzoneContainer = styled(ColumnContainer)`
  position: relative;
  width: 100%;

  & > span {
    bottom: -25px;
    font-weight: 300;

    ${mediaMinWidth.xs} {
      margin: 0 0 0 60px;
    }
  }
`;

export const DropzoneWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;

  ${mediaMinWidth.xs} {
    flex-direction: row;
  }
`;

export const DropzoneInnerWrapper = styled.div`
  width: 100%;
  padding: 10px;
  border: ${({ theme: { borderWidth, borderColors } }) => `${borderWidth.xxs} solid ${borderColors.lightGrey}`};
  border-radius: 0 4px 4px 4px;
`;

export const DropzoneTab = styled.div`
  ${({ theme: { borderWidth, borderColors, bgColors } }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    height: 45px;
    width: 40px;
    border: ${borderWidth.xxs} solid ${borderColors.lightGrey};
    border-radius: 4px 4px 0 0;

    &:after {
      content: '';
      position: absolute;
      bottom: -2.5px;
      width: 100%;
      height: 5px;
      background: ${bgColors.primary};
    }

    & div {
      padding: 10px 0 0 13px;
    }

    ${mediaMinWidth.xs} {
      width: 65px;
      height: 40px;
      align-items: center;
      border-radius: 4px 0 0 4px;

      &:after {
        content: '';
        right: -3px;
        bottom: 0;
        width: 5px;
        height: 100%;
      }

      & div {
        padding: 0 0 0 10px;
      }
    }
  `}
`;

export const DropzoneInputWrapper = styled.div<{ showError: boolean }>`
  ${({ showError, theme: { keyColors, fontSize, fontFamily, borderWidth } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 130px;
    padding: 0 5px;
    background: rgba(116, 63, 133, 0.17);
    border: ${borderWidth.xs} dashed ${showError ? keyColors.third : keyColors.first};

    & p {
      font: ${fontSize.xxxs} ${fontFamily.primary};
      font-weight: 300;
      text-align: center;

      & span {
        display: inline-block;
        width: 100%;

        &:first-child {
          margin: 0 0 20px;
        }
      }
    }
  `}
`;

export const DropzoneLabel = styled.label`
  ${({ theme: { fontSize, fontFamily, fontColors } }) => css`
    display: inline-block;
    margin: 0 0 15px;
    color: ${fontColors.darkGrey};
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: 300;

    ${mediaMinWidth.md} {
      margin: 0 0 20px;
    }

    ${mediaMinWidth.xl} {
      margin: 0 0 30px;
    }
  `};
`;
