import styled from 'styled-components';

import { OUTSIDE } from '../../constants/engagement/constants';
import { mediaMinWidth } from '../../assets/styles/media';

export const IR35Status = styled.span<{ status?: string }>`
  display: inline-block;
  width: 100%;
  max-width: 125px;
  margin: 0 auto;
  padding: 7px 15px;
  color: #46484a;
  background: ${({ status, theme: { keyColors } }) => (status === OUTSIDE ? keyColors.second : keyColors.third)};
  border-radius: 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 1;

  ${mediaMinWidth.lg} {
    padding: 12px 15px;
  }
`;
