import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 0 10px;

  & > button {
    width: 95px;

    ${mediaMinWidth.xl} {
      width: 110px;
    }

    ${mediaMinWidth.xxl} {
      width: 125px;
    }
  }

  & > button {
    margin-right: 10px;

    ${mediaMinWidth.lg} {
      margin-right: 25px;
    }
  }
`;
