import { GET_FILE_REQUEST, GET_FILE_SUCCESS, GET_FILE_ERROR } from '../../constants/preview/actionTypes';

export const getFileRequest = () => ({
  type: GET_FILE_REQUEST,
});

export const getFileSuccess = (payload: { fileURL: string; fileName: string; fileType: string }) => ({
  type: GET_FILE_SUCCESS,
  payload,
});

export const getFileError = (error: string) => ({
  type: GET_FILE_ERROR,
  payload: {
    error,
  },
});
