import React from 'react';

import UploadButton from '../../../buttons/UploadButton';
import { ButtonSecond } from '../../../buttons/index.style';
import { RowWrapper, StyledText, ButtonWithIconWrapper } from './index.styles';

import attachIcon from '../../../../assets/images/bitmap@3x.png';
import Icon from '../../../icons/Icon';

interface Props {
  title: string;
  handleUpload: Function;
}

const UploadRow = ({ handleUpload, title }: Props) => (
  <RowWrapper>
    <StyledText>{title}</StyledText>
    <ButtonWithIconWrapper>
      <UploadButton
        onUpload={handleUpload}
        Button={ButtonSecond}
        buttonProps={{ iconPosition: 'right', icon: attachIcon }}
      />
      <Icon iconSrc={attachIcon} size="lg" />
    </ButtonWithIconWrapper>
  </RowWrapper>
);

export default UploadRow;
