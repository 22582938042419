import React from 'react';
import { Grid } from '@material-ui/core';

import PreviewLayout from '../../../components/layouts/PreviewLayout';
import DocumentViewer from '../../../components/DocumentViewer';
import { usePreviewFile } from '../../../hooks/usePreviewFile';

const PreviewDocument = () => {
  const { fileURl, fileName, fileType, isLoading } = usePreviewFile();

  return (
    <PreviewLayout isLoading={isLoading} fileName={fileName}>
      <Grid item xs={12} xl={9}>
        <DocumentViewer fileURl={fileURl} fileName={fileName} fileType={fileType} />
      </Grid>
    </PreviewLayout>
  );
};

export default PreviewDocument;
