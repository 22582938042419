import React from 'react';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';

import InputSearch, { UserSuggestionSelectedValue } from '../../../../components/inputs/InputSearch';
import PopoverText from './PopoverText';
import { SupplyInput } from '../../../../components/inputs/index.style';

import { AppState } from '../../../../redux/reducers';
import { EngagementParty } from '../../../../constants/engagement/actionTypes';
import { addToPartyEngagement } from '../../../../redux/engagement/thunks';
import {
  buildEngagementParty,
  buildFullName,
  toCleanUserType,
  toSuggestionOption,
} from '../../../../utils/supplyChainUtils';
import { SuggestionUserPayload } from '../../../../constants/user/actionTypes';

interface Props {
  worker?: boolean;
  userType?: string;
}

const SupplyInputRow = ({ worker, userType }: Props) => {
  const dispatch = useDispatch();
  const engagementId = useSelector<AppState, string | undefined>(({ engagement }) => engagement.values.id);
  const engagementParty = useSelector<AppState, EngagementParty | undefined>(
    ({ engagement }) => userType && get(userType, engagement.partiesInfo)
  );
  const suggestionUserList = useSelector<AppState, SuggestionUserPayload[] | undefined>(
    ({ user }) => userType && get(userType, user.suggestionUserList)
  );

  const handleSelect = (value: UserSuggestionSelectedValue) => {
    const engagementParty = buildEngagementParty(value);

    if (userType && engagementParty && engagementId) {
      dispatch(addToPartyEngagement(userType, engagementParty, engagementId));
    }
  };

  const fullName = buildFullName(engagementParty);

  const inputValue = worker ? fullName : engagementParty?.company || '';
  const textValue = worker ? engagementParty?.company || '' : fullName;

  const supplyInput = (
    <PopoverText disabled={textValue.length <= 18} text={textValue}>
      <SupplyInput disabled value={textValue} maxWidth={185} worker={worker} />
    </PopoverText>
  );

  return (
    <>
      {worker && supplyInput}
      <PopoverText disabled={inputValue.length <= 18} text={inputValue}>
        <InputSearch
          onSelect={handleSelect}
          options={toSuggestionOption(suggestionUserList)}
          userType={toCleanUserType(userType) || ''}
          userRole={userType || ''}
          noOptionsText="No User Found"
          value={inputValue}
        />
      </PopoverText>
      {!worker && supplyInput}
    </>
  );
};

export default SupplyInputRow;
