import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../reducers';
import api from '../../services/api';
import { logoutByStatusCode } from '../../utils/auth';
import { RequestWPQPayload, WPQDocumentPayload } from '../../constants/wpq/actionTypes';
import {
  requestWPQRequest,
  requestWPQSuccess,
  requestWPQError,
  getLinkToWPQFormRequest,
  getLinkToWPQFormSuccess,
  getLinkToWPQFormError,
  uploadWPQDocumentRequest,
  uploadWPQDocumentSuccess,
  uploadWPQDocumentError,
} from './actions';
import { getEngagement } from '../engagement/thunks';

export const requestWPQ = (
  requestWPQData: RequestWPQPayload
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(requestWPQRequest());

    await api.wpq.requestWPQ(requestWPQData);

    dispatch(requestWPQSuccess());
  } catch (e) {
    dispatch(requestWPQError('Cannot request WPQ'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const getLinkToWPQForm = (engagementId: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getLinkToWPQFormRequest());

    const { data } = await api.wpq.getLinkToWPQForm(engagementId);

    dispatch(getLinkToWPQFormSuccess(data));
  } catch (e) {
    dispatch(getLinkToWPQFormError('Cannot get link to WPQ form'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const uploadWPQDocument = ({
  file,
  engagementId,
  wpqAuthor,
}: WPQDocumentPayload): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(uploadWPQDocumentRequest());

    const data = new FormData();

    data.append('fileData', file);
    data.append('engagementId', engagementId);
    data.append('wpqAuthor', wpqAuthor);

    await api.wpq.uploadUWPQDocument(data);
    dispatch(uploadWPQDocumentSuccess());
    dispatch(getEngagement(engagementId));
  } catch (e) {
    dispatch(uploadWPQDocumentError('Cannot upload document'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};
