import styled, { css } from 'styled-components';

import { mediaMinWidth } from '../../../assets/styles/media';
import { userTypes } from '../../../constants/user/userTypes';
import { IR_35_NAVIGATOR } from '../../../constants/supplyChain/constants';

export const RowContainer = styled.div<{ userType?: string | undefined }>`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 0 60px;

  ${mediaMinWidth.sm} {
    margin: 0 0 30px;
    align-items: center;
  }

  ${mediaMinWidth.md} {
    ${({ userType }) => css`
      justify-content: space-between;
      margin-bottom: 0.5rem;
      ${(userType === userTypes.ENGAGER || userType === IR_35_NAVIGATOR) && 'margin-bottom: 2.143rem'};
      ${userType === IR_35_NAVIGATOR && 'margin-top: 2.143rem'};
    `}

    div {
      cursor: default;

      ${mediaMinWidth.md} {
        padding-bottom: 0;
      }
    }
  }

  & > div {
    margin-right: 20px;

    ${mediaMinWidth.md} {
      margin-right: 0;
    }
  }
`;
