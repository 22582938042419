import styled from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const InputWrapper = styled.div<{ fullWidth: boolean }>`
  position: relative;
  width: 100%;
  margin: 0 0 10px;

  ${mediaMinWidth.xs} {
    margin: 0;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 5px)')};
  }

  & > span {
    bottom: -18px;
  }
`;
