import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const Column = styled.div<{ width: string; align?: 'center' | 'right' }>`
  ${({ width, align, theme: { fontFamily, fontSize } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    font: ${fontSize.xs} ${fontFamily.primary};

    ${mediaMinWidth.xs} {
      flex-direction: column;
      width: ${width};
      text-align: ${align || 'left'};
    }
  `}
`;

export const ColumnTitle = styled.div`
  ${({ theme: { keyColors } }) => css`
    min-width: 100px;
    padding: 10px 5px;
    color: ${keyColors.first};
    font-weight: 700;

    ${mediaMinWidth.xs} {
      width: 100%;
      border-bottom: 4px solid ${keyColors.first};
    }
  `}
`;

export const ColumnValue = styled.div`
  ${({ theme: { keyColors } }) => css`
    width: 100%;
    padding: 10px 5px;
    color: ${keyColors.first};
    word-break: break-word;

    ${mediaMinWidth.xs} {
      word-break: normal;
    }
  `}
`;
