import styled, { css } from 'styled-components';
import { Form } from 'formik';
import { MaterialFormControl } from '../../components/form/FormControl/index.style';
import { mediaMinWidth } from '../../assets/styles/media';

export const LoginForm = styled(Form)`
  ${({ theme: { fontSize } }) => css`
    margin-top: 10px;
    width: 100%;
    font-size: ${fontSize.xs};

    & > ${MaterialFormControl} {
      margin-top: 25px;
    }

    ${mediaMinWidth.xl} {
      font-size: ${fontSize.sm};
    }
  `}
`;

export const ForgotPassword = styled.div`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 10px;

    button {
      width: fit-content;
      font-size: ${fontSize.xxxs};
      color: ${fontColors.primary};
      font-family: ${fontFamily.primary};
      font-weight: bold;
    }
  `}
`;

export const LearnMoreLink = styled.div`
  ${({ theme: { fontColors, fontFamily } }) => css`
    display: flex;
    justify-content: center;
    margin-top: 30px;

    a,
    && {
      font-size: inherit;
      color: ${fontColors.primary};
      font-family: ${fontFamily.primary};
      font-weight: bold;
      text-align: right;
      white-space: nowrap;
    }

    a {
      margin-left: 5px;
      text-decoration: underline;
    }
  `}
`;

export const ValidationError = styled.div`
  ${({ theme: { keyColors, fontFamily } }) => css`
    font-size: inherit;
    color: ${keyColors.third};
    font-family: ${fontFamily.primary};
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
  `}
`;
