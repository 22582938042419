import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Main from '../../../components/Main';
import EngagementTitle from '../../../components/titles/EngagementTitle';
import SDSUploadForm from './SDSUploadForm';

import { getEngagement } from '../../../redux/engagement/thunks';
import Loader from '../../../components/Loader';
import { AppState } from '../../../redux/reducers';
import { resetEngagementState, resetUploadSdsState } from '../../../redux/engagement/actions';
import ResultDialog from '../../../components/modals/Dialog/ResultDialog';
import BackButton from '../../../components/BackButton';

const SDSUpload = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);
  const successRequest = useSelector<AppState, boolean>(({ engagement }) => engagement.successRequest);
  const errorMessage = useSelector<AppState, string>(({ engagement }) => engagement.errorMessage);

  useEffect(() => {
    dispatch(getEngagement(id));

    return () => {
      dispatch(resetEngagementState());
    };
  }, [id]);

  const redirectToEngagementSummaryPage = () => {
    history.push(`/engagement/${id}`);
  };

  const handleResultDialogClose = () => {
    dispatch(resetUploadSdsState());
    redirectToEngagementSummaryPage();
  };

  return (
    <Loader isLoading={isEngagementLoading}>
      <Main>
        <header>
          <EngagementTitle />
          <BackButton onClick={redirectToEngagementSummaryPage} />
        </header>
        <Main.Content>
          <SDSUploadForm />
          <ResultDialog
            isOpen={successRequest || Boolean(errorMessage)}
            errorMessage={errorMessage}
            onClose={handleResultDialogClose}
            successMessage="Success - the SDS has been uploaded"
          />
        </Main.Content>
      </Main>
    </Loader>
  );
};

export default SDSUpload;
