import React from 'react';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid/Grid';
import { useSelector } from 'react-redux';

import { ButtonSecond } from '../../../../buttons/index.style';
import TextareaWPQ from './WPQTextareaField';

import { AppState } from '../../../../../redux/reducers';
import { WPQValidation } from './validation';
import { FormContent } from '../index.styles';

import { mainTheme } from '../../../../../assets/styles/variables/themes';

const initialUserValues = {
  wpqLink: '',
};

interface Props {
  handleSubmit: (values: { wpqLink: string }) => void;
}

const CustomUrlForm = ({ handleSubmit }: Props) => {
  const { buttonMaxSize } = mainTheme;
  const baseLink = useSelector<AppState, string>(({ wpq }) => wpq.baseLink);
  const isLoading = useSelector<AppState, boolean>(({ wpq }) => wpq.isLoading);

  return (
    <Formik
      initialValues={initialUserValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={WPQValidation(baseLink)}
    >
      <Form id="customURL">
        <FormContent>
          <Grid item xs={12} sm={9}>
            <TextareaWPQ />
          </Grid>
          <ButtonSecond maxWidth={buttonMaxSize.xs} type="submit" disabled={isLoading}>
            invite
          </ButtonSecond>
        </FormContent>
      </Form>
    </Formik>
  );
};

export default CustomUrlForm;
