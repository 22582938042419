import {
  SET_USER_DATA,
  GET_USER_DATA,
  USER_REQUEST_FAIL,
  User,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  INFO_DIALOG_CLOSE,
  UPDATE_USER_REQUEST,
  GET_SUGGESTION_USER_LIST_REQUEST,
  GET_SUGGESTION_USER_LIST_ERROR,
  GET_SUGGESTION_USER_LIST_SUCCESS,
  RESET_USER_STATE,
  UPDATE_USER_SUCCESS,
  SET_FULL_USER_DATA,
  ExtendedUser,
  GET_USER_DOCUMENTS_REQUEST,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_ERROR,
  AllUserDocuments,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SuggestionUserPayload,
  UPLOAD_REQUIRE_DOCUMENT_REQUEST,
} from '../../constants/user/actionTypes';

export const setUserData = (payload: User) => ({
  type: SET_USER_DATA,
  payload,
});

export const setFullUserData = (payload: ExtendedUser) => ({
  type: SET_FULL_USER_DATA,
  payload,
});

export const getUserData = () => ({
  type: GET_USER_DATA,
});

export const updateUserRequest = () => ({
  type: UPDATE_USER_REQUEST,
});

export const updateUserSuccess = (payload: User) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const userRequestFail = (message: string) => ({
  type: USER_REQUEST_FAIL,
  payload: {
    errorMessage: message,
  },
});

export const inviteUserRequest = () => ({
  type: INVITE_USER_REQUEST,
});

export const inviteUserSuccess = () => ({
  type: INVITE_USER_SUCCESS,
});

export const inviteUserError = (message: string) => ({
  type: INVITE_USER_ERROR,
  payload: {
    errorMessage: message,
  },
});

export const infoDialogClose = () => ({
  type: INFO_DIALOG_CLOSE,
});

export const getSuggestionUserListRequest = (userType: string) => ({
  type: GET_SUGGESTION_USER_LIST_REQUEST,
  payload: {
    userType,
  },
});

export const resetUserState = () => ({
  type: RESET_USER_STATE,
});

export const getSuggestionUserListSuccess = (suggestionUserList: SuggestionUserPayload[], userType: string) => ({
  type: GET_SUGGESTION_USER_LIST_SUCCESS,
  payload: {
    suggestionUserList,
    userType,
  },
});

export const getSuggestionUserListError = (errorMessage: string, userType: string) => ({
  type: GET_SUGGESTION_USER_LIST_ERROR,
  payload: {
    errorMessage,
    userType,
  },
});

export const getUserDocumentsRequest = () => ({
  type: GET_USER_DOCUMENTS_REQUEST,
});

export const getUserDocumentsSuccess = (userDocuments: AllUserDocuments) => ({
  type: GET_USER_DOCUMENTS_SUCCESS,
  payload: {
    userDocuments,
  },
});

export const getUserDocumentsError = (message: string) => ({
  type: GET_USER_DOCUMENTS_ERROR,
  payload: {
    errorMessage: message,
  },
});

export const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = (errorMessage: string) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { errorMessage },
});

export const uploadRequireDocumentRequest = () => ({
  type: UPLOAD_REQUIRE_DOCUMENT_REQUEST,
});

export const uploadRequireDocumentSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const uploadRequireDocumentError = (errorMessage: string) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: { errorMessage },
});
