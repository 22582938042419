import React from 'react';
import { TextContainer } from './index.styles';

interface Props {
  content: React.ReactNode;
  firstName: string;
}

const SDSText = ({ firstName, content }: Props) => (
  <TextContainer>
    <p>Dear {firstName},</p>
    {content}
    <p>Alternatively, please do not hesitate to contact us should you wish to discuss our decision.</p>
    <p>
      Kind Regards,
      <br />
      IR35 Navigator Limited
    </p>
  </TextContainer>
);

export default SDSText;
