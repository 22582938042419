import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';

import { verifySDSRequest, verifySDSSuccess, verifySDSError } from './actions';
import api from '../../services/api';
import { logoutByStatusCode } from '../../utils/auth';

export const verifySDSFile = (sdsFile: {
  arrayBuffer: ArrayBuffer;
  type: string;
  name: string;
}): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(verifySDSRequest());

    const file = new File([sdsFile.arrayBuffer], sdsFile.name, {
      type: sdsFile.type,
    });

    const formData = new FormData();
    formData.append('fileData', file);

    const { data } = await api.engagement.verifySDSFile(formData);

    dispatch(verifySDSSuccess(data));
  } catch (e) {
    dispatch(verifySDSError('Error connection'));
    logoutByStatusCode(dispatch, e.response?.status);
  }
};
