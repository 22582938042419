import { combineReducers } from 'redux';

import user from './user/reducers';
import engagement from './engagement/reducers';
import engagements from './engagements/reducers';
import auth from './auth/reducers';
import preview from './preview/reducers';
import documents from './documents/reducers';
import wpq from './wpq/reducers';
import sdsVerification from './sdsVerification/reducers';

const rootReducer = combineReducers({
  user,
  engagement,
  engagements,
  auth,
  preview,
  documents,
  wpq,
  sdsVerification,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
