import axios from 'axios';
import { Engagement, GetEngagementsBodyRequest } from '../../constants/engagements/actionTypes';

export interface EngagementsPayload {
  results: Engagement[];
  totalCount: number;
}

const getEngagements = (body: GetEngagementsBodyRequest): Promise<{ data: EngagementsPayload }> =>
  axios.post(`/search-engagements`, body, { headers: { 'Accept-Language': '*' } });

export default {
  getEngagements,
};
