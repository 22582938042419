import styled from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 65px;
  padding: 5px 25px;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
  }

  ${mediaMinWidth.xl} {
    min-height: 80px;
  }
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: -1px;
  background: #fff;
  z-index: 1200;
`;
