import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckIcon from '@material-ui/icons/Done';

export const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0 10px 0 0;
    padding: 0;
  }

  .MuiIconButton-label {
  ${({ theme: { bgColors, keyColors } }) => css`
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: ${bgColors.primary};
    border: 1px solid ${keyColors.first};
    border-radius: 5px;
    line-height: 12px;
  `}
`;

export const Checkmark = styled(CheckIcon)`
  color: ${({ theme: { fontColors } }) => fontColors.primary};
`;

export const CheckboxLabel = styled(FormControlLabel)`
  && {
    margin: 0;
  }

  .MuiTypography-body1 {
    font: ${({ theme: { fontSize } }) => fontSize.xs} ${({ theme: { fontFamily } }) => fontFamily.primary};
    font-weight: bold;
  }
`;
