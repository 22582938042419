import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mediaMinWidth } from '../../assets/styles/media';

export const SDSLink = styled(Link)`
  color: #1a2860;
  font-weight: 400;
  text-decoration: underline;

  &:visited {
    color: #1a2860;
  }
`;

export const DashboardContent = styled.div`
  padding: 20px 0 0;

  ${mediaMinWidth.lg} {
    max-width: 995px;
    width: 100%;
    margin: 0 auto;
  }

  ${mediaMinWidth.xl} {
    max-width: 1095px;
  }

  ${mediaMinWidth.xxl} {
    max-width: 1255px;
  }

  ${mediaMinWidth.xxxl} {
    max-width: 1335px;
  }
`;
