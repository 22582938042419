import axios from 'axios';
import { LoginPayload } from '../../constants/auth/actionTypes';
import { getStoredTicket } from '../../utils/auth';

interface LoginResponse {
  data: {
    ticket: string;
  };
}

const getTicket = (auth: LoginPayload): Promise<LoginResponse> =>
  axios.post('/login', {
    userName: auth.email,
    password: auth.password,
  });

const logout = () => {
  const ticket = getStoredTicket();

  return axios.delete(`/logout/${ticket}`);
};

export default {
  getTicket,
  logout,
};
