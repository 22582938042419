import axios from 'axios';

interface Params {
  attach?: boolean;
  noCache?: string;
}

const getDocument = (
  id: string,
  params: Params = {}
): Promise<{ data: Blob; headers: { 'content-disposition': string } }> =>
  axios.get(`/content/${id}`, { params: { ...params, c: 'force', attach: true }, responseType: 'blob' });

export default { getDocument };
