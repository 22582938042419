import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid/Grid';

import { mediaMinWidth } from '../../../../assets/styles/media';

export const GridContainer = styled(Grid)`
  position: relative;
  align-items: flex-end;

  button,
  a {
    ${mediaMinWidth.xl} {
      margin-left: auto;
    }
  }
`;

export const TextContainerUnderButton = styled.p`
  ${({ theme: { fontFamily, fontSize } }) => css`
    position: absolute;
    bottom: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100px;
    padding: 5px 10px;

    ${mediaMinWidth.xs} {
      max-width: 125px;
    }

    ${mediaMinWidth.xl} {
      max-width: 150px;
    }

    span {
      padding: 0 5px 0 0;
      font: ${fontSize.sm} ${fontFamily.primary};
      font-style: italic;
      font-weight: 300;

      ${mediaMinWidth.xs} {
        padding: 0 10px 0 0;
      }
    }

    img {
      min-width: 1.286rem;
    }
  `}
`;
