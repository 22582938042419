import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormikValues } from 'formik';

import Dialog from '../index';
import DialogBackTitle from '../DialogBackButton';
import BasicUrlForm from './WPQBasicUrlForm/index';
import CustomUrlForm from './WPQCustomUrlForm/index';
import { Paragraph, BodyWrapper } from './index.styles';
import ResultDialog from '../ResultDialog';

import { mainTheme } from '../../../../assets/styles/variables/themes';

import { requestWPQ } from '../../../../redux/wpq/thunks';
import { EngagementParty } from '../../../../constants/engagement/actionTypes';
import { getPartyTitle } from '../../../../utils/supplyChainUtils';
import { AppState } from '../../../../redux/reducers';
import { resetWPQSuccessResult } from '../../../../redux/wpq/actions';
import { getEngagement } from '../../../../redux/engagement/thunks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  partyTitle?: string | null;
  requestFor: EngagementParty;
}

const WPQDialog = ({ requestFor, isOpen, onClose }: Props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const successRequest = useSelector<AppState, boolean>(({ wpq }) => wpq.successRequest);
  const errorMessage = useSelector<AppState, string>(({ wpq }) => wpq.errorMessage);

  const handleSubmit = (values: FormikValues) => {
    dispatch(
      requestWPQ({
        wpqLink: values.wpqLink,
        wpqAuthor: requestFor.userName,
        engagementId: id,
      })
    );
  };

  const handleResultDialogClose = () => {
    dispatch(resetWPQSuccessResult());
    dispatch(getEngagement(id));
    onClose();
  };

  return (
    <Dialog
      maxWidth={mainTheme.dialogMaxSize.md}
      isOpen={isOpen}
      dialogTitle={<DialogBackTitle title="Working Practices Questionnaire" onClick={onClose} />}
    >
      <BodyWrapper>
        <h2>{getPartyTitle(requestFor)}</h2>
        <Paragraph>
          Send an invite to the standard blank questionnaire, or use a custom invite in the text box below.
        </Paragraph>
        <BasicUrlForm handleSubmit={handleSubmit} />
        <Paragraph>OR</Paragraph>
        <CustomUrlForm handleSubmit={handleSubmit} />
      </BodyWrapper>
      <ResultDialog
        isOpen={successRequest || Boolean(errorMessage)}
        errorMessage={errorMessage}
        onClose={handleResultDialogClose}
        successMessage="Success - Your WPQ Invite has been sent"
      />
    </Dialog>
  );
};

export default WPQDialog;
