import styled from 'styled-components';
import { Form } from 'formik';

import { mediaMinWidth } from '../../../../assets/styles/media';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const DropzoneContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 30px auto 0;

  ${mediaMinWidth.md} {
    margin: 70px 0 0;
  }

  ${mediaMinWidth.xl} {
    margin: 100px 0 0;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0 0;
  padding: 0 40px;

  ${mediaMinWidth.md} {
    flex-wrap: nowrap;
    justify-content: center;
    margin: 80px 0 0;
  }
`;

export const InfoFormWrapper = styled.div<{ rightMargin?: boolean }>`
  max-width: 700px;
  width: 100%;
  margin: ${({ rightMargin }) => (rightMargin ? '0 0 0 0' : '0 0')};

  ${mediaMinWidth.lg} {
    max-width: 800px;
  }

  ${mediaMinWidth.xl} {
    max-width: 930px;
  }
`;

export const FormRow = styled.div<{ noBorders?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font: ${({ theme: { fontFamily, fontSize } }) => `${fontSize.xs} ${fontFamily.primary}`};
  font-weight: 500;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
  }
`;

export const FormColumn = styled.div<{ alignLeft?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 0 15px;
  line-height: 1;

  ${mediaMinWidth.xs} {
    flex-direction: column;
    width: 25%;
    margin: 0;
    text-align: ${({ alignLeft }) => (alignLeft ? 'left' : 'center')};
  }
`;

export const FieldTitle = styled.div`
  min-width: 100px;
  margin: 0 20px 0 0;
  padding: 10px 0;
  color: ${({ theme: { keyColors } }) => keyColors.first};
  line-height: 1;

  ${mediaMinWidth.xs} {
    min-width: unset;
    width: 100%;
    margin: 0;
    padding: 10px 0 20px;
    border-bottom: ${({ theme: { borderWidth, borderColors } }) => `${borderWidth.xxs} solid ${borderColors.primary}`};
  }
`;

export const FieldValue = styled.div<{ alignLeft?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px 0;
  font-weight: 300;

  ${mediaMinWidth.xs} {
    padding: 20px 0 0;
    justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'center')};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 50px auto 0;

  ${mediaMinWidth.md} {
    margin: 50px 0 0 20px;
  }
`;
