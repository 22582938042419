import React from 'react';
import { FormikValues } from 'formik';
import InputField from '../../inputs/InputField';
import { formFields } from '../../../constants/inviteUser/constants';
import { userTypes } from '../../../constants/user/userTypes';
import { mainTheme } from '../../../assets/styles/variables/themes';
import { FormRow, FormTitle } from '../index.styles';

interface Props {
  formikValues: FormikValues;
  isEdit?: boolean;
}

const UserRows = ({ isEdit, formikValues }: Props) => (
  <>
    {formFields.map((fields) => [
      !(isEdit && fields[0]?.skipEdit) && (
        <FormRow key={fields[0].name} isTextRow={fields[0].isTitle}>
          {fields[0].isTitle ? (
            <FormTitle>{fields[0].name}</FormTitle>
          ) : (
            fields.map((field) => (
              <InputField
                key={field.name}
                name={field.name}
                fullWidth={field.fullWidth}
                maxFieldWidth={!field.fullWidth ? mainTheme.inputMaxSize.lg : undefined}
                placeholder={`${field.placeholder}${
                  field.userCheck && formikValues.userType !== userTypes.WORKER ? '*' : ''
                }`}
              />
            ))
          )}
        </FormRow>
      ),
    ])}
  </>
);

export default UserRows;
