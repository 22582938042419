import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import JotformEmbed from 'react-jotform-embed';

import Main from '../../components/Main';
import Loader from '../../components/Loader';
import EngagementTitle from '../../components/titles/EngagementTitle';
import { JotContainer } from './index.styles';

import { getLinkToWPQForm } from '../../redux/wpq/thunks';
import { AppState } from '../../redux/reducers';
import { getEngagement } from '../../redux/engagement/thunks';
import { resetEngagementState } from '../../redux/engagement/actions';

const WPQ = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const wpqLink = useSelector<AppState, string>(({ wpq }) => wpq.wpqLink);
  const isLoading = useSelector<AppState, boolean>(({ wpq }) => wpq.isLoading);
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);

  useEffect(() => {
    dispatch(getLinkToWPQForm(id));
    dispatch(getEngagement(id));

    return () => {
      dispatch(resetEngagementState());
    };
  }, [id]);

  return (
    <Loader isLoading={isLoading || isEngagementLoading}>
      <Main>
        <header>
          <EngagementTitle />
        </header>
        <Main.Content>
          <JotContainer>
            <JotformEmbed src={wpqLink} />
          </JotContainer>
        </Main.Content>
      </Main>
    </Loader>
  );
};

export default WPQ;
