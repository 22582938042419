import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const PreviewAside = styled.aside`
  width: 100%;
  padding: 0 20px 0 0;
  font-size: 20px;

  & > div {
    display: flex;
    flex-direction: column;
    max-width: 355px;
    font-weight: 300;

    & > button {
      align-self: center;
      margin-top: 30px;
    }
  }

  ${mediaMinWidth.xl} {
    max-height: calc(100vh - 205px);
    overflow-y: auto;
  }
`;

export const RecipientsBlock = styled.div`
  margin: 20px 0 20px;
`;

export const RecipientsEmailBlock = styled.div<{ first?: boolean }>`
  ${({ theme: { fontColors }, first }) => css`
    color: ${fontColors.white};
    background-color: ${first ? '#4bb18e' : '#b292c3'};
    border-radius: 4px;
    padding: 10px;

    img {
      margin-bottom: 3px;
    }

    span {
      font-weight: 700;
      padding: 0 10px;
    }
  `}
`;

export const RecipientsItemsBlock = styled.div`
  max-width: 355px;
  margin: 10px 0 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;
