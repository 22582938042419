import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const Button = styled.button`
  ${({ theme: { bgColors, borderWidth, borderColors, outlineColor } }) => css`
    width: 35px;
    height: 35px;
    margin: 15px 25px 0 0;
    background: ${bgColors.primary};
    border: ${borderWidth.xs} solid ${borderColors.black};
    border-radius: 50%;

    svg {
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
      outline: ${outlineColor} auto 0.5px;
      outline-offset: 0;
    }

    ${mediaMinWidth.xl} {
      width: 40px;
      height: 40px;
    }
  `}
`;
