import styled from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const StyledSection = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0 15px;
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 0 0 10px;

  ${mediaMinWidth.xs} {
    padding: 0 0 0 25px;
  }

  ${mediaMinWidth.sm} {
    padding: 0 0 0 45px;
    flex-direction: row;
    justify-content: space-between;
  }
`;
