import React, { useEffect } from 'react';
import UserTypeSelect from '../../../../selects/UserTypeSelect';
import { ErrorMessage } from '../../../../inputs/InputErrorMessage';
import { FormTitle } from '../../../../form/index.styles';
import { SelectValidationContainer, TitleContainer, UserTypeContainer } from './index.styles';

interface Props {
  onChange: (value: string) => void;
  value?: string;
  showError?: boolean;
  unchangeableValue?: string;
}

const UserTypePicker = ({ unchangeableValue, onChange, value, showError }: Props) => {
  useEffect(() => {
    if (unchangeableValue && !value) {
      onChange(unchangeableValue);
    }
  }, [value]);

  return (
    <UserTypeContainer>
      <TitleContainer>
        <FormTitle>User</FormTitle>
      </TitleContainer>
      <SelectValidationContainer>
        <UserTypeSelect
          showError={showError}
          name="userType"
          unchangeableValue={unchangeableValue}
          value={value}
          onChange={onChange}
        />
        <ErrorMessage name="userType" component="span" />
      </SelectValidationContainer>
    </UserTypeContainer>
  );
};

export default UserTypePicker;
