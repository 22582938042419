import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { isNumber } from 'lodash/fp';

import { mediaMinWidth } from '../../../../../../assets/styles/media';

export const StyledCheckbox = styled(Checkbox)<{ maxWidth?: number }>`
  ${({ maxWidth, theme: { bgColors, keyColors } }) => css`
    && {
      padding: 0;
    }

    .MuiIconButton-label {
      display: flex;
      max-width: ${isNumber(maxWidth) ? `${maxWidth - 50}px` : 'none'};
      min-height: 50px;
      background-color: ${bgColors.primary};
      border: 7px solid ${keyColors.first};
      border-radius: 0;
      line-height: 12px;

      ${mediaMinWidth.xs} {
        min-height: 50px;
      }

      ${mediaMinWidth.xxl} {
        min-height: 64px;
        max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
      }
    }
  `}
`;
