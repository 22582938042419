import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Props {
  emailInvite: boolean;
  onEmailInviteClick: (value: boolean) => void;
}

const EmailCheckbox = (props: Props) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          name="emailInvite"
          checked={props.emailInvite}
          onChange={(e) => props.onEmailInviteClick(e.target.checked)}
        />
      }
      label="Email Invite"
      labelPlacement="start"
    />
  </div>
);

export default EmailCheckbox;
