import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Main from '../../../components/Main';
import PageTitle from '../../../components/titles/PageTitle';
import { VerificationFormContainer, LeftColumn, RightColumn, InfoMessage } from './index.style';

import SDSVerificationForm from './SDSVerificationForm';
import SDSVerificationResult from './SDSVerificationResult';
import { matchPath } from 'react-router';
import { ROUTES } from '../../../constants/routing';

const SDSVerification = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isPrivateVerificationPage = matchPath(pathname, {
    path: [ROUTES.SDS_VERIFICATION_ON_PLATFORM],
    exact: true,
  });

  const titleProps = isPrivateVerificationPage ? { onBackButtonClick: () => history.goBack() } : {};

  return (
    <Main>
      <header>
        <PageTitle {...titleProps}>
          <PageTitle.Title>Status Determination Statement (SDS) Verification</PageTitle.Title>
        </PageTitle>
      </header>
      <Main.Content>
        <VerificationFormContainer>
          <LeftColumn>
            <SDSVerificationForm />
          </LeftColumn>
          <RightColumn>
            <InfoMessage>
              <p>
                Each and every finalised Status Determination Statement is registered on a publicly verifiable
                blockchain to provide immutable evidence that the IR35 status decision and the reasons for reaching that
                decision were recorded at that point in time.
              </p>
              <p>To verify a Status Determination Statement, please upload your copy of the PDF document.</p>
            </InfoMessage>
          </RightColumn>
        </VerificationFormContainer>
        <SDSVerificationResult />
      </Main.Content>
    </Main>
  );
};

export default SDSVerification;
