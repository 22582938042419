import styled from 'styled-components';
import { MaterialFormControl } from '../../../../../components/form/FormControl/index.style';
import { Form } from 'formik';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const Container = styled.div`
  width: 100%;
  padding: 0 20px 20px;

  ${mediaMinWidth.xs} {
    padding: 0 40px 35px;
  }
`;

export const FormWrapper = styled(Form)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaMinWidth} {
    align-items: center;
  }

  & > ${MaterialFormControl} {
    margin-top: 25px;
    max-width: 320px;
  }
`;

export const ChangePasswordDialogTitle = styled.div`
  width: 100%;

  & > h2 {
    padding: 30px 30px 0;

    & > div {
      display: flex;
      justify-items: center;
    }
  }
`;
