import React from 'react';

import Icon from '../../icons/Icon';
import { StyledButton } from './index.style';

interface Props {
  icon?: string;
  label: string;
  secondaryButton?: boolean;
  onClick?: () => void;
  inRow?: boolean;
  supplyChain?: boolean;
  as?: string | any;
  to?: string;
}

const ButtonAsLink = ({ secondaryButton, icon, label, supplyChain, ...props }: Props) => (
  <StyledButton supplyChain={supplyChain} secondaryButton={secondaryButton} {...props}>
    {icon && <Icon iconSrc={icon} size="lg" />}
    {label}
  </StyledButton>
);

export default ButtonAsLink;
