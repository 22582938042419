export const fillTableDataToPageSize = <D extends {}>(data: D[], pageSize: number): D[] => {
  const dataLength = data.length;
  if (dataLength && dataLength < pageSize) {
    const filledData = [...data];

    for (let i = dataLength; i < pageSize; i++) {
      filledData.push({} as D);
    }

    return filledData;
  }

  return data;
};
