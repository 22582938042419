import React from 'react';
import { FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import UserForm from '../../../../components/form/UserForm';
import { ButtonFirst, ButtonSecond } from '../../../../components/buttons/index.style';
import { FormRow, FormTitle } from '../../../../components/form/index.styles';
import { Container } from '../index.styles';
import { ButtonsWrapper } from './index.styles';

import { updateUser } from '../../../../redux/user/thunks';
import { ExtendedUser } from '../../../../constants/user/actionTypes';
import { AppState } from '../../../../redux/reducers';
import { editUserValidation } from './validation';

import { mainTheme } from '../../../../assets/styles/variables/themes';

interface Props {
  user: ExtendedUser;
  onCancel: () => void;
}

const AccountEdit = ({ onCancel, user }: Props) => {
  const isLoading = useSelector<AppState, boolean>((state) => state.user.isLoading);
  const dispatch = useDispatch();

  const handleSubmit = (formikValues: FormikValues) => {
    dispatch(updateUser({ ...formikValues } as ExtendedUser));
  };

  return (
    <Container>
      <ButtonsWrapper>
        <ButtonFirst disabled={isLoading} onClick={onCancel} maxWidth={mainTheme.buttonMaxSize.sm}>
          cancel
        </ButtonFirst>
        <ButtonSecond disabled={isLoading} maxWidth={mainTheme.buttonMaxSize.sm} form="user-form">
          save
        </ButtonSecond>
      </ButtonsWrapper>
      <UserForm
        isEdit
        handleSubmit={handleSubmit}
        validationSchema={editUserValidation}
        formInitValues={user}
        successMessage="Success - user has been updated"
        onSuccess={onCancel}
        beforeRows={() => (
          <FormRow isTextRow>
            <FormTitle>User</FormTitle>
          </FormRow>
        )}
      />
    </Container>
  );
};

export default AccountEdit;
