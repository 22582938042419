import axios from 'axios';

import auth from './authApi';
import people from './peopleApi';
import engagement from './engagementApi';
import engagements from './engagementsApi';
import documents from './documentsApi';
import person from './personApi';
import preview from './preview';
import wpq from './wpq';

import { getStoredTicket } from '../../utils/auth';

const domain = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_URL : '';
const baseURL = `${domain}/alfresco/s/ir35/`;

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use((config) => {
  const ticket = getStoredTicket();

  const headers = { ...config.headers, Authorization: ticket };

  return {
    ...config,
    headers,
  };
});

export default {
  auth,
  people,
  engagement,
  engagements,
  documents,
  person,
  preview,
  wpq,
};
