import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const StyledMain = styled.div.attrs({
  as: 'main',
})<{ preview?: boolean }>`
  ${({ preview }) => css`
    ${!preview &&
    `
        margin: 0 auto;
        width: 100%;
        padding: 0 10px 20px;
      
      ${mediaMinWidth.sm} {
        padding: 0 10px 20px;
      }
      
       ${mediaMinWidth.lg} {
        max-width: 1100px;
        padding: 0 10px 20px;
      }
      
        ${mediaMinWidth.xl} {
        max-width: 1200px;
      }
      
         ${mediaMinWidth.xxl} {
        max-width: 1360px;
      }
      
      ${mediaMinWidth.xxxl} {
        max-width: 1440px;
 
      }
    `};

    flex: 1;
    display: flex;
    flex-direction: column;
  `};
`;

export const ContentWrapper = styled.div<{ preview?: boolean }>`
  padding: ${({ preview }) => (preview ? '30px 0 0;' : '0')};
  flex: 1;
  display: flex;
  flex-direction: column;
`;
