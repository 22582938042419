import { values } from 'lodash/fp';
import { TICKET, USERNAME } from '../constants/auth';
import { userTypes } from '../constants/user/userTypes';
import { userLogout } from '../redux/auth/actions';

export const getStoredTicket = () => localStorage.getItem(TICKET);

export const checkIsWttAdmin = (userType: string): boolean => values(userTypes).every((type) => type !== userType);

export const checkPermissions = ({ allowedUserTypes, userType }: { allowedUserTypes: string[]; userType: string }) => {
  if (checkIsWttAdmin(userType)) {
    return true;
  }

  return allowedUserTypes.some((item) => item === userType);
};

export const logoutByStatusCode = (dispatch: Function, code: number) => {
  if (code === 401) {
    window.localStorage.removeItem(TICKET);
    window.localStorage.removeItem(USERNAME);
    dispatch(userLogout());
  }
};

export const redirectAfterLogin = (path: string, location: any) =>
  location.state && location.state.from
    ? location.state
    : {
        from: { pathname: location.pathname !== path ? location.pathname : '/', search: location.search },
      };
