import React, { useEffect } from 'react';
import { isEmpty } from 'lodash/fp';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DialogWithDescription from '../index';
import Loader from '../../../../Loader';
import FilteredRow from './FilteredRow';
import { buildContracts } from '../../../../../utils/documents';
import { AppState } from '../../../../../redux/reducers';
import { Contracts } from '../../../../../constants/documents/actionTypes';
import { deleteDocument, getContractsList, uploadContract } from '../../../../../redux/documents/thunks';
import { contractTypesList, documentTypes } from '../../../../../constants/documents/constants';
import { Container } from '../index.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UploadContractsDialog = ({ isOpen, onClose }: Props) => {
  const { id } = useParams();
  const contracts = useSelector<AppState, Contracts>(({ documents }) => documents.contracts);
  const userName = useSelector<AppState, string>(({ user }) => user.values.userName);
  const isContractsLoading = useSelector<AppState, boolean>(({ documents }) => documents.isContractsLoading);
  const isLoading = useSelector<AppState, boolean>(({ documents }) => documents.isLoading);
  const userType = useSelector<AppState, string>(({ user }) => user.values.userType);

  const updatedContracts = buildContracts(contracts);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(getContractsList(id, userName));
    }
  }, [isOpen]);

  const handleUploadRequiredDocument = (contractType: string, contractWith: string) => (file: File) => {
    dispatch(uploadContract({ engagementId: id, contractWith, contractFrom: userName, contractType, file }));
  };

  const handleDeleteDocument = (idDocument: string) => {
    dispatch(
      deleteDocument({ id: idDocument || '', userName, documentType: documentTypes.CONTRACT, idEngagement: id })
    );
  };

  return (
    <DialogWithDescription
      isOpen={isOpen}
      onClose={onClose}
      title="Attach Engagement File(s)"
      description="Please upload the following file(s) connected to this engagement."
    >
      <Loader isLoading={isContractsLoading || isLoading}>
        <Container>
          {isEmpty(updatedContracts) && <div>There are no documents requested at this time</div>}
          {contractTypesList.map((contractType) => (
            <FilteredRow
              key={contractType}
              userType={userType}
              requestedDirectly={updatedContracts}
              contractType={contractType}
              handleUpload={handleUploadRequiredDocument}
              handleDelete={handleDeleteDocument}
            />
          ))}
        </Container>
      </Loader>
    </DialogWithDescription>
  );
};

export default UploadContractsDialog;
