import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Row } from 'react-table';
import { values } from 'lodash/fp';

import Table from '../../../components/Table';
import TableFilter from '../../../components/Table/TableFilter';
import { SDSLink } from '../index.style';
import DashboardButtonsPanel from '../DashboardButtonsPanel';
import { IR35Status } from '../../../components/IR35Status';

import { sdsStatuses, ir35Statuses, statuses, NO_STATUS } from '../../../constants/engagement/constants';
import { Engagement } from '../../../constants/engagements/actionTypes';
import { getEngagements } from '../../../redux/engagements/thunks';
import { AppState } from '../../../redux/reducers';
import { upperCaseFirstLetter } from '../../../utils/engagements';
import { checkIsWttAdmin } from '../../../utils/auth';

const sdsStatusSelectOptions = [
  { value: '', label: 'All' },
  { value: NO_STATUS, label: 'No status' },
  ...values(sdsStatuses),
];
const ir35StatusSelectOptions = [{ value: '', label: 'All' }, ...values(ir35Statuses)];

const columns: Column<Engagement>[] = [
  {
    Header: 'Reference',
    accessor: ({ reference, alternateReference }) => (
      <>
        <p>{reference}</p>
        {alternateReference ? <p>{alternateReference}</p> : ''}
      </>
    ),
  },
  {
    Header: 'Engagement',
    accessor: 'shortDescription',
  },
  {
    Header: 'End Client',
    accessor: 'engager',
  },
  {
    Header: 'Worker',
    accessor: 'worker',
    className: 'center',
  },
  {
    id: statuses.SDS_STATUS,
    className: 'center',
    disableFilters: false,
    Header: () => null,
    Filter: (props) => (
      <TableFilter name={props.column.id} defaultLabel="SDS Status" options={sdsStatusSelectOptions} {...props} />
    ),
    accessor: ({ sdsStatus, sdsDocumentId }) =>
      sdsStatus && (
        <SDSLink id="sdsLink" to={`/preview/${sdsDocumentId}`} onClick={(e) => e.stopPropagation()}>
          {upperCaseFirstLetter(sdsStatus)}
        </SDSLink>
      ),
  },
  {
    id: statuses.IR35_STATUS,
    className: 'center',
    disableFilters: false,
    Header: () => null,
    Filter: (props) => (
      <TableFilter name={props.column.id} defaultLabel="IR35 Status" options={ir35StatusSelectOptions} {...props} />
    ),
    accessor: ({ ir35Status }) =>
      ir35Status && <IR35Status status={ir35Statuses[ir35Status].value}>{ir35Statuses[ir35Status].label}</IR35Status>,
  },
  {
    id: 'startDate',
    Header: 'Start Date',
    className: 'center',
    disableSortBy: false,
    accessor: 'startDate',
  },
];

const EngagementTable = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const engagements = useSelector<AppState, Engagement[]>(({ engagements }) => engagements.results);
  const pageCount = useSelector<AppState, number>(({ engagements }) => engagements.pageCount);
  const isLoading = useSelector<AppState, boolean>(({ engagements }) => engagements.isLoading);
  const userType = useSelector<AppState, string>(({ user }) => user.values.userType);
  const isWttAdmin = checkIsWttAdmin(userType);

  const fetchData = useCallback((params) => {
    dispatch(getEngagements(params));
  }, []);

  const getRowProps = (row: Row<Engagement>) => {
    const id = row.original.id;

    return id
      ? {
          onClick: () => history.push(`/engagement/${id}`),
        }
      : {};
  };

  return (
    <Table<Engagement>
      columns={columns}
      data={engagements}
      isLoading={isLoading}
      fetchData={fetchData}
      pageCount={pageCount}
      getRowProps={getRowProps}
      noDataMessagePrimary="You are not currently connected to any engagements"
      noDataMessageSecondary="The engagements are not found. Please try again"
      controlButtons={isWttAdmin ? <DashboardButtonsPanel /> : null}
    />
  );
};

export default EngagementTable;
