import React, { ReactElement } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRouter';
import { MainRouter } from '../../declarations/routing';
import routes from './routes';
import BaseLayout from '../../components/layouts/BaseLayout';

const MainRouting = () => (
  <BaseLayout>
    <Switch>
      {routes.map(
        (route: MainRouter, i: number): ReactElement => (
          <PrivateRoute key={route.path || i} {...route} />
        )
      )}
      <Redirect to="/" />
    </Switch>
  </BaseLayout>
);

export default MainRouting;
