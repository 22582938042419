import { createGlobalStyle } from 'styled-components';
import { mainTheme } from './variables/themes';
import { mediaMinWidth } from './media';
//fonts
import FaricyNewRegularWoff from '../fonts/FaricyNew-Regular.woff';
import FaricyNewRegularWoff2 from '../fonts/FaricyNew-Regular.woff2';

import FaricyNewMediumWoff from '../fonts/FaricyNew-Medium.woff';
import FaricyNewMediumWoff2 from '../fonts/FaricyNew-Medium.woff2';

import FaricyNewBoldWoff from '../fonts/FaricyNew-Bold.woff';
import FaricyNewBoldWoff2 from '../fonts/FaricyNew-Bold.woff2';

import FaricyNewItalicWoff from '../fonts/FaricyNew-Italic.woff';
import FaricyNewItalicWoff2 from '../fonts/FaricyNew-Italic.woff2';

import FaricyNewLightWoff from '../fonts/FaricyNew-Light.woff';
import FaricyNewLightWoff2 from '../fonts/FaricyNew-Light.woff2';

import FaricyNewLightItalicWoff from '../fonts/FaricyNew-LightItalic.woff';
import FaricyNewLightItalicWoff2 from '../fonts/FaricyNew-LightItalic.woff2';

import EbonyHvWoff from '../fonts/TypeTogether-EbonyHv.woff';
import EbonyHvWoff2 from '../fonts/TypeTogether-EbonyHv.woff2';

import LatoBoldWoff from '../fonts/Lato-Bold.woff';
import LatoBoldWoff2 from '../fonts/Lato-Bold.woff2';

import LatoLightWoff from '../fonts/Lato-Light.woff';
import LatoLightWoff2 from '../fonts/Lato-Light.woff2';

import LatoRegularWoff from '../fonts/Lato-Regular.woff';
import LatoRegularWoff2 from '../fonts/Lato-Regular.woff2';

import LatoSemiboldWoff from '../fonts/Lato-Semibold.woff';
import LatoSemiboldWoff2 from '../fonts/Lato-Semibold.woff2';

export const GlobalStyle = createGlobalStyle`
    //FONTS
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewRegularWoff2}) format('woff2'),
        url(${FaricyNewRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewMediumWoff2}) format('woff2'),
        url(${FaricyNewMediumWoff}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewBoldWoff2}) format('woff2'),
        url(${FaricyNewBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewItalicWoff2}) format('woff2'),
        url(${FaricyNewItalicWoff}) format('woff');
        font-weight: 400;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewLightWoff2}) format('woff2'),
        url(${FaricyNewLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'FaricyNew';
        src: local('FaricyNew'), local('FaricyNew'),
        url(${FaricyNewLightItalicWoff2}) format('woff2'),
        url(${FaricyNewLightItalicWoff}) format('woff');
        font-weight: 300;
        font-style: italic;
    }
    
     @font-face {
        font-family: 'EbonyHv';
        src: local('EbonyHv'), local('EbonyHv'),
        url(${EbonyHvWoff2}) format('woff2'),
        url(${EbonyHvWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Lato';
        src: local('Lato'), local('Lato'),
        url(${LatoBoldWoff2}) format('woff2'),
        url(${LatoBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Lato';
        src: local('Lato'), local('Lato'),
        url(${LatoLightWoff2}) format('woff2'),
        url(${LatoLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    
        @font-face {
        font-family: 'Lato';
        src: local('Lato'), local('Lato'),
        url(${LatoRegularWoff2}) format('woff2'),
        url(${LatoRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Lato';
        src: local('Lato'), local('Lato'),
        url(${LatoSemiboldWoff2}) format('woff2'),
        url(${LatoSemiboldWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
    }
    
    //RESET
    
  /* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline-color: transparent;
  outline-offset: 1px;
  box-shadow: 0px 0px 3px ${mainTheme.borderColors.primary};
}

/* Remove default padding */
ul,
ol {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
  height: 100%;
  font-size: 12px;
  
  ${mediaMinWidth.sm} {
    font-size: 13px;
  }
  
  ${mediaMinWidth.lg} {
    font-size: 14px;
  }

  ${mediaMinWidth.xl} {
    font-size: 15px;
  }
  
  ${mediaMinWidth.xxl} {
    font-size: 16px;
  }
}

//styles for cookie control plugin
#ccc {
  font-size: 13.6px !important;

  h1, h2, h3, h4 {
    font-family: Arial, sans-serif;
  }
  
  #ccc-icon svg {
    fill: #82459b  !important;
  }
  
  button:focus {
    box-shadow: none;
  }
}

/* Set core body defaults */
body {
  min-width: 320px;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: ${mainTheme.fontFamily.primary};
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
  height: 100%;

  a {
    text-decoration: none;
  }
}
    
    h1, h2, h3, h4 {
      font-family: ${mainTheme.fontFamily.secondary};
      font-weight: 400;
    }

    h2 {
      font-size: ${mainTheme.fontSize.lg};
    }


ul,
ol {
  list-style: none;
}



/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  vertical-align: middle;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
`;
