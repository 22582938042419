import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const VerificationResultSectionWrapper = styled.div`
  width: 100%;
`;

export const VerificationResultHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
  }
`;

export const VerificationResultRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 0;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
  }
`;

export const RegistrationHash = styled.span`
  ${({ theme: { fontColors } }) => css`
    display: block;
    color: ${fontColors.darkGrey};
  `}
`;

export const VerificationResultPrompt = styled.div`
  ${({ theme: { fontFamily, fontColors, fontSize } }) => css`
    margin: 0;
    padding: 20px 5px;
    color: ${fontColors.darkGrey};
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: 300;

    word-break: break-word;

    ${mediaMinWidth.xs} {
      word-break: normal;
    }
  `}
`;
