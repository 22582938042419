import styled from 'styled-components';

export const TableFilterWrapper = styled.div`
  .select__control {
    border: none;
    box-shadow: none;

    &:hover {
      border: none;
      cursor: pointer;
    }
  }

  .select__single-value {
    color: ${({ theme: { keyColors } }) => keyColors.first};
  }

  .select__indicator {
    padding: 0;
    color: #6f7d92;
  }
`;
