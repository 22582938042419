import styled, { css } from 'styled-components';
import { ImageSize } from './index';
import { isNumber } from 'lodash';

interface Props extends ImageSize {
  hideIcon?: boolean;
}

export const IconContainer = styled.div<Props>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  visibility: ${({ hideIcon }) => (hideIcon ? 'hidden' : 'visible')};

  &:hover {
    cursor: pointer;
  }

  img {
    ${({ imgHeight, imgWidth, minWidth }) => css`
      height: ${isNumber(imgHeight) ? `${imgHeight}rem` : imgHeight};
      width: ${imgWidth}rem;
      min-width: ${isNumber(minWidth) ? `${minWidth}rem` : 'auto'};
    `}
  }
`;
