import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import SDSPreview from '../SDSPreview';
import SDSText from '../SDSText';
import SDSAcceptExpirationMessage from '../SDSAcceptExpirationMessage';

import { AppState } from '../../../redux/reducers';
import { User } from '../../../constants/user/actionTypes';
import { PartiesInfo } from '../../../constants/engagement/actionTypes';
import { ACCEPT_SDS_PAGE } from '../../../constants/sds/constants';
import { acceptEngagement, getEngagement } from '../../../redux/engagement/thunks';
import { getLowestIntermediary } from '../../../utils/supplyChainUtils';
import { resetEngagementState } from '../../../redux/engagement/actions';

const SDSAccept = () => {
  const { idEngagement, id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector<AppState, User>(({ user }) => user.values);
  const partiesInfo = useSelector<AppState, PartiesInfo | null>(({ engagement }) => engagement.partiesInfo);
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);
  const lowestIntermediary = partiesInfo && getLowestIntermediary(partiesInfo);

  useEffect(() => {
    dispatch(getEngagement(idEngagement));

    return () => {
      dispatch(resetEngagementState());
    };
  }, [idEngagement]);

  const handleFinaliseClick = () => {
    dispatch(acceptEngagement(id));
  };

  const content = (
    <>
      <p>The end client has issued its final Status Determination Statement for your review and acceptance.</p>
      <p>
        Once accepted, the Status Determination Statement will be passed onto the next party in the supply chain beneath
        you.
      </p>
    </>
  );

  const renderSDSText = (firstName: string) => <SDSText firstName={firstName} content={content} />;

  return (
    <Loader isLoading={isEngagementLoading}>
      {lowestIntermediary !== user.userName ? (
        <SDSPreview
          pageType={ACCEPT_SDS_PAGE}
          acceptButtonLabel="accept"
          finaliseSDS={handleFinaliseClick}
          renderSDSText={renderSDSText}
        />
      ) : (
        <SDSAcceptExpirationMessage />
      )}
    </Loader>
  );
};

export default SDSAccept;
