import React from 'react';

import { List } from '../../../index.style';
import { StyledListItem } from './index.style';

import { buildFullName } from '../../../../../utils/supplyChainUtils';
import { EngagementParty } from '../../../../../constants/engagement/actionTypes';

interface ListPartyProps {
  partiesInfo: {
    engager: EngagementParty;
    ir35LTD: EngagementParty;
    worker: EngagementParty;
    intermediaries: EngagementParty[];
  };
}

interface ListPartyItemProps {
  value: string | undefined | null;
}

const ListPartyItem = ({ value }: ListPartyItemProps) => (value ? <StyledListItem>{value}</StyledListItem> : null);

const PartiesList = ({ partiesInfo }: ListPartyProps) => (
  <List>
    <ListPartyItem value={partiesInfo.engager.company} />
    {Boolean(partiesInfo.intermediaries.length) && (
      <li>
        <ul>
          {partiesInfo.intermediaries.map((intermediary) => (
            <ListPartyItem key={intermediary.userName} value={intermediary?.company} />
          ))}
        </ul>
      </li>
    )}
    <ListPartyItem value={partiesInfo.ir35LTD.company} />
    <ListPartyItem value={partiesInfo.worker.company} />
    <ListPartyItem value={buildFullName(partiesInfo.worker)} />
  </List>
);

export default PartiesList;
