export const formInitValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  company: '',
  jobTitle: '',
  telephone: '',
  mobile: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  postcode: '',
  userType: undefined,
};

interface FormFields {
  name: string;
  placeholder?: string;
  fullWidth?: boolean;
  isTitle?: boolean;
  userCheck?: boolean;
  skipEdit?: boolean;
}

export const formFields: Array<Array<FormFields>> = [
  [
    { name: 'firstName', placeholder: 'First Name*' },
    { name: 'lastName', placeholder: 'Last Name*' },
  ],
  [{ name: 'email', placeholder: 'Email Address*', fullWidth: true, skipEdit: true }],
  [{ name: 'password', placeholder: 'Password*', fullWidth: true, skipEdit: true }],
  [{ name: 'Additional', isTitle: true }],
  [
    { name: 'company', placeholder: 'Company', userCheck: true },
    { name: 'jobTitle', placeholder: 'Job Title' },
  ],
  [
    { name: 'telephone', placeholder: 'Contact Number*' },
    { name: 'mobile', placeholder: 'Mobile Number' },
  ],
  [{ name: 'Address', isTitle: true }],
  [
    { name: 'addressLine1', placeholder: 'Address Line 1*' },
    { name: 'addressLine2', placeholder: 'Address Line 2' },
  ],
  [
    { name: 'addressLine3', placeholder: 'Address Line 3' },
    { name: 'postcode', placeholder: 'Postcode*' },
  ],
];
