export const VERIFY_SDS_REQUEST = 'VERIFY_SDS_REQUEST';
export const VERIFY_SDS_SUCCESS = 'VERIFY_SDS_SUCCESS';
export const VERIFY_SDS_ERROR = 'VERIFY_SDS_ERROR';
export const RESET_VERIFY_SDS_STATE = 'RESET_VERIFY_SDS_STATE';

export interface SdsVerificationAcceptanceEntry {
  company: string;
  hash?: string;
  registrationTime?: string;
}

export interface SdsVerificationPayload {
  registrationState: string;
  registrationTime?: string;
  chainId: string;
  acceptanceEntries: SdsVerificationAcceptanceEntry[];
}

export interface SdsVerificationColumn {
  label: string;
  width: string;
  align?: 'center' | 'right';
}

interface VerifySDSRequest {
  type: typeof VERIFY_SDS_REQUEST;
}

interface VerifySDSSuccess {
  type: typeof VERIFY_SDS_SUCCESS;
  payload: SdsVerificationPayload;
}

interface ResetVerifySdsState {
  type: typeof RESET_VERIFY_SDS_STATE;
}

interface VerifySDSError {
  type: typeof VERIFY_SDS_ERROR;
  payload: {
    errorMessage: string;
  };
}

export type verificationSDSTypes = VerifySDSRequest | VerifySDSSuccess | ResetVerifySdsState | VerifySDSError;
