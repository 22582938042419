import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const StyledButton = styled.button<{ inRow?: boolean; secondaryButton?: boolean; supplyChain?: boolean }>`
  ${({ inRow, supplyChain, secondaryButton, theme: { fontFamily, fontSize, fontColors, transition } }) => css`
    padding: ${inRow ? '0 10px !important' : '0'};
    background-color: transparent;
    font-weight: 300;
    font-style: italic;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.sm};
    transition: ${transition};

    &:visited {
      color: ${fontColors.primary};
    }

    &:hover {
      opacity: 0.7;
      transition: ${transition};
    }

    div {
      margin: 0 5px 0 0;
      vertical-align: bottom;
    }

    ${secondaryButton &&
    css`
      width: 100%;
      text-align: left;

      ${mediaMinWidth.sm} {
        width: ${supplyChain ? '100px' : 'auto'};
        margin-left: auto;
        text-align: center;
      }

      ${mediaMinWidth.md} {
        margin-left: 0;
        font-size: ${fontSize.xs};
      }
    `}
  `}
`;
