export const SET_WPQ_BASE_LINK = 'SET_WPQ_BASE_LINK';
export const REQUEST_WPQ_REQUEST = 'REQUEST_WPQ_REQUEST';
export const REQUEST_WPQ_SUCCESS = 'REQUEST_WPQ_SUCCESS';
export const REQUEST_WPQ_ERROR = 'REQUEST_WPQ_ERROR';
export const GET_LINK_TO_WPQ_FORM_REQUEST = 'GET_LINK_TO_WPQ_FORM_REQUEST';
export const GET_LINK_TO_WPQ_FORM_SUCCESS = 'GET_LINK_TO_WPQ_FORM_SUCCESS';
export const GET_LINK_TO_WPQ_FORM_ERROR = 'GET_LINK_TO_WPQ_FORM_ERROR';
export const UPLOAD_WPQ_DOCUMENT_REQUEST = 'UPLOAD_WPQ_DOCUMENT_REQUEST';
export const UPLOAD_WPQ_DOCUMENT_SUCCESS = 'UPLOAD_WPQ_DOCUMENT_SUCCESS';
export const UPLOAD_WPQ_DOCUMENT_ERROR = 'UPLOAD_WPQ_DOCUMENT_ERROR';
export const RESET_WPQ_SUCCESS_RESULT = 'RESET_WPQ_SUCCESS_RESULT';

export interface RequestWPQPayload {
  engagementId: string;
  wpqAuthor: string;
  wpqLink: string;
}

export interface WPQDocumentPayload {
  engagementId: string;
  wpqAuthor: string;
  file: File;
}

interface SetWPQBaseLink {
  type: typeof SET_WPQ_BASE_LINK;
  payload: { baseLink: string; requestFor: string };
}

interface RequestWPQRequest {
  type: typeof REQUEST_WPQ_REQUEST;
}

interface RequestWPQSuccess {
  type: typeof REQUEST_WPQ_SUCCESS;
}

interface RequestWPQError {
  type: typeof REQUEST_WPQ_ERROR;
  payload: { errorMessage: string };
}

interface GetLinkToWPQFormRequest {
  type: typeof GET_LINK_TO_WPQ_FORM_REQUEST;
}

interface GetLinkToWPQFormSuccess {
  type: typeof GET_LINK_TO_WPQ_FORM_SUCCESS;
  payload: { wpqLink: string };
}

interface GetLinkToWPQFormError {
  type: typeof GET_LINK_TO_WPQ_FORM_ERROR;
  payload: { errorMessage: string };
}

interface UploadWPQDocumentRequest {
  type: typeof UPLOAD_WPQ_DOCUMENT_REQUEST;
}

interface UploadWPQDocumentSuccess {
  type: typeof UPLOAD_WPQ_DOCUMENT_SUCCESS;
}

interface UploadWPQDocumentError {
  type: typeof UPLOAD_WPQ_DOCUMENT_ERROR;
  payload: { errorMessage: string };
}

interface ResetWPQSuccessResult {
  type: typeof RESET_WPQ_SUCCESS_RESULT;
}

export type wpqTypes =
  | SetWPQBaseLink
  | RequestWPQRequest
  | RequestWPQSuccess
  | RequestWPQError
  | GetLinkToWPQFormRequest
  | GetLinkToWPQFormSuccess
  | GetLinkToWPQFormError
  | UploadWPQDocumentRequest
  | UploadWPQDocumentSuccess
  | UploadWPQDocumentError
  | ResetWPQSuccessResult;
