import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash/fp';
import { Box } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Main from '../../components/Main';
import EngagementTitle from '../../components/titles/EngagementTitle';

import ChainRow from './ChainInfoRow';
import DashedWrapper from './DashedWrapper';
import BackButton from '../../components/BackButton';
import Loader from '../../components/Loader';
import DeleteEngagementDialog from '../../components/modals/Dialog/EngagementDialog/DeleteEngagementDialog';
import EditEngagementDialog from '../../components/modals/Dialog/EngagementDialog/EditEngagementDialog';

import { AppState } from '../../redux/reducers';
import { useModalToggle } from '../../hooks/useModalToggle';

import { getEngagement } from '../../redux/engagement/thunks';
import { resetEngagementState } from '../../redux/engagement/actions';
import { PartiesInfo } from '../../constants/engagement/actionTypes';
import { supplyUserTypes } from '../../constants/supplyChain/constants';
import { HeaderButtonsWrapper } from './index.style';
import { mainTheme } from '../../assets/styles/variables/themes';

import { ButtonSecond } from '../../components/buttons/index.style';
import AttachDialog from '../../components/modals/Dialog/DialogWithDescription/AttachDialog';

const checkIfUserAdded = (type: string, partiesInfo: PartiesInfo | null) => get(`${type}.firstName`, partiesInfo);

const SupplyChain = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const isLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);
  const partiesInfo = useSelector<AppState, PartiesInfo | null>(({ engagement }) => engagement.partiesInfo);
  const [isDeleteOpen, toggleDeleteModal] = useModalToggle(false);
  const [isEditModalOpen, toggleEditModal] = useModalToggle(false);
  const [isAttachOpen, toggleAttachModal] = useModalToggle(false);

  useEffect(() => {
    dispatch(getEngagement(id));

    return () => {
      dispatch(resetEngagementState());
    };
  }, []);

  const openCloseDeleteModal = useCallback(() => {
    toggleDeleteModal();
    toggleEditModal();
  }, [toggleDeleteModal, toggleEditModal]);

  const onEditDialogClose = () => {
    toggleEditModal();
    dispatch(getEngagement(id));
  };

  return (
    <>
      <Loader isLoading={isLoading}>
        <Main>
          <header>
            <EngagementTitle edit editButtonToggle={toggleEditModal} />
            <HeaderButtonsWrapper>
              <BackButton onClick={() => history.push(`/engagement/${id}`)} />
              <ButtonSecond onClick={toggleAttachModal} maxWidth={mainTheme.buttonMaxSize.xs}>
                attach
              </ButtonSecond>
            </HeaderButtonsWrapper>
          </header>
          <Main.Content>
            <Box pt="2rem" pb="2rem" pl="1.4rem">
              {supplyUserTypes.map((userType) => (
                <ChainRow
                  key={userType.type}
                  userType={userType}
                  partiesInfo={partiesInfo}
                  addedUser={checkIfUserAdded(userType.type, partiesInfo)}
                />
              ))}
              <DashedWrapper />
            </Box>
          </Main.Content>
        </Main>
      </Loader>
      <DeleteEngagementDialog isOpen={isDeleteOpen} onClose={toggleDeleteModal} />
      <EditEngagementDialog
        skipResetEngagement
        isOpen={isEditModalOpen}
        onClose={onEditDialogClose}
        openDeleteModal={openCloseDeleteModal}
      />
      <AttachDialog isOpen={isAttachOpen} onClose={toggleAttachModal} />
    </>
  );
};

export default SupplyChain;
