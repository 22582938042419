import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;

  & > span {
    max-width: 140px;
    margin: 0;
  }

  & > a {
    display: flex;
    padding: 0 0 0 10px;

    ${mediaMinWidth.xl} {
      padding: 0 0 0 20px;
    }
  }
`;

export const NoDecisionStatusMessage = styled.p`
  ${({ theme: { fontSize, keyColors } }) => css`
    color: ${keyColors.lightGrey};
    font-size: ${fontSize.xs};
  `}
`;
