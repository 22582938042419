import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';

import { mediaMinWidth } from '../../../../assets/styles/media';

export const FormTitleWrapper = styled(Grid)`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    width: 100%;

    & > div {
      display: flex;
      align-items: center;
    }

    h1 {
      font-size: ${fontSize.xxl};
      color: ${fontColors.primary};
      font-family: ${fontFamily.secondary};
    }
  `}
`;

export const LoginAvatar = styled.img`
  width: 70px;

  ${mediaMinWidth.xl} {
    width: 100px;
  }
`;
