import styled, { css } from 'styled-components';

import { PrimaryInput } from '../../inputs/index.style';
import { mediaMinWidth } from '../../../assets/styles/media';

export const SearchInputWrapper = styled.div<{ positionAbsolute: boolean }>`
  position: relative;
  max-width: 215px;
  width: 100%;
  margin: ${({ positionAbsolute }) => (positionAbsolute ? '0' : '0 auto 15px')};

  img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
  }

  ${mediaMinWidth.sm} {
    ${({ positionAbsolute }) =>
      positionAbsolute &&
      css`
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      `}
  }

  ${mediaMinWidth.xl} {
    max-width: 315px;
  }
`;

export const SearchInput = styled(PrimaryInput)`
  ${({ theme: { borderStyle, fontColors, fontSize, fontFamily } }) => css`
    padding-right: 35px;
    color: ${fontColors.secondary};
    border: 1px ${borderStyle} ${fontColors.secondary};
    border-radius: 16px;
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: 600;
    text-align: center;

    &::placeholder {
      color: ${fontColors.secondary};
      font-size: ${fontSize.xxs};
    }

    ${mediaMinWidth.xxl} {
      border-radius: 25px;
    }
  `}
`;
