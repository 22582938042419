import React from 'react';

import { StyledTitle } from './index.style';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { checkIsWttAdmin } from '../../../utils/auth';
import { matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants/routing';

const PageTitle = () => {
  const userCompany = useSelector<AppState, string | undefined>(({ user }) => user.values.company);
  const userType = useSelector<AppState, string>(({ user }) => user.values.userType);
  const isWttAdmin = checkIsWttAdmin(userType);

  const { pathname } = useLocation();
  const shouldRenderPageTitle = matchPath(pathname, {
    path: [ROUTES.DASHBOARD, ROUTES.ACCOUNT, ROUTES.DOCUMENTS],
    exact: true,
  });

  return shouldRenderPageTitle ? (
    <StyledTitle>{isWttAdmin ? 'IR35 Navigator Admin' : userCompany || ''}</StyledTitle>
  ) : null;
};

export default PageTitle;
