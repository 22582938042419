import React, { useState } from 'react';
import { FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../index';
import DialogAddUserTitle from './DialogAddUserTitle/index';
import UserTypePicker from './UserTypePicker/index';
import UserForm from '../../../form/UserForm/index';
import { inviteUser } from '../../../../redux/user/thunks';
import { toCleanUserType } from '../../../../utils/supplyChainUtils';
import { NewUser } from '../../../../constants/user/actionTypes';
import { AppState } from '../../../../redux/reducers';
import { inviteUserValidation } from './validation';
import { formInitValues } from '../../../../constants/inviteUser/constants';
import { FormRow } from '../../../form/index.styles';
import { mainTheme } from '../../../../assets/styles/variables/themes';
import { Container } from './index.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userType?: string;
}

const InviteUserDialog = ({ isOpen, onClose, userType }: Props) => {
  const isLoading = useSelector<AppState, boolean>((state) => state.user.isInviteUserLoading);
  const engagementId = useSelector<AppState, string | undefined>(({ engagement }) => engagement.values.id);
  const dispatch = useDispatch();

  const [sendInvite, setSendInvite] = useState(true);

  const handleSubmit = (formikValues: FormikValues) => {
    dispatch(inviteUser({ ...formikValues, sendInvite } as NewUser, userType, engagementId));
  };

  return (
    <Dialog
      maxWidth={mainTheme.dialogMaxSize.md}
      isOpen={isOpen}
      dialogTitle={
        <DialogAddUserTitle
          title="Add User"
          onEmailInviteClick={setSendInvite}
          emailInvite={sendInvite}
          onBackClick={onClose}
          disabled={isLoading}
        />
      }
    >
      <Container>
        <UserForm
          handleSubmit={handleSubmit}
          validationSchema={inviteUserValidation}
          formInitValues={formInitValues}
          successMessage="Success - user has been added"
          onSuccess={onClose}
          beforeRows={(errors, formikValues, setFieldValue) => (
            <FormRow>
              <UserTypePicker
                unchangeableValue={toCleanUserType(userType)}
                showError={Boolean(errors.userType)}
                value={formikValues.userType}
                onChange={(value) => setFieldValue('userType', value)}
              />
            </FormRow>
          )}
        />
      </Container>
    </Dialog>
  );
};

export default InviteUserDialog;
