import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import SDSPreview from '../SDSPreview';
import SDSText from '../SDSText';

import { finaliseEngagement, getEngagement } from '../../../redux/engagement/thunks';
import { FINALISE_SDS_PAGE } from '../../../constants/sds/constants';
import { resetEngagementState } from '../../../redux/engagement/actions';
import { AppState } from '../../../redux/reducers';

const SDSFinalise = () => {
  const { idEngagement } = useParams();
  const dispatch = useDispatch();
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);

  useEffect(() => {
    dispatch(getEngagement(idEngagement));

    return () => {
      dispatch(resetEngagementState());
    };
  }, [idEngagement]);

  const handleFinaliseClick = () => {
    dispatch(finaliseEngagement(idEngagement));
  };

  const content = (
    <>
      <p>We have issued our final Status Determination Statement for your review and acceptance.</p>
      <p>
        Should you accept our status determination, please select the Finalise button whereupon it will be sent to the
        following recipients within the supply chain.
      </p>
    </>
  );

  const renderSDSText = (firstName: string) => <SDSText firstName={firstName} content={content} />;

  return (
    <Loader isLoading={isEngagementLoading}>
      <SDSPreview
        pageType={FINALISE_SDS_PAGE}
        finaliseSDS={handleFinaliseClick}
        acceptButtonLabel="finalise"
        renderSDSText={renderSDSText}
      />
    </Loader>
  );
};

export default SDSFinalise;
