import axios from 'axios';
import { ChangePasswordPayload } from '../../constants/user/actionTypes';
import { USERNAME } from '../../constants/auth';

const changePassword = ({ oldPassword, newPassword, confirmPassword }: ChangePasswordPayload): Promise<any> => {
  const email = localStorage.getItem(USERNAME);

  return axios.post(`/person/changepassword/${email}`, {
    oldpw: oldPassword,
    newpw: newPassword,
    newpwrepeat: confirmPassword,
  });
};

export default {
  changePassword,
};
