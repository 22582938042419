import React from 'react';
import { StyledCheckbox, CheckboxLabel, Checkmark } from './index.style';

interface Props {
  name: string;
  label: string;
}

const Checkbox = ({ label, ...props }: Props) => (
  <CheckboxLabel
    control={<StyledCheckbox disableRipple icon={<span />} checkedIcon={<Checkmark />} {...props} />}
    label={label}
  />
);

export default Checkbox;
