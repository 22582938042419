import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const PrimaryInput = styled.input`
  ${({ theme: { bgColors, fontColors, fontSize } }) => css`
    padding: 5px;
    min-height: 35px;
    width: 100%;
    background-color: ${bgColors.primary};
    color: ${fontColors.primary};
    font-size: ${fontSize.sm};
    font-weight: 300;

    ${mediaMinWidth.xxl} {
      min-height: 50px;
    }
  `}
`;

const PrimaryTextarea = styled.textarea`
  ${({ theme: { bgColors, fontColors, fontSize } }) => css`
    padding: 5px;
    min-height: 50px;
    width: 100%;
    background-color: ${bgColors.primary};
    color: ${fontColors.primary};
    font-size: ${fontSize.sm};
    font-weight: 300;
  `}
`;

export const MainInput = styled(PrimaryInput)<{ showError: boolean; maxWidth?: number }>`
  ${({ showError, maxWidth, theme: { borderStyle, borderColors, keyColors, fontSize, fontColors, fontFamily } }) => css`
    border: none;
    border-bottom: 1px ${borderStyle} ${borderColors.primary};
    font-size: ${fontSize.xs};

    ${mediaMinWidth.xs} {
      max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
    }

    ${mediaMinWidth.xl} {
      font-size: ${fontSize.md};
    }

    &::placeholder {
      font-family: ${fontFamily.primary};
      font-style: italic;
      font-size: ${fontSize.xs};
      font-weight: 300;
      color: ${fontColors.secondary};

      ${mediaMinWidth.xl} {
        font-size: ${fontSize.md};
      }
    }

    ${showError &&
    css`
      border-color: ${keyColors.third};
    `}
  `}
`;

export const MainTextarea = styled(PrimaryTextarea)<{ showError: boolean; maxWidth?: number }>`
  ${({ showError, maxWidth, theme: { borderStyle, borderColors, keyColors, fontSize, fontColors, fontFamily } }) => css`
    border: none;
    font-size: ${fontSize.xs};
    border-bottom: 1px ${borderStyle} ${borderColors.primary};
    max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
    resize: vertical;

    &::placeholder {
      font-family: ${fontFamily.primary};
      font-style: italic;
      font-size: ${fontSize.xs};
      font-weight: 300;
      color: ${fontColors.secondary};

      ${mediaMinWidth.xl} {
        font-size: ${fontSize.md};
      }
    }

    ${mediaMinWidth.xl} {
      font-size: ${fontSize.md};
    }

    ${showError &&
    css`
      border-color: ${keyColors.third};
    `}
  `}
`;

export const LoginInput = styled(PrimaryInput)<{ showError?: boolean }>`
  ${({ showError, theme: { borderStyle, keyColors } }) => css`
    border: 3px ${borderStyle} ${showError ? keyColors.third : keyColors.first};
  `}
`;

export const SetUpInput = styled(PrimaryInput)<{ showError: boolean }>`
  ${({ showError, theme: { borderStyle, keyColors, borderColors } }) => css`
    border: 2px ${borderStyle} ${borderColors.secondary};
    border-radius: 16px;

    ${showError &&
    css`
      border-color: ${keyColors.third};
    `}
  `}
`;

export const SupplyInput = styled(PrimaryInput)<{ worker?: boolean; maxWidth?: number }>`
  ${({ worker, maxWidth, theme: { borderStyle, keyColors, fontSize } }) => css`
    border: none;
    border-bottom: 3px ${borderStyle} ${worker ? keyColors.first : keyColors.second};
    max-width: 100%;
    min-width: 80px;
    margin: 0 5px;
    z-index: 3;
    font-size: ${fontSize.xs};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

    ${mediaMinWidth.xs} {
      max-width: 40%;
    }

    ${mediaMinWidth.md} {
      margin: 0 15px;
    }
  `}
`;
