import {
  AllUserDocuments,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  ExtendedUser,
  GET_SUGGESTION_USER_LIST_ERROR,
  GET_SUGGESTION_USER_LIST_REQUEST,
  GET_SUGGESTION_USER_LIST_SUCCESS,
  GET_USER_DATA,
  GET_USER_DOCUMENTS_ERROR,
  GET_USER_DOCUMENTS_REQUEST,
  GET_USER_DOCUMENTS_SUCCESS,
  INFO_DIALOG_CLOSE,
  INVITE_USER_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  RESET_USER_STATE,
  SET_FULL_USER_DATA,
  SET_USER_DATA,
  SuggestionLoading,
  SuggestionUser,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_REQUIRE_DOCUMENT_ERROR,
  UPLOAD_REQUIRE_DOCUMENT_REQUEST,
  UPLOAD_REQUIRE_DOCUMENT_SUCCESS,
  User,
  USER_REQUEST_FAIL,
  userTypes,
} from '../../constants/user/actionTypes';

export interface UserStore {
  values: User;
  fullValues: ExtendedUser;
  userDocuments: AllUserDocuments;
  isLoading: boolean;
  isUserLoading: boolean;
  isDialogLoading: boolean;
  isInviteUserLoading: boolean;
  successRequest: boolean;
  errorMessage: string;
  suggestionUserList: SuggestionUser;
  suggestionLoading: SuggestionLoading;
}

const shortUserInit = {
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  company: '',
  jobTitle: '',
  userType: '',
  userName: '',
  userFirstLogin: true,
};

const initialState: UserStore = {
  values: shortUserInit,
  fullValues: {
    ...shortUserInit,
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postcode: '',
  },
  userDocuments: { personal: {}, company: {} },
  errorMessage: '',
  isLoading: false,
  isUserLoading: false,
  isInviteUserLoading: false,
  suggestionLoading: {},
  isDialogLoading: false,
  successRequest: false,
  suggestionUserList: {},
};

export default (state: UserStore = initialState, action: userTypes): UserStore => {
  switch (action.type) {
    case GET_USER_DATA: {
      return { ...state, isUserLoading: true };
    }
    case GET_USER_DOCUMENTS_REQUEST:
    case UPLOAD_REQUIRE_DOCUMENT_REQUEST:
    case UPDATE_USER_REQUEST: {
      return { ...state, isLoading: true, errorMessage: '' };
    }
    case UPLOAD_REQUIRE_DOCUMENT_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case GET_USER_DOCUMENTS_ERROR: {
      return { ...state, isLoading: false };
    }
    case INVITE_USER_REQUEST: {
      return { ...state, isInviteUserLoading: true };
    }
    case INVITE_USER_ERROR: {
      return { ...state, ...action.payload, isInviteUserLoading: false };
    }
    case SET_USER_DATA: {
      return { ...state, values: action.payload, errorMessage: '', isUserLoading: false };
    }
    case UPDATE_USER_SUCCESS: {
      return { ...state, values: action.payload, isLoading: false, successRequest: true, errorMessage: '' };
    }
    case SET_FULL_USER_DATA: {
      return { ...state, fullValues: action.payload as ExtendedUser, isUserLoading: false, errorMessage: '' };
    }
    case USER_REQUEST_FAIL: {
      return { ...state, ...action.payload, isLoading: false, isUserLoading: false };
    }
    case INVITE_USER_SUCCESS: {
      return { ...state, isInviteUserLoading: false, successRequest: true, errorMessage: '' };
    }
    case INFO_DIALOG_CLOSE: {
      return { ...state, successRequest: false, errorMessage: '' };
    }
    case GET_SUGGESTION_USER_LIST_REQUEST: {
      return { ...state, suggestionLoading: { [action.payload.userType]: true }, suggestionUserList: {} };
    }
    case GET_SUGGESTION_USER_LIST_SUCCESS: {
      return {
        ...state,
        suggestionUserList: { [action.payload.userType]: action.payload.suggestionUserList },
        suggestionLoading: { [action.payload.userType]: false },
      };
    }
    case GET_SUGGESTION_USER_LIST_ERROR: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        suggestionLoading: { [action.payload.userType]: false },
      };
    }
    case RESET_USER_STATE: {
      return { ...initialState };
    }
    case CHANGE_PASSWORD_REQUEST: {
      return { ...state, isDialogLoading: true };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return { ...state, isDialogLoading: false, successRequest: true, errorMessage: '' };
    }
    case CHANGE_PASSWORD_ERROR: {
      return { ...state, ...action.payload, isDialogLoading: false };
    }
    case GET_USER_DOCUMENTS_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false, errorMessage: '' };
    }
    case UPLOAD_REQUIRE_DOCUMENT_SUCCESS: {
      return { ...state, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
