import React from 'react';
import Grid from '@material-ui/core/Grid';

import { OneSizeGrid } from '../index.style';
import { DashedBox, DoubleSizeGridForDashedBox } from './index.style';

import { supplyChainColumnWidths } from '../../../constants/supplyChain/constants';

const DashedWrapper = () => (
  <Grid container justify="space-between">
    <OneSizeGrid width={supplyChainColumnWidths.partyColumn} />
    <DoubleSizeGridForDashedBox>
      <DashedBox />
    </DoubleSizeGridForDashedBox>
    <OneSizeGrid width={supplyChainColumnWidths.requestInfoColumn} />
    <OneSizeGrid width={supplyChainColumnWidths.wpqColumn} noPadding />
  </Grid>
);

export default DashedWrapper;
