import styled from 'styled-components';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const NavList = styled(MenuList)`
  && {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

export const NavItem = styled(MenuItem).attrs(() => ({
  disableRipple: true,
}))`
  && {
    min-height: unset;
    width: calc(50% - 10px);
    margin: 0 0 20px;
    padding: 0;
    font: ${({ theme: { fontSize } }) => fontSize.xxs} ${({ theme: { fontFamily } }) => fontFamily.primary};
    font-weight: bold;

    &:nth-child(even) {
      margin-left: 20px;
    }

    &:hover {
      cursor: auto;
      background-color: unset;
    }

    & > * {
      color: inherit;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    a {
      text-decoration: none;
    }

    ${mediaMinWidth.xl} {
      font-size: ${({ theme: { fontSize } }) => fontSize.xs};
    }
  }
`;
