export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const AUTH_REQUEST_FAIL = 'AUTH_REQUEST_FAIL';
export const SET_AGREEMENT_STATUS = 'SET_AGREEMENT_STATUS';
export const GET_AGREEMENT_STATUS = 'GET_AGREEMENT_STATUS';
export const UPDATE_AGREEMENT_STATUS_REQUEST = 'UPDATE_AGREEMENT_STATUS_REQUEST';
export const UPDATE_AGREEMENT_STATUS_SUCCESS = 'UPDATE_AGREEMENT_STATUS_SUCCESS';
export const GET_AUTHENTICATED_USER_REQUEST = 'GET_AUTHENTICATED_USER_REQUEST';
export const GET_AUTHENTICATED_USER_SUCCESS = 'GET_AUTHENTICATED_USER_SUCCESS';
export const CHECK_ACCESS_TO_ENGAGEMENT_REQUEST = 'CHECK_ACCESS_TO_ENGAGEMENT_REQUEST';
export const CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS = 'CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS';
export const CHECK_ACCESS_TO_ENGAGEMENT_ERROR = 'CHECK_ACCESS_TO_ENGAGEMENT_ERROR';
export const CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST = 'CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST';
export const CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS = 'CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS';
export const CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL = 'CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface LoginSuccessPayload {
  ticket: string;
}

export interface AgreementPayload {
  agreementStatus: boolean;
}

interface SetUserAgreementStatus {
  type: typeof SET_AGREEMENT_STATUS;
  payload: AgreementPayload;
}

interface GetUserAgreementStatus {
  type: typeof GET_AGREEMENT_STATUS;
}

interface UpdateAgreementStatusRequest {
  type: typeof UPDATE_AGREEMENT_STATUS_REQUEST;
}

interface UpdateAgreementStatusSuccess {
  type: typeof UPDATE_AGREEMENT_STATUS_SUCCESS;
  payload: AgreementPayload;
}

interface AuthRequestFail {
  type: typeof AUTH_REQUEST_FAIL;
  payload: {
    errorMessage: string;
  };
}

interface LoginUserSuccess {
  type: typeof LOGIN_USER_SUCCESS;
  payload: {
    ticket: string;
  };
}

interface SetLoginError {
  type: typeof SET_LOGIN_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface GetAuthenticatedUserRequest {
  type: typeof GET_AUTHENTICATED_USER_REQUEST;
}

interface GetAuthenticatedUserSuccess {
  type: typeof GET_AUTHENTICATED_USER_SUCCESS;
}

interface Logout {
  type: typeof LOGOUT;
}

interface CheckAccessToEngagementRequest {
  type: typeof CHECK_ACCESS_TO_ENGAGEMENT_REQUEST;
}

interface CheckAccessToEngagementSuccess {
  type: typeof CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS;
}

interface CheckAccessToEngagementError {
  type: typeof CHECK_ACCESS_TO_ENGAGEMENT_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface ChangePasswordAfterFirstLoginRequest {
  type: typeof CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST;
}

interface ChangePasswordAfterFirstLoginSuccess {
  type: typeof CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS;
}

interface ChangePasswordAfterFirstLoginError {
  type: typeof CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL;
  payload: {
    errorMessage: string;
  };
}

export type authTypes =
  | Logout
  | SetLoginError
  | LoginUserSuccess
  | GetUserAgreementStatus
  | SetUserAgreementStatus
  | UpdateAgreementStatusSuccess
  | UpdateAgreementStatusRequest
  | GetAuthenticatedUserRequest
  | GetAuthenticatedUserSuccess
  | CheckAccessToEngagementRequest
  | CheckAccessToEngagementSuccess
  | CheckAccessToEngagementError
  | ChangePasswordAfterFirstLoginRequest
  | ChangePasswordAfterFirstLoginSuccess
  | ChangePasswordAfterFirstLoginError
  | AuthRequestFail;
