import React from 'react';

import { RowWrapper, StyledText, ButtonWithIconWrapper } from './index.styles';
import { ButtonSecond } from '../../../buttons/index.style';
import trashBinIcon from '../../../../assets/images/trash-bin.jpg';
import { EngagementDocumentsInfo } from '../../../../constants/engagement/actionTypes';
import { previewButtonProps } from '../../../../utils/preview';
import Icon from '../../../icons/Icon';

interface Props {
  document: EngagementDocumentsInfo;
  hideDeleteButton?: boolean;
  onDelete: (documentId: string) => void;
}

const ViewRow = ({ document, hideDeleteButton, onDelete }: Props) => (
  <RowWrapper>
    <StyledText>{document.name}</StyledText>
    <ButtonWithIconWrapper>
      <ButtonSecond {...previewButtonProps(document.id)} active>
        View
      </ButtonSecond>
      <Icon iconSrc={trashBinIcon} hideIcon={hideDeleteButton} size="lg" onClick={() => onDelete(document.id)} />
    </ButtonWithIconWrapper>
  </RowWrapper>
);

export default ViewRow;
