import {
  GET_ENGAGEMENT_REQUEST,
  GET_ENGAGEMENT_SUCCESS,
  CREATE_ENGAGEMENT_REQUEST,
  CREATE_ENGAGEMENT_SUCCESS,
  UPDATE_ENGAGEMENT_REQUEST,
  UPDATE_ENGAGEMENT_SUCCESS,
  DELETE_ENGAGEMENT_REQUEST,
  DELETE_ENGAGEMENT_SUCCESS,
  SET_ENGAGEMENT_DATA_ERROR,
  RESET_ENGAGEMENT_STATE,
  FullEngagementPayload,
  DELETE_PARTY_FROM_ENGAGEMENT_REQUEST,
  DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS,
  DELETE_PARTY_FROM_ENGAGEMENT_ERROR,
  ADD_TO_PARTY_ENGAGEMENT_REQUEST,
  ADD_TO_PARTY_ENGAGEMENT_SUCCESS,
  ADD_TO_PARTY_ENGAGEMENT_ERROR,
  UPLOAD_SDS_FILE_REQUEST,
  UPLOAD_SDS_FILE_SUCCESS,
  EngagementParty,
  UPLOAD_ATTACHED_DOCUMENTS_REQUEST,
  UPLOAD_ATTACHED_DOCUMENTS_SUCCESS,
  UPLOAD_ATTACHED_DOCUMENTS_ERROR,
  DELETE_ATTACHED_DOCUMENTS_REQUEST,
  DELETE_ATTACHED_DOCUMENTS_SUCCESS,
  DELETE_ATTACHED_DOCUMENTS_ERROR,
  FINALISE_ENGAGEMENT_REQUEST,
  FINALISE_ENGAGEMENT_SUCCESS,
  FINALISE_ENGAGEMENT_ERROR,
  RESET_UPLOAD_SDS_STATE,
  RESET_EDIT_ENGAGEMENT_STATE,
  TOGGLE_FINALISE_SDS_MODAL,
} from '../../constants/engagement/actionTypes';

export const getEngagementRequest = () => ({
  type: GET_ENGAGEMENT_REQUEST,
});

export const getEngagementSuccess = (payload: FullEngagementPayload) => ({
  type: GET_ENGAGEMENT_SUCCESS,
  payload,
});

export const createEngagementRequest = () => ({
  type: CREATE_ENGAGEMENT_REQUEST,
});

export const createEngagementSuccess = (engagementId: string) => ({
  type: CREATE_ENGAGEMENT_SUCCESS,
  payload: {
    engagementId,
  },
});

export const updateEngagementRequest = () => ({
  type: UPDATE_ENGAGEMENT_REQUEST,
});

export const updateEngagementSuccess = () => ({
  type: UPDATE_ENGAGEMENT_SUCCESS,
});

export const deleteEngagementRequest = () => ({
  type: DELETE_ENGAGEMENT_REQUEST,
});

export const deleteEngagementSuccess = () => ({
  type: DELETE_ENGAGEMENT_SUCCESS,
});

export const setEngagementError = (errorMessage: string) => ({
  type: SET_ENGAGEMENT_DATA_ERROR,
  payload: {
    errorMessage,
  },
});

export const resetEngagementState = () => ({
  type: RESET_ENGAGEMENT_STATE,
});

export const deletePartyFromEngagementRequest = () => ({
  type: DELETE_PARTY_FROM_ENGAGEMENT_REQUEST,
});

export const deletePartyFromEngagementSuccess = (engagementPartyType: string) => ({
  type: DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS,
  payload: {
    engagementPartyType,
  },
});

export const deletePartyFromEngagementError = (errorMessage: string) => ({
  type: DELETE_PARTY_FROM_ENGAGEMENT_ERROR,
  payload: {
    errorMessage,
  },
});

export const addToPartyEngagementRequest = () => ({
  type: ADD_TO_PARTY_ENGAGEMENT_REQUEST,
});

export const addToPartyEngagementSuccess = (engagementPartyType: string, engagementParty: EngagementParty) => ({
  type: ADD_TO_PARTY_ENGAGEMENT_SUCCESS,
  payload: {
    engagementParty,
    engagementPartyType,
  },
});

export const addToPartyEngagementError = (errorMessage: string) => ({
  type: ADD_TO_PARTY_ENGAGEMENT_ERROR,
  payload: {
    errorMessage,
  },
});

export const uploadSDSFileRequest = () => ({
  type: UPLOAD_SDS_FILE_REQUEST,
});

export const uploadSDSFileSuccess = () => ({
  type: UPLOAD_SDS_FILE_SUCCESS,
});

export const uploadAttachedDocumentRequest = () => ({
  type: UPLOAD_ATTACHED_DOCUMENTS_REQUEST,
});

export const uploadAttachedDocumentSuccess = () => ({
  type: UPLOAD_ATTACHED_DOCUMENTS_SUCCESS,
});

export const uploadAttachedDocumentError = (errorMessage: string) => ({
  type: UPLOAD_ATTACHED_DOCUMENTS_ERROR,
  payload: {
    errorMessage,
  },
});

export const deleteAttachedDocumentRequest = () => ({
  type: DELETE_ATTACHED_DOCUMENTS_REQUEST,
});

export const deleteAttachedDocumentSuccess = () => ({
  type: DELETE_ATTACHED_DOCUMENTS_SUCCESS,
});

export const deleteAttachedDocumentError = (errorMessage: string) => ({
  type: DELETE_ATTACHED_DOCUMENTS_ERROR,
  payload: {
    errorMessage,
  },
});

export const finaliseEngagementRequest = () => ({
  type: FINALISE_ENGAGEMENT_REQUEST,
});

export const finaliseEngagementSuccess = () => ({
  type: FINALISE_ENGAGEMENT_SUCCESS,
});

export const finaliseEngagementError = (errorMessage: string) => ({
  type: FINALISE_ENGAGEMENT_ERROR,
  payload: {
    errorMessage,
  },
});

export const resetFinaliseEngagementResult = () => ({
  type: RESET_ENGAGEMENT_STATE,
});

export const resetUploadSdsState = () => ({
  type: RESET_UPLOAD_SDS_STATE,
});

export const resetEditEngagementState = () => ({
  type: RESET_EDIT_ENGAGEMENT_STATE,
});

export const toggleFinaliseSDSModal = () => ({
  type: TOGGLE_FINALISE_SDS_MODAL,
});
