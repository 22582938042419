import React from 'react';
import { Link, useParams } from 'react-router-dom';

import Section from '../index';
import ButtonAsLink from '../../../../components/buttons/ButtonAsLink';
import PartiesList from './EngagementSummarySectionPartiesList';
import { EngagementTitle } from './index.style';

import { EngagementParty } from '../../../../constants/engagement/actionTypes';

import circleGroup from '../../../../assets/images/circle-group.png';

interface Props {
  engagementTitle: string;
  partiesInfo?: {
    engager: EngagementParty;
    ir35LTD: EngagementParty;
    worker: EngagementParty;
    intermediaries: EngagementParty[];
  };
  isWTTAdmin: boolean;
}

const MembersSection = ({ engagementTitle, partiesInfo, isWTTAdmin }: Props) => {
  const { id } = useParams();

  return (
    <Section
      title="SupplyChain"
      icon={circleGroup}
      controlButton={isWTTAdmin ? <ButtonAsLink as={Link} label="Edit" to={`/engagement/${id}/supply-chain`} /> : null}
    >
      {partiesInfo && <PartiesList partiesInfo={partiesInfo} />}
      <EngagementTitle>{engagementTitle}</EngagementTitle>
    </Section>
  );
};

export default MembersSection;
