import React from 'react';
import InputField from '../../../inputs/InputField/index';
import { mainTheme } from '../../../../assets/styles/variables/themes';
import { ReferenceWrapper, Reference } from './index.style';

interface Props {
  id?: string;
  reference?: string;
}

const EngagementReferences = ({ id, reference }: Props) => {
  const isEditForm = Boolean(id);
  const alternateReference = (
    <InputField
      maxWidth={mainTheme.inputMaxSize.sm}
      fullWidth={isEditForm}
      name="alternateReference"
      placeholder="Alternate Reference"
    />
  );

  return isEditForm ? (
    <ReferenceWrapper>
      <Reference>Reference {reference}</Reference>
      {alternateReference}
    </ReferenceWrapper>
  ) : (
    alternateReference
  );
};

export default EngagementReferences;
