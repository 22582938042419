import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';

import { Navigation } from '../../../../declarations/routing';
import { ROUTES } from '../../../../constants/routing';
import { logout } from '../../../../redux/auth/thunks';
import { NavList, NavItem } from './index.style';

interface Props {
  closeMenu: () => void;
}

const navigation: Navigation[] = [
  { title: 'Documents', path: ROUTES.DOCUMENTS },
  { title: 'Account', path: ROUTES.ACCOUNT },
];

const NavigationMenu = ({ closeMenu }: Props) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    closeMenu();
  };

  return (
    <NavList>
      {navigation.map(({ title, path }: Navigation) => (
        <NavItem key={title}>
          <Link to={path} onClick={closeMenu}>
            {title}
          </Link>
        </NavItem>
      ))}
      <NavItem>
        <ButtonBase disableRipple onClick={handleLogout}>
          Logout
        </ButtonBase>
      </NavItem>
    </NavList>
  );
};

export default NavigationMenu;
