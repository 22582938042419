import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';
import { useEffect } from 'react';
import { getFilePreview } from '../redux/preview/thunks';
import { useParams } from 'react-router-dom';

export const usePreviewFile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const fileURl = useSelector<AppState, string>(({ preview }) => preview.fileURL);
  const fileName = useSelector<AppState, string>(({ preview }) => preview.fileName);
  const fileType = useSelector<AppState, string>(({ preview }) => preview.fileType);
  const isLoading = useSelector<AppState, boolean>(({ preview }) => preview.isLoading);

  useEffect(() => {
    dispatch(getFilePreview(id));
  }, [id]);

  return { fileURl, fileName, fileType, isLoading };
};
