import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const StyledTitle = styled.h2`
  ${({ theme: { fontSize, fontFamily, keyColors } }) => css`
    width: 100%;
    order: 3;
    padding: 15px 0 5px;
    color: ${keyColors.first};
    font: ${fontSize.md} ${fontFamily.primary};
    font-weight: bold;
    text-align: center;

    ${mediaMinWidth.xs} {
      max-width: 350px;
      order: 0;
      padding: 0;
      font-size: ${fontSize.lg};
    }

    ${mediaMinWidth.sm} {
      max-width: 600px;
    }

    ${mediaMinWidth.md} {
      max-width: 750px;
    }

    ${mediaMinWidth.lg} {
      max-width: 700px;
    }
  `}
`;
