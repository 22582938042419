import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaMinWidth } from '../../assets/styles/media';

export const MainTitle = styled.h1`
  max-width: 40px;
  margin: 0;

  ${mediaMinWidth.xl} {
    max-width: 50px;
  }
`;

export const LogoLink = styled(Link)`
  display: block;
  height: 100%;
  vertical-align: middle;

  & img {
    height: auto;
  }
`;
