import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PreviewLayout from '../../../components/layouts/PreviewLayout';
import ResultDialog from '../../../components/modals/Dialog/ResultDialog';
import DocumentViewer from '../../../components/DocumentViewer';

import ConfirmationDialog from '../../../components/modals/Dialog/ConfirmationDialog';
import { PreviewAside, RecipientsBlock, RecipientsEmailBlock, RecipientsItemsBlock } from './index.styles';
import { ButtonFirst } from '../../../components/buttons/index.style';

import { usePreviewFile } from '../../../hooks/usePreviewFile';

import { resetFinaliseEngagementResult, toggleFinaliseSDSModal } from '../../../redux/engagement/actions';
import { AppState } from '../../../redux/reducers';
import { PartiesInfo } from '../../../constants/engagement/actionTypes';
import { buildRecipients } from '../../../utils/preview';
import { User } from '../../../constants/user/actionTypes';
import { confirmationMessages, resultMessages } from '../../../constants/sds/constants';

interface Props {
  acceptButtonLabel: string;
  pageType: string;
  finaliseSDS: () => void;
  renderSDSText: (firstName: string) => React.ReactNode;
}

const FinaliseSDS = ({ finaliseSDS, renderSDSText, acceptButtonLabel, pageType }: Props) => {
  const { idEngagement } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { fileURl, fileName, fileType, isLoading } = usePreviewFile();

  const partiesInfo = useSelector<AppState, PartiesInfo | null>(({ engagement }) => engagement.partiesInfo);
  const finalSDS = useSelector<AppState, string | null | undefined>(
    ({ engagement }) => engagement.sdsDocumentsInfo?.finalSDS
  );
  const isFinaliseLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isLoading);
  const isConfirmationModalOpen = useSelector<AppState, boolean>(
    ({ engagement }) => engagement.isConfirmFinaliseModalOpen
  );
  const successRequest = useSelector<AppState, boolean>(({ engagement }) => engagement.successRequest);
  const errorMessage = useSelector<AppState, string>(({ engagement }) => engagement.errorMessage);
  const user = useSelector<AppState, User>(({ user }) => user.values);

  useEffect(() => {
    return () => {
      dispatch(resetFinaliseEngagementResult());
    };
  }, []);

  const handleInfoDialogClose = () => {
    dispatch(resetFinaliseEngagementResult());
    history.push('/');
  };

  const toggleConfirmationModal = () => dispatch(toggleFinaliseSDSModal());

  return (
    <PreviewLayout
      isLoading={isLoading}
      fileName={fileName}
      onBackButtonClick={() => history.push(`/engagement/${idEngagement}`)}
    >
      <Grid item xs={12} xl={3}>
        <PreviewAside>
          <div>
            {renderSDSText(user.firstName)}
            <ButtonFirst disabled={!Boolean(finalSDS)} onClick={toggleConfirmationModal} maxWidth={160} minWidth={100}>
              {acceptButtonLabel}
            </ButtonFirst>
          </div>
          <RecipientsBlock>
            <header>Supply chain Recipients</header>
            <div>
              {partiesInfo &&
                buildRecipients(partiesInfo).map((parties: any, index: number) => (
                  <RecipientsItemsBlock key={`${parties.firstName}_${parties.lastName}`}>
                    <RecipientsEmailBlock first={!Boolean(index % 2)}>
                      <span>{parties.isWorker ? `${parties.firstName} ${parties.lastName}` : parties.company}</span>
                    </RecipientsEmailBlock>
                  </RecipientsItemsBlock>
                ))}
            </div>
          </RecipientsBlock>
        </PreviewAside>
      </Grid>
      <Grid item xs={12} xl={9}>
        <DocumentViewer fileURl={fileURl} fileName={fileName} fileType={fileType} />
      </Grid>
      <ResultDialog
        isOpen={successRequest || Boolean(errorMessage)}
        onClose={handleInfoDialogClose}
        errorMessage={errorMessage}
        successMessage={resultMessages[pageType]}
      />
      <ConfirmationDialog
        isLoading={isFinaliseLoading}
        isOpen={isConfirmationModalOpen}
        onConfirm={finaliseSDS}
        onCancel={toggleConfirmationModal}
        message={confirmationMessages[pageType]}
      />
    </PreviewLayout>
  );
};

export default FinaliseSDS;
