import styled from 'styled-components';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const TextContainer = styled.div`
  padding: 5px;
`;

export const PopoverWrapper = styled.div`
  z-index: 10;
`;

export const InputWrapper = styled.div`
  &&& {
    padding: 0;
    width: 100%;
    max-width: 100%;
    min-width: 80px;
    margin: 0 5px;
    z-index: 3;

    ${mediaMinWidth.xs} {
      max-width: 40%;
    }

    ${mediaMinWidth.sm} {
      max-width: 195px;
    }

    ${mediaMinWidth.lg} {
      max-width: 215px;
    }

    ${mediaMinWidth.xl} {
      max-width: 315px;
    }

    ${mediaMinWidth.sm} {
      margin: 0 15px;
    }

    div {
      padding: 0;

      input {
        margin: 0;
        max-width: 100%;
      }
    }
  }
`;
