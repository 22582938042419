import React from 'react';
import { DialogBody, CustomDialog } from './index.styles';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  dialogTitle?: React.ReactNode;
  maxWidth?: number;
  minHeight?: number;
  dialogType?: 'requestInformationDialog' | 'resultDialog';
}

const Dialog = (props: Props) => (
  <CustomDialog
    open={props.isOpen}
    customMaxWidth={props.maxWidth}
    customMinHeight={props.minHeight}
    dialogType={props.dialogType}
  >
    {props.dialogTitle}
    <DialogBody dialogType={props.dialogType}>{props.children}</DialogBody>
  </CustomDialog>
);

export default Dialog;
