import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../index';
import DialogBackTitle from '../../DialogBackButton';
import EngagementFormComponent from '../../../../form/EngagementForm/index';
import ButtonAsLink from '../../../../buttons/ButtonAsLink/index';
import { TitleWrapper } from './index.style';
import ResultDialog from '../../ResultDialog';

import { AppState } from '../../../../../redux/reducers';
import { resetEditEngagementState } from '../../../../../redux/engagement/actions';

import trashBinIcon from '../../../../../assets/images/trash-bin.jpg';
import { mainTheme } from '../../../../../assets/styles/variables/themes';
import { getEngagement } from '../../../../../redux/engagement/thunks';
import { useParams } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  openDeleteModal: () => void;
  skipResetEngagement?: boolean;
}

const EditEngagementDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const successRequest = useSelector<AppState, boolean>(({ engagement }) => engagement.successRequest);
  const errorMessage = useSelector<AppState, string>(({ engagement }) => engagement.errorMessage);

  const handleResultDialogClose = () => {
    dispatch(resetEditEngagementState());
    dispatch(getEngagement(id));
    props.onClose();
  };

  return (
    <>
      <Dialog
        maxWidth={mainTheme.dialogMaxSize.md}
        isOpen={props.isOpen}
        dialogTitle={
          <TitleWrapper>
            <DialogBackTitle title="Edit Engagement" onClick={props.onClose} />
            <ButtonAsLink label="Delete engagement" icon={trashBinIcon} onClick={props.openDeleteModal} />
          </TitleWrapper>
        }
      >
        <EngagementFormComponent skipResetEngagement={props.skipResetEngagement} />
      </Dialog>
      <ResultDialog
        isOpen={successRequest || Boolean(errorMessage)}
        errorMessage={errorMessage}
        onClose={handleResultDialogClose}
        successMessage="Success – the engagement has been updated"
      />
    </>
  );
};

export default EditEngagementDialog;
