import React from 'react';

import Section from '../index';
import { EngagementDescription, MainInfoContainer, MainInfoRow } from './index.style';
import ButtonAsLink from '../../../../components/buttons/ButtonAsLink';

import { EngagementPayload } from '../../../../constants/engagement/actionTypes';
import loginMan from '../../../../assets/images/loginMan.png';

interface Props {
  engagement: EngagementPayload;
  isWTTAdmin: boolean;
  toggleEditModal: () => void;
}

const MainSection = ({ engagement, isWTTAdmin, toggleEditModal }: Props) => (
  <Section
    title={engagement.shortDescription}
    icon={loginMan}
    controlButton={isWTTAdmin ? <ButtonAsLink label="Edit" onClick={toggleEditModal} /> : null}
  >
    <MainInfoContainer>
      <MainInfoRow>
        Start Date:
        <span>{engagement.startDate}</span>
      </MainInfoRow>
      <MainInfoRow>
        End Date:
        <span>{engagement.endDate}</span>
      </MainInfoRow>
      {engagement.alternateReference && (
        <MainInfoRow>
          Alternative Ref:
          <span>{engagement.alternateReference}</span>
        </MainInfoRow>
      )}
    </MainInfoContainer>
    {engagement.description && <EngagementDescription>{engagement.description}</EngagementDescription>}
  </Section>
);

export default MainSection;
