import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const UserTypeLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledUserTypeLabel = styled.span<{ engager?: boolean; worker?: boolean }>`
  ${({ engager, worker, theme: { fontFamily, fontSize } }) => css`
    display: inline-block;
    padding: 5px 5px 5px 15px;
    font: ${engager || worker ? fontSize.md : fontSize.sm} ${fontFamily.primary};
    font-style: italic;
    font-weight: 300;

    ${mediaMinWidth.md} {
      width: calc(100% - 3.376rem);
      font-size: ${engager || worker ? fontSize.xs : fontSize.xxs};
    }

    &:first-letter {
      text-transform: uppercase;
    }
  `}
`;

export const HiddenImage = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => css`
    display: inline-flex;
    padding: 0 !important;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    cursor: pointer;

    & > div {
      visibility: ${isVisible ? 'visible' : 'hidden'};
    }
  `}
`;
