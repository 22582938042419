import {
  LOGIN_USER_SUCCESS,
  LoginSuccessPayload,
  SET_LOGIN_ERROR,
  SET_AGREEMENT_STATUS,
  GET_AGREEMENT_STATUS,
  LOGOUT,
  AUTH_REQUEST_FAIL,
  UPDATE_AGREEMENT_STATUS_REQUEST,
  UPDATE_AGREEMENT_STATUS_SUCCESS,
  GET_AUTHENTICATED_USER_REQUEST,
  GET_AUTHENTICATED_USER_SUCCESS,
  CHECK_ACCESS_TO_ENGAGEMENT_REQUEST,
  CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS,
  CHECK_ACCESS_TO_ENGAGEMENT_ERROR,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL,
  AgreementPayload,
} from '../../constants/auth/actionTypes';

export const loginUserSuccess = (payload: LoginSuccessPayload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const setLoginError = (errorMessage: string) => ({
  type: SET_LOGIN_ERROR,
  payload: {
    errorMessage,
  },
});

export const getAgreementStatus = () => ({
  type: GET_AGREEMENT_STATUS,
});

export const updateAgreementStatusRequest = () => ({
  type: UPDATE_AGREEMENT_STATUS_REQUEST,
});

export const updateAgreementStatusSuccess = (payload: AgreementPayload) => ({
  type: UPDATE_AGREEMENT_STATUS_SUCCESS,
  payload,
});

export const setAgreementStatus = (payload: AgreementPayload) => ({
  type: SET_AGREEMENT_STATUS,
  payload,
});

export const getAuthenticatedUserRequest = () => ({
  type: GET_AUTHENTICATED_USER_REQUEST,
});

export const getAuthenticatedUserSuccess = () => ({
  type: GET_AUTHENTICATED_USER_SUCCESS,
});

export const authRequestFail = (error: string) => ({
  type: AUTH_REQUEST_FAIL,
  payload: {
    error,
  },
});

export const userLogout = () => ({
  type: LOGOUT,
});

export const checkAccessToEngagementRequest = () => ({
  type: CHECK_ACCESS_TO_ENGAGEMENT_REQUEST,
});

export const checkAccessToEngagementSuccess = () => ({
  type: CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS,
});

export const checkAccessToEngagementError = (error: string) => ({
  type: CHECK_ACCESS_TO_ENGAGEMENT_ERROR,
  payload: {
    error,
  },
});

export const changePasswordAfterFirstLoginRequest = () => ({
  type: CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST,
});

export const changePasswordAfterFirstLoginSuccess = () => ({
  type: CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS,
});

export const changePasswordAfterFirstLoginFail = () => ({
  type: CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL,
});
