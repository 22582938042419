import { USERNAME } from '../../constants/auth';
import axios from 'axios';
import { AllUserDocuments } from '../../constants/user/actionTypes';
import { Contracts } from '../../constants/documents/actionTypes';

const getUserDocumentsList = (userName?: string): Promise<{ data: AllUserDocuments }> => {
  const email = localStorage.getItem(USERNAME);

  return axios.get(`/user-documents/${userName || email}`);
};

const uploadUserRequiredDocument = (fileData: FormData): Promise<{ data: { id: string } }> =>
  axios.post('documents/upload/required-document', fileData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const uploadEngagementDocument = (fileData: FormData): Promise<{ data: { id: string } }> =>
  axios.post('documents/upload/engagement-document', fileData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const setRequiredDocuments = (userName: string, engagementId: string, requiredDocuments: Array<string>): Promise<any> =>
  axios.post('/documents/request-required-documents', { userName, engagementId, requiredDocuments });

const deleteDocument = (documentId: string): Promise<any> => axios.delete(`document/${documentId}`);

const getContractsList = (id: string, userName?: string): Promise<{ data: Contracts }> =>
  axios.get(`/user-contracts/${id}`, { params: { userName } });

const requestRequiredDocuments = (body: {
  userName: string;
  engagementId: string;
  requiredDocuments: string[];
}): Promise<any> => {
  return axios.post(`/documents/request-required-documents`, body);
};

const requestContracts = (body: { engagementId: string; contracts: any[] }): Promise<any> => {
  return axios.post(`/documents/request-contract-documents`, body);
};

const uploadContract = (body: FormData): Promise<any> => {
  return axios.post(`/documents/upload/user-contract`, body);
};

export default {
  getUserDocumentsList,
  setRequiredDocuments,
  deleteDocument,
  getContractsList,
  uploadUserRequiredDocument,
  requestRequiredDocuments,
  requestContracts,
  uploadContract,
  uploadEngagementDocument,
};
