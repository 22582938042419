import { useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routing';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';

interface Location {
  pathname: string;
  state: {
    activeStep: number;
  };
}

interface UseCheckUserAgreement {
  (arg: { requireTermsAgreement?: boolean; isLoading: boolean }): undefined | string | Location;
}

export const useCheckUserAgreement: UseCheckUserAgreement = ({ requireTermsAgreement, isLoading }) => {
  const location = useLocation();
  const userAgreement = useSelector<AppState, boolean>(({ auth }) => auth.agreementStatus);
  const isFirstLogin = useSelector<AppState, boolean>(({ user }) => user.values.userFirstLogin);

  if (isLoading) {
    return;
  }

  if (requireTermsAgreement && !userAgreement) {
    return ROUTES.SET_UP_ACCOUNT;
  }

  if (requireTermsAgreement && isFirstLogin) {
    return {
      pathname: ROUTES.SET_UP_ACCOUNT,
      state: {
        activeStep: 1,
      },
    };
  }

  if (location.pathname === ROUTES.SET_UP_ACCOUNT && !isFirstLogin && userAgreement) {
    return ROUTES.DASHBOARD;
  }
};
