import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { infoDialogClose } from '../../../redux/user/actions';
import ResultDialog from '../../modals/Dialog/ResultDialog';

interface Props {
  successMessage: string;
  onSuccess?: () => void;
}

const UserResultDialog = ({ successMessage, onSuccess }: Props) => {
  const successRequest = useSelector<AppState, boolean>((state) => state.user.successRequest);
  const errorMessage = useSelector<AppState, string>((state) => state.user.errorMessage);
  const dispatch = useDispatch();

  const handleInfoDialogClose = () => {
    dispatch(infoDialogClose());

    if (!errorMessage && onSuccess) {
      onSuccess();
    }
  };

  return (
    <ResultDialog
      isOpen={successRequest || Boolean(errorMessage)}
      errorMessage={errorMessage}
      onClose={handleInfoDialogClose}
      successMessage={successMessage}
    />
  );
};

export default UserResultDialog;
