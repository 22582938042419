import styled, { css } from 'styled-components';

interface Props {
  insideDialog?: boolean;
}

export const StyledBackTitle = styled.div<Props>`
  ${({ insideDialog, theme: { fontSize, fontFamily } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: ${insideDialog ? '20px 10px 0' : '0'};
    font: ${fontSize.sm} ${fontFamily.primary};
    text-decoration: underline;
    font-weight: 500;
  `}
`;

export const BackTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  & > span {
    margin: 0 10px;
    color: ${({ theme: { keyColors } }) => keyColors.darkGrey};
    line-height: 1;
    text-decoration: underline;
  }
`;
