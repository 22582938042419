import React from 'react';
import { Filters } from 'react-table';

import { NoDaraTableRow } from './index.style.js';

interface Props<D extends {}> {
  searchQuery: string;
  noDataMessagePrimary: string;
  noDataMessageSecondary: string;
  filters: Filters<D>;
}

const NoDataComponent = <D extends {}>({
  noDataMessagePrimary,
  noDataMessageSecondary,
  filters,
  searchQuery,
}: Props<D>) => {
  const noDataMessage = filters.length || searchQuery ? noDataMessageSecondary : noDataMessagePrimary;

  return (
    <NoDaraTableRow>
      <td>
        <div>{noDataMessage}</div>
      </td>
    </NoDaraTableRow>
  );
};

export default NoDataComponent;
