import React from 'react';
import { LoadingTableRow } from './index.style.js';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const TableLoading = ({ isLoading, children }: Props) =>
  isLoading ? (
    <LoadingTableRow>
      <td>
        <div>Loading...</div>
      </td>
    </LoadingTableRow>
  ) : (
    <>{children}</>
  );

export default TableLoading;
