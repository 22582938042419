import { filter, isEmpty, map, pipe, toPairs, reduce, values, get, includes, find, concat, groupBy } from 'lodash/fp';
import { AllUserDocuments, UserDocuments } from '../constants/user/actionTypes';
import {
  FormValues,
  DocumentsFormValues,
  Contracts,
  RequestInfoParties,
  UploadedContract,
  RequestContractPayload,
} from '../constants/documents/actionTypes';
import { contractTypes, partyTypes } from '../constants/documents/constants';
import { EngagementParty } from '../constants/engagement/actionTypes';

export const getDocumentsList = (userDocuments: UserDocuments) =>
  pipe(
    toPairs,
    map((it) => ({ name: it[0], ...it[1] }))
  )(userDocuments);

export const isShowEmptyMessage = (userDocuments: UserDocuments) =>
  pipe(
    filter((it: any) => it.requested || it.id),
    isEmpty
  )(userDocuments);

export const getRequestedRequiredDocuments = (documents: FormValues) =>
  pipe(
    toPairs,
    reduce((result: string[], [key, value]) => {
      if (value) result.push(key);

      return result;
    }, [])
  )(documents);

export const getAllRequestedRequiredDocuments = (documents: DocumentsFormValues, userDocuments: AllUserDocuments) => {
  const allDocuments = { ...userDocuments.company, ...userDocuments.personal };

  return pipe(
    concat(getRequestedRequiredDocuments(documents.personal)),
    filter((documentType) => !get(`${documentType}.requested`, allDocuments))
  )(getRequestedRequiredDocuments(documents.company));
};

export const getContractName = (contractType: string, userName: string, party?: EngagementParty) =>
  `${contractType}-${party?.userName === userName ? partyTypes.UPPER_PARTY : partyTypes.LOWER_PARTY}`;

export const getContractFieldFormValues = (
  { requestedDirectly, requestedInDirectly }: Contracts,
  parties: RequestInfoParties
) => {
  const fields = [
    ...requestedDirectly.map(({ contractType, contractWith }) =>
      getContractName(contractType, contractWith, parties.upperParty)
    ),
    ...requestedInDirectly.map(({ contractType, contractFrom }) =>
      getContractName(contractType, contractFrom, parties.upperParty)
    ),
  ];

  return values(contractTypes).reduce((result: { [key: string]: boolean }, contractType) => {
    if (parties.upperParty) {
      const contractUpperParty = `${contractType}-${partyTypes.UPPER_PARTY}`;
      result[contractUpperParty] = includes(contractUpperParty, fields);
    }

    if (parties.lowerParty) {
      const contractLowerParty = `${contractType}-${partyTypes.LOWER_PARTY}`;
      result[contractLowerParty] = includes(contractLowerParty, fields);
    }

    return result;
  }, {});
};

export const getUploadedContracts = (data: UploadedContract[], parties: RequestInfoParties) =>
  data.reduce((result: { [key: string]: string }, { contractWith, contractFrom, id, contractType }) => {
    const party = contractWith === parties.requestFrom.userName ? contractFrom : contractWith;
    const contractName = getContractName(contractType, party, parties.upperParty);

    result[contractName] = id;
    return result;
  }, {});

export const getRequestedContracts = (
  formValues: FormValues,
  parties: RequestInfoParties,
  { requestedDirectly, requestedInDirectly }: Contracts
) =>
  pipe(
    toPairs,
    reduce((result: RequestContractPayload[], [key, value]) => {
      if (value) {
        const [contractType, contractWith] = key.split('-');
        const partyUsername = parties[contractWith]?.userName;

        if (partyUsername) {
          const alreadyRequestedDirectlyContract = find(
            { contractType, contractWith: partyUsername },
            requestedDirectly
          );
          const alreadyRequestedInDirectlyContract = find(
            { contractType, contractFrom: partyUsername },
            requestedInDirectly
          );

          if (!alreadyRequestedDirectlyContract && !alreadyRequestedInDirectlyContract) {
            result.push({
              contractType,
              contractWith: partyUsername,
              contractFrom: parties.requestFrom.userName,
            });
          }
        }
      }

      return result;
    }, [])
  )(formValues);

export const buildContracts = (userContracts: Contracts) =>
  pipe(get('requestedDirectly'), concat(userContracts.uploaded), groupBy('contractType'))(userContracts);
