import styled, { css } from 'styled-components';

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;

  & button {
    max-width: 150px;
    margin: 25px 0 0;
  }
`;

export const ErrorMessage = styled.p`
  color: ${({ theme: { keyColors } }) => keyColors.third};
  margin: 30px 0 0;
`;

export const ErrorStatus = styled.p`
  ${({ theme: { keyColors, fontSize } }) => css`
    color: ${keyColors.third};
    font-size: ${fontSize.xl};
    font-weight: bold;
  `}
`;
