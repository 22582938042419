import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const StyledBackTitle = styled.h2`
  ${({ theme: { fontSize, fontFamily } }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 20px 10px 0;
    font: ${fontSize.lg} ${fontFamily.secondary};

    ${mediaMinWidth.xxl} {
      padding-top: 20px;
    }

    & > div:first-child {
      margin-bottom: 15px;

      ${mediaMinWidth.xs} {
        margin-bottom: 0;
        padding: 0;
      }
    }
  `}
`;

export const BackTextContainer = styled.div`
  display: flex;
  cursor: pointer;

  & > span {
    margin: 0 15px;
  }
`;
