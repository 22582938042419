import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { GridContainer, TextContainerUnderButton } from './index.style';
import { ButtonFirst, ButtonSecond } from '../../../../components/buttons/index.style';
import UploadButton from '../../../../components/buttons/UploadButton';
import Icon from '../../../../components/icons/Icon';

import { mainTheme } from '../../../../assets/styles/variables/themes';
import upload from '../../../../assets/images/images-9@3x.png';

import { uploadWPQDocument } from '../../../../redux/wpq/thunks';
import { EngagementParty, WPQInfo } from '../../../../constants/engagement/actionTypes';
import { AppState } from '../../../../redux/reducers';
import { setWPQBaseLink } from '../../../../redux/wpq/actions';

interface Props {
  isEngager: boolean;
  isWorker: boolean;
  wpqAuthor: EngagementParty;
  disabled: boolean;
  setShowWPQ: Function;
}

const getWPQStatus = (isWPQRequested: boolean, isWPQFileUploaded: boolean) => {
  if (isWPQFileUploaded) {
    return 'On File';
  }

  if (isWPQRequested) {
    return '*Invited*';
  }

  return '*Invite*';
};

const WorkingPracticesButton = ({ isEngager, isWorker, wpqAuthor, disabled, setShowWPQ }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { engagerWPQInfo, workerWPQInfo } = useSelector<AppState, WPQInfo>(({ engagement }) => engagement.wpqInfo);

  const Button = isEngager ? ButtonSecond : ButtonFirst;
  const wpqInfo = isEngager
    ? {
        wpq: engagerWPQInfo.engagerWPQ,
        wpqBaseLink: engagerWPQInfo.enagagerWPQBaseLink,
        requested: engagerWPQInfo.requested,
      }
    : {
        wpq: workerWPQInfo.workerWPQ,
        wpqBaseLink: workerWPQInfo.workerWPQBaseLink,
        requested: workerWPQInfo.requested,
      };

  const isWPQFileUploaded = Boolean(wpqInfo.wpq);

  const handleUploadWPQDocument = (file: File) => {
    dispatch(uploadWPQDocument({ wpqAuthor: wpqAuthor.userName, file, engagementId: id }));
  };

  const handleRequestWPQClick = () => {
    if (isWPQFileUploaded) {
      history.push(`/preview/${wpqInfo.wpq}`);
      return;
    }

    dispatch(
      setWPQBaseLink({
        baseLink: wpqInfo.wpqBaseLink,
      })
    );
    setShowWPQ();
  };

  return isEngager || isWorker ? (
    <GridContainer direction="column" justify="flex-end" alignItems="flex-end" container spacing={0}>
      <Button
        active={isWPQFileUploaded}
        maxWidth={mainTheme.buttonMaxSize.xs}
        disabled={disabled}
        onClick={handleRequestWPQClick}
        secondaryFontType
      >
        Working Practices
      </Button>
      <TextContainerUnderButton>
        <span>{getWPQStatus(wpqInfo.requested, isWPQFileUploaded)}</span>
        <UploadButton
          onUpload={handleUploadWPQDocument}
          Button={(props) => <Icon iconSrc={upload} size="md" onClick={props.onClick} />}
        />
      </TextContainerUnderButton>
    </GridContainer>
  ) : null;
};

export default WorkingPracticesButton;
