import React from 'react';

interface Props {
  title?: string;
  value: string;
}

const SuggestionListItem = ({ title, value }: Props) => (
  <>
    <span>{title}</span>
    <span>{value}</span>
  </>
);

export default SuggestionListItem;
