import React, { ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PrivateRoute from './components/PrivateRouter';
import routes from './routes';
import { MainRouter } from './declarations/routing';
import { ROUTE_TYPE_PUBLIC, ROUTES } from './constants/routing';
import { getAuthenticatedUser, getUserAgreementStatus } from './redux/auth/thunks';
import { getStoredTicket } from './utils/auth';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserAgreementStatus());
    dispatch(getAuthenticatedUser());
  }, []);

  return (
    <Switch location={location}>
      {routes.map(
        (route: MainRouter, i: number): ReactElement => {
          if (route.path === ROUTES.SDS_VERIFICATION && getStoredTicket()) {
            return <Redirect key={route.path || i} to={ROUTES.SDS_VERIFICATION_ON_PLATFORM} {...route} />;
          }

          return route.type === ROUTE_TYPE_PUBLIC ? (
            <Route key={route.path || i} {...route} />
          ) : (
            <PrivateRoute key={route.path || i} {...route} />
          );
        }
      )}
    </Switch>
  );
};

export default App;
