import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const DatePickerWrapper = styled.div<{ maxWidth?: number; showError?: boolean }>`
  ${({ maxWidth, showError, theme: { fontSize, fontColors, borderColors, keyColors, fontFamily } }) => css`
    width: 100%;
    position: relative;

    ${mediaMinWidth.xs} {
      max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid ${showError ? keyColors.third : borderColors.primary};
    }

    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
      margin-left: 0;
    }

    .MuiFormControl-marginNormal {
      margin: 0;
    }

    .MuiIconButton-root {
      padding: 5px;
    }

    input {
      padding: 5px;
      min-height: 35px;
      font-family: ${fontFamily.primary};
      font-size: ${fontSize.xs};
      font-weight: 300;
      color: ${fontColors.primary};

      & + div {
        padding: 0 3px 5px;
      }

      &::placeholder {
        font-family: inherit;
        font-style: italic;
        font-size: ${fontSize.xs};
        font-weight: 300;
        color: ${fontColors.secondary};
        opacity: 1;
      }

      ${mediaMinWidth.xxl} {
        min-height: 50px;
        font-size: ${fontSize.md};
      }
    }
  `}
`;
