import React from 'react';
import { TextContainer, PopoverWrapper, InputWrapper } from './index.styles';
import { Popover } from '@material-ui/core';

interface Props {
  text: string;
  children: React.ReactNode;
  disabled?: boolean;
}

const PopoverText = ({ children, text, disabled }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return disabled ? (
    <InputWrapper>
      <div>{children}</div>
    </InputWrapper>
  ) : (
    <InputWrapper>
      <PopoverWrapper style={{ zIndex: 10 }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        <Popover
          style={{ pointerEvents: 'none' }}
          id="mouse-over-popover"
          open={open}
          elevation={150}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: -25,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          onMouseLeave={handlePopoverClose}
        >
          <TextContainer>{text}</TextContainer>
        </Popover>
        {children}
      </PopoverWrapper>
    </InputWrapper>
  );
};

export default PopoverText;
