import React from 'react';
import { SuggestionListContainer, SuggestionTitle } from './index.styles';

interface Props {
  children: React.ReactNode;
}

const SuggestionList = ({ children }: Props) => (
  <SuggestionListContainer>
    <SuggestionTitle>User Suggestion</SuggestionTitle>
    {children}
  </SuggestionListContainer>
);

export default SuggestionList;
