const mediaMinWidthQuery = (minWidth: number) => `
    @media (min-width: ${minWidth}px)
`;

const mediaMaxWidthQuery = (maxWidth: number) => `
    @media (max-width: ${maxWidth}px)
`;

const mediaMinHeightQuery = (minHeight: number) => `
    @media (min-height: ${minHeight}px)
`;

const pureHeightQuery = (minHeight: number) => `
    (min-height: ${minHeight}px)
`;

type MediaWidthSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

type MediaWidth = {
  [key in MediaWidthSize]: string;
};

export const grid = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1680,
  xxxl: 1860,
};

export const mediaMinWidth: MediaWidth = {
  xs: mediaMinWidthQuery(576),
  sm: mediaMinWidthQuery(768),
  md: mediaMinWidthQuery(992),
  lg: mediaMinWidthQuery(1200),
  xl: mediaMinWidthQuery(1440),
  xxl: mediaMinWidthQuery(1680),
  xxxl: mediaMinWidthQuery(1860),
};

type MediaHeightSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

type MediaHeight = {
  [key in MediaHeightSize]: string;
};

export const mediaMinHeight: MediaHeight = {
  xxs: mediaMinHeightQuery(481),
  xs: mediaMinHeightQuery(769),
  sm: mediaMinHeightQuery(900),
  md: mediaMinHeightQuery(1025),
  lg: mediaMinHeightQuery(1080),
};

const pureHeight: MediaHeight = {
  xxs: pureHeightQuery(0),
  xs: pureHeightQuery(0),
  sm: pureHeightQuery(360),
  md: pureHeightQuery(720),
  lg: pureHeightQuery(1080),
};

export const mediaWidthAndHeight = (width: MediaWidthSize, height: MediaHeightSize) =>
  `${mediaMinWidth[width]} and ${pureHeight[height]}`;
