const ENGAGEMENT = 'Engagement';
const SCHEDULE = 'Schedule';
const ADDENDUM = 'Addendum';

export const documentTypes = { CONTRACT: 'contract', REQUIRED_DOCUMENT: 'requiredDocument' };
export const contractTypes = { ENGAGEMENT, SCHEDULE, ADDENDUM };
export const contractTypesList = [ENGAGEMENT, SCHEDULE, ADDENDUM];
export const partyTypes = { UPPER_PARTY: 'upperParty', LOWER_PARTY: 'lowerParty' };
export const contractLabels: { [key: string]: string } = {
  [ENGAGEMENT]: 'Contract with',
  [SCHEDULE]: SCHEDULE,
  [ADDENDUM]: ADDENDUM,
};

export const contractLabelsForUploadContractsModal: { [key: string]: string } = {
  [ENGAGEMENT]: 'Engagement Contract',
  [SCHEDULE]: 'Engagement Contract Schedule',
  [ADDENDUM]: 'Engagement Contract Addendum',
};
