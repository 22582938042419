import styled, { css } from 'styled-components';
import { isNumber } from 'lodash/fp';
import { mediaMinWidth } from '../../assets/styles/media';
import attachIcon from '../../assets/images/bitmap@3x.png';

export interface Props {
  maxWidth?: number;
  minWidth?: number;
  active?: boolean;
  secondaryFontType?: boolean;
  link?: boolean;
  centered?: boolean;
  defaultPointer?: boolean;
}

const PrimaryButton = styled.button<Props>`
  ${({
    secondaryFontType,
    centered,
    defaultPointer,
    maxWidth,
    theme: { borderWidth, borderStyle, bgColors, fontColors, transition, fontSize, fontFamily },
  }) => css`
    display: inline-flex !important;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center !important;
    min-height: 45px;
    max-width: ${isNumber(maxWidth) ? `${maxWidth - 50}px` : 'none'};
    width: 100%;
    padding: 3px;
    color: ${fontColors.primary};
    border-width: ${borderWidth.md};
    border-style: ${borderStyle};
    background-color: ${bgColors.primary};
    font: ${fontSize.xxs} ${fontFamily.secondary};
    font-weight: 400;
    text-align: center;
    text-transform: ${!secondaryFontType ? 'uppercase' : 'none'};
    transition: ${transition};
    cursor: ${defaultPointer ? 'default' : 'pointer'};

    ${centered &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};

    ${mediaMinWidth.xs} {
      max-width: ${maxWidth ? `${maxWidth - 25}px` : 'none'};
    }

    ${mediaMinWidth.xl} {
      max-width: ${maxWidth ? `${maxWidth}px` : 'none'};
      font-size: ${fontSize.xs};
    }

    ${mediaMinWidth.xxl} {
      padding: 5px;
      min-height: 55px;
    }

    &:hover,
    &:focus {
      color: ${bgColors.primary};
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        color: ${fontColors.primary};
        background-color: ${bgColors.primary};
      }
    }
  `}
`;

export const ButtonFirst = styled(PrimaryButton)<Props>`
  ${({ minWidth, active, theme: { keyColors, bgColors } }) => css`
    border-color: ${keyColors.first};
    min-width: ${minWidth ? `${minWidth}px` : '0'};
    background-color: ${active ? keyColors.first : 'inherit'};
    color: ${active ? bgColors.primary : 'inherit'};
    position: relative;

    &:hover,
    &:focus {
      background-color: ${keyColors.first};
    }
  `}
`;

export const ButtonSecond = styled(PrimaryButton)<Props>`
  ${({ minWidth, active, theme: { keyColors, bgColors } }) => css`
    border-color: ${keyColors.second};
    min-width: ${minWidth ? `${minWidth}px` : '0'};
    background-color: ${active ? keyColors.second : 'inherit'};
    color: ${active ? bgColors.primary : 'inherit'};

    &:hover,
    &:focus {
      background-color: ${keyColors.second};
    }
  `}
`;

export const ButtonThird = styled(PrimaryButton)<Props>`
  ${({ active, theme: { keyColors, bgColors } }) => css`
    border-color: ${keyColors.third};
    background-color: ${active ? keyColors.third : 'inherit'};
    color: ${active ? bgColors.primary : 'inherit'};

    &:hover,
    &:focus {
      background-color: ${keyColors.third};
    }
  `}
`;

export const ButtonWithIconWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 170px;
  width: 100%;

  div {
    margin: 0 10px;
  }

  & button,
  & a {
    max-width: 125px;

    ${mediaMinWidth.xxl} {
      max-width: 145px;
    }
  }

  & span {
    min-width: 250px;
  }

  ${mediaMinWidth.xxl} {
    max-width: 200px;
  }
`;

export const LinkedButton = styled(PrimaryButton)`
  ${({ theme: { bgColors, fontColors } }) => css`
    border-width: 0;
    border-style: none;
    min-height: 30px;
    max-width: none;

    &:hover,
    &:focus {
      background-color: ${bgColors.secondary};
      color: ${fontColors.primary};
      outline-width: 0;
    }
  `}
`;
