import React from 'react';
import { Grid } from '@material-ui/core';

import { BaseContainer, ColumnContainer } from '../../containers';
import { PreviewBodyHeaderContainer, PreviewMainWrapper } from './index.style';
import Header from '../../Header';
import Main from '../../Main';
import Footer from '../../Footer';

import Loader from '../../Loader';
import BackButton from '../../BackButton';
import { useHistory } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
  fileName: string;
  isLoading: boolean;
  onBackButtonClick?: () => void;
}

const PreviewLayout = ({ fileName, children, isLoading, onBackButtonClick }: Props) => {
  const history = useHistory();

  return (
    <BaseContainer preview>
      <ColumnContainer>
        <Header />
        <Main preview>
          <Loader isLoading={isLoading}>
            <PreviewBodyHeaderContainer>
              <BackButton onClick={onBackButtonClick || history.goBack} />
              <p>{fileName}</p>
            </PreviewBodyHeaderContainer>
            <PreviewMainWrapper>
              <Main.Content preview>
                <Grid container alignItems="flex-start" justify="center">
                  {children}
                </Grid>
              </Main.Content>
            </PreviewMainWrapper>
          </Loader>
        </Main>
        <Footer />
      </ColumnContainer>
    </BaseContainer>
  );
};

export default PreviewLayout;
