import React from 'react';

import Icon from '../icons/Icon/index';
import { BackTextContainer, StyledBackTitle } from './index.styles';

import leftArrow from '../../assets/images/left-arrow.png';

interface Props {
  onClick: () => void;
  children?: React.ReactNode;
  insideDialog?: boolean;
}

const BackButton = ({ insideDialog, onClick, children }: Props) => (
  <StyledBackTitle insideDialog={insideDialog}>
    <BackTextContainer onClick={onClick}>
      <Icon size="xxxs" iconSrc={leftArrow} />
      <span>Back</span>
    </BackTextContainer>
    {children}
  </StyledBackTitle>
);

export default BackButton;
