import { createStore, applyMiddleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import rootReducer from './reducers';

const configureStore = (initialState = {}): Store => {
  const middlewareList = [thunk as ThunkMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewareList.push(logger);
  }

  const middleware = applyMiddleware(...middlewareList);

  return process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, initialState, middleware)
    : createStore(rootReducer, initialState, composeWithDevTools(middleware));
};

export default configureStore;
