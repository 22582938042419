import React from 'react';

import { ContactsContainer, ContactsRow } from './index.style';

const Contacts = () => (
  <ContactsContainer>
    <ContactsRow>
      E: <a href="mailto:hello@ir35navigator.com">hello@ir35navigator.com</a>
    </ContactsRow>
    <ContactsRow>
      T: <a href="tel:+44 (0)20 3468 0008">+44 (0)20 3468 0008</a>
    </ContactsRow>
    <ContactsRow>40 Queen Street, London, EC4R 1DD</ContactsRow>
    <ContactsRow>© 2021 IR35 Navigator</ContactsRow>
  </ContactsContainer>
);

export default Contacts;
