import {
  GET_LINK_TO_WPQ_FORM_ERROR,
  GET_LINK_TO_WPQ_FORM_REQUEST,
  GET_LINK_TO_WPQ_FORM_SUCCESS,
  REQUEST_WPQ_ERROR,
  REQUEST_WPQ_REQUEST,
  REQUEST_WPQ_SUCCESS,
  RESET_WPQ_SUCCESS_RESULT,
  SET_WPQ_BASE_LINK,
  wpqTypes,
} from '../../constants/wpq/actionTypes';

export interface WPQStore {
  baseLink: string;
  wpqLink: string;
  errorMessage: string;
  requestFor: string;
  isLoading: boolean;
  successRequest: boolean;
}

const initialState: WPQStore = {
  baseLink: '',
  wpqLink: '',
  errorMessage: '',
  requestFor: '',
  isLoading: false,
  successRequest: false,
};

export default (state: WPQStore = initialState, action: wpqTypes): WPQStore => {
  switch (action.type) {
    case REQUEST_WPQ_REQUEST:
    case GET_LINK_TO_WPQ_FORM_REQUEST: {
      return { ...state, isLoading: true };
    }
    case SET_WPQ_BASE_LINK: {
      return { ...state, ...action.payload };
    }
    case REQUEST_WPQ_SUCCESS: {
      return { ...state, isLoading: false, successRequest: true };
    }
    case RESET_WPQ_SUCCESS_RESULT: {
      return { ...state, successRequest: false, errorMessage: '' };
    }
    case GET_LINK_TO_WPQ_FORM_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case REQUEST_WPQ_ERROR:
    case GET_LINK_TO_WPQ_FORM_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
