import { SdsVerificationColumn } from './actionTypes';

export const REGISTRATION_STATUS_REGISTERED = 'REGISTERED';
export const REGISTRATION_STATUS_NOT_REGISTERED = 'NOT_REGISTERED';

export const registrationStatusLabels: { [key: string]: string } = {
  [REGISTRATION_STATUS_REGISTERED]: 'Registered by IR35 Navigator Limited',
  [REGISTRATION_STATUS_NOT_REGISTERED]: 'Not found',
};

export const registrationStatusCaptions: { [key: string]: string } = {
  [REGISTRATION_STATUS_REGISTERED]: 'Secured on a public blockchain and unchanged from its registration.',
  [REGISTRATION_STATUS_NOT_REGISTERED]:
    'This document may not be the original. Please check the uploaded file or contact us on 020 3468 0008 for assistance.',
};

export const verificationSdsResultColumnsForFinalSds: SdsVerificationColumn[] = [
  {
    label: 'Organisation',
    width: '60%',
  },
  {
    label: 'Status',
    width: '15%',
    align: 'center',
  },
  {
    label: 'Date',
    width: '25%',
  },
];

export const verificationSDSResultColumns: SdsVerificationColumn[] = [
  {
    label: 'Result',
    width: '75%',
  },
  {
    label: 'Date',
    width: '25%',
  },
];

export const ACCEPT_SDS_PAGE = 'ACCEPT';
export const FINALISE_SDS_PAGE = 'FINALISE';

export const confirmationMessages: { [key: string]: string } = {
  [ACCEPT_SDS_PAGE]: 'Please confirm you are happy to accept and pass on this Status Determination Statement',
  [FINALISE_SDS_PAGE]: 'Please confirm you are happy to finalise and issue this Status Determination Statement',
};

export const resultMessages: { [key: string]: string } = {
  [ACCEPT_SDS_PAGE]:
    'Success – the Status Determination Statement has been accepted and issued to the next party in the supply chain.',
  [FINALISE_SDS_PAGE]:
    'Success - the Status Determination Statement has been finalised and issued to the supply chain.',
};
