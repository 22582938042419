import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { history } from './history';
import configureStore from './redux/store';

import MainWrapper from './components/layouts/MainWrapper';

//Global styles
import { mainTheme, customMaterialBreakpoints } from './assets/styles/variables/themes';
import { GlobalStyle } from './assets/styles/globalStyle';

const store = configureStore();

ReactDOM.render(
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={customMaterialBreakpoints}>
      <ThemeProvider theme={mainTheme}>
        <GlobalStyle />
        <Router history={history}>
          <MainWrapper>
            <Provider store={store}>
              <App />
            </Provider>
          </MainWrapper>
        </Router>
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
