import * as Yup from 'yup';

const acceptedFileExtensions = ['application/pdf'];

export const sdsFileValidation = () =>
  Yup.object()
    .shape({ type: Yup.string().required(), arrayBuffer: Yup.mixed().required('Required') })
    .default(null)
    .nullable()
    .required('Required')
    .test('file-invalid-type', 'Unsupported file extension. File type must be .pdf', (value) => {
      if (!value) return true;

      return acceptedFileExtensions.some((extension) => extension === value.type);
    });
