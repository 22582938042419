import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { useSelector } from 'react-redux';

import { ControlContainer } from './index.style';
import { CustomLabel } from '../index.style';
import DeleteDialog from '../../DeleteDialog';
import PreviewButton from './PreviewButton';
import RequestDocumentCheckbox from './RequestDocumentCheckbox';

import { useModalToggle } from '../../../../../hooks/useModalToggle';
import { AppState } from '../../../../../redux/reducers';

interface Props {
  name: string;
  label?: string;
  idDocument?: string;
  handleUploadRequiredDocument: Function;
  handleDelete: Function;
  documentType: string;
}

const RequestDocumentControl = ({
  documentType,
  idDocument,
  handleUploadRequiredDocument,
  label,
  name,
  handleDelete,
}: Props) => {
  const isLoading = useSelector<AppState, boolean>(({ documents }) => documents.isLoading);
  const [isDeleteModalOpen, toggleDeleteModal] = useModalToggle(false);

  const controlComponent = idDocument ? (
    <PreviewButton idDocument={idDocument} onClick={toggleDeleteModal} />
  ) : (
    <RequestDocumentCheckbox name={name} onClick={handleUploadRequiredDocument} />
  );

  return (
    <>
      {label ? (
        <Grid container wrap="nowrap" justify="space-between" alignItems="center">
          <CustomLabel>{label}</CustomLabel>
          <ControlContainer>{controlComponent}</ControlContainer>
        </Grid>
      ) : (
        <ControlContainer>{controlComponent}</ControlContainer>
      )}
      <DeleteDialog
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        isLoading={isLoading}
        message="Are you sure you want to delete the document?"
        handleDelete={handleDelete(idDocument || '', documentType)}
      />
    </>
  );
};

export default RequestDocumentControl;
