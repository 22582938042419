import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const FormRow = styled.div<{ isTextRow?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 ${({ isTextRow }) => (isTextRow ? 5 : 25)}px;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  ${mediaMinWidth.xxl} {
    margin: 0 0 ${({ isTextRow }) => (isTextRow ? 0 : 30)}px;

    &:last-child {
      margin-bottom: ${({ isTextRow }) => (isTextRow ? 20 : 30)}px;
    }
  }

  & > div:last-child {
    & {
      position: relative;
      margin: 0;
    }

    & > * {
      display: block;
      margin-left: auto;
    }
  }
`;

export const FormTitle = styled.h2`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    width: 100%;
    opacity: 0.48;
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.md};
    font-weight: bold;
    color: ${fontColors.primary};
    margin: 10px 30px 0 0;
  `}
`;
