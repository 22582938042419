import React from 'react';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';

import { Label } from '../../../../components/Label/index.styles';
import { SetUpInput } from '../../../../components/inputs/index.style';
import { ErrorMessage, CustomErrorMessage } from '../../../../components/inputs/InputErrorMessage';
import { InputWrapper } from './index.style';

interface Props {
  name: string;
  label: string;
  fullWidth?: boolean;
  errorMessage?: string;
  type?: 'text' | 'password';
}

interface FieldProps {
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
}

const InputField = ({ name, type = 'text', errorMessage, label, fullWidth = true }: Props) => (
  <Field name={name}>
    {({ field, meta: { touched, error } }: FieldProps) => {
      const showError: boolean = Boolean(errorMessage) || (touched && Boolean(error));

      return (
        <InputWrapper fullWidth={fullWidth}>
          <Label>{label}</Label>
          <SetUpInput showError={showError} type={type} {...field} value={field.value || ''} />
          {errorMessage && <CustomErrorMessage>{errorMessage}</CustomErrorMessage>}
          {!errorMessage && <ErrorMessage name={name} component="span" />}
        </InputWrapper>
      );
    }}
  </Field>
);

export default InputField;
