import React from 'react';
import { Field, FieldProps } from 'formik';

import { TextareaWPQ } from './index.style';
import { InputWrapper } from '../../../../../inputs/InputField/index.styles';
import { ErrorMessage } from '../../../../../inputs/InputErrorMessage';

const WPQTextarea = () => (
  <Field name="wpqLink">
    {({ field, meta: { touched, error } }: FieldProps) => (
      <InputWrapper fullWidth>
        <TextareaWPQ
          showError={touched && Boolean(error)}
          placeholder="Type/paste a link here to a pre-populated form."
          rows={3}
          {...field}
        />
        <ErrorMessage name={field.name} component="span" />
      </InputWrapper>
    )}
  </Field>
);

export default WPQTextarea;
