import styled, { css } from 'styled-components';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import { mediaMinWidth } from '../../../assets/styles/media';

export const Menu = styled(Popper)`
  && {
    margin: 10px 0 0 0;
    text-align: right;
    z-index: 99999;
  }
`;

export const MenuContentWrapper = styled.div`
  ${({ theme: { fontSize, fontColors, gradient, fontFamily } }) => css`
    width: 175px;
    padding: 20px 5px 5px 20px;
    color: ${fontColors.white};
    background-image: ${gradient.toRightDeg};
    border-radius: 30px;
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: bold;

    &:after {
      content: '';
      position: absolute;
      right: 25px;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 13px 16px 13px;
      border-color: transparent transparent #ea9939 transparent;

      ${mediaMinWidth.xl} {
        border-width: 0 20px 23px 20px;
      }
    }

    ${mediaMinWidth.sm} {
      width: 200px;
    }

    ${mediaMinWidth.xl} {
      width: 230px;
    }
  `}
`;

export const UserInfo = styled.div`
  margin: 0 0 35px;
  text-align: left;

  &:focus {
    outline: none;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p:first-child {
    margin: 0 0 35px;

    ${mediaMinWidth.xl} {
      margin: 0 0 50px;
    }
  }
`;

export const GrowTransition = styled(Grow)`
  padding: 15px 0 0;
`;
