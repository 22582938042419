import { Action } from 'redux';
import { mapValues } from 'lodash/fp';
import { ThunkAction } from 'redux-thunk';

import {
  DeleteDocumentPayload,
  UploadContractPayload,
  RequestContractsPayload,
} from '../../constants/documents/actionTypes';
import { AppState } from '../reducers';
import api from '../../services/api';
import {
  getContractsError,
  getContractsRequest,
  getContractsSuccess,
  getRequiredDocumentsListSuccess,
  getRequiredDocumentsListRequest,
  getRequiredDocumentsListSuccessError,
  requestDocumentsRequest,
  requestDocumentsSuccess,
  requestDocumentsError,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentError,
  uploadContractRequest,
  uploadContractSuccess,
  uploadContractError,
} from './actions';
import {
  getAllRequestedRequiredDocuments,
  getContractFieldFormValues,
  getRequestedContracts,
  getUploadedContracts,
} from '../../utils/documents';
import { documentTypes } from '../../constants/documents/constants';

export const getRequiredDocumentsList = (
  userName?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(getRequiredDocumentsListRequest());

    const { data } = await api.documents.getUserDocumentsList(userName);

    const documentsFormValues = {
      personal: mapValues('requested', data.personal),
      company: mapValues('requested', data.company),
    };

    dispatch(getRequiredDocumentsListSuccess({ userDocuments: data, documentsFormValues }));
  } catch (e) {
    dispatch(getRequiredDocumentsListSuccessError('Cannot find any user documents'));
  }
};

export const getContractsList = (
  id: string,
  userName?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState): Promise<void> => {
  try {
    dispatch(getContractsRequest());

    const { data } = await api.documents.getContractsList(id, userName);
    const {
      documents: { parties },
    } = getState();

    dispatch(
      getContractsSuccess({
        allContracts: data,
        uploadedContracts: getUploadedContracts(data.uploaded, parties),
        contractsFormValues: getContractFieldFormValues(data, parties),
      })
    );
  } catch (e) {
    dispatch(getContractsError('Cannot find any user documents'));
  }
};

export const requestDocuments = ({
  engagementId,
  formValues,
}: RequestContractsPayload): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch,
  getState
): Promise<void> => {
  try {
    dispatch(requestDocumentsRequest());
    const {
      documents: { parties, contracts, userDocuments },
    } = getState();

    const requestedContracts = getRequestedContracts(formValues.contracts, parties, contracts);
    requestedContracts.length &&
      (await api.documents.requestContracts({
        engagementId,
        contracts: requestedContracts,
      }));
    const requestedRequiredDocuments = getAllRequestedRequiredDocuments(formValues.documents, userDocuments);

    requestedRequiredDocuments.length &&
      (await api.documents.requestRequiredDocuments({
        userName: parties.requestFrom.userName,
        engagementId,
        requiredDocuments: requestedRequiredDocuments,
      }));

    dispatch(requestDocumentsSuccess());
  } catch (e) {
    dispatch(requestDocumentsError('Error occurred'));
  }
};

export const uploadContract = ({
  contractWith,
  contractFrom,
  engagementId,
  contractType,
  file,
}: UploadContractPayload): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(uploadContractRequest());

    const data = new FormData();

    data.append('fileData', file);
    data.append('engagementId', engagementId);
    data.append('contractType', contractType);
    data.append('contractFrom', contractFrom);
    data.append('contractWith', contractWith);

    await api.documents.uploadContract(data);
    dispatch(uploadContractSuccess());
    dispatch(getContractsList(engagementId, contractFrom));
  } catch (e) {
    dispatch(uploadContractError('Cannot upload document'));
  }
};

export const deleteDocument = ({
  id,
  userName,
  documentType,
  idEngagement,
}: DeleteDocumentPayload): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(deleteDocumentRequest());

    await api.documents.deleteDocument(id);

    dispatch(deleteDocumentSuccess());
    documentType === documentTypes.CONTRACT
      ? dispatch(getContractsList(idEngagement, userName))
      : dispatch(getRequiredDocumentsList(userName));
  } catch (e) {
    dispatch(deleteDocumentError('Error occurred'));
  }
};
