import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const MainInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MainInfoRow = styled.div`
  ${({ theme: { fontSize, keyColors } }) => css`
    width: 100%;
    display: flex;
    padding: 0 10px 0 0;
    color: ${keyColors.darkGrey};
    font-size: ${fontSize.sm};
    font-weight: 700;

    ${mediaMinWidth.md} {
      width: auto;
    }

    & > span {
      padding: 0 0 0 10px;
      color: ${keyColors.lightGrey};
      font-size: ${fontSize.sm};
      font-weight: 400;
    }
  `}
`;

export const EngagementDescription = styled.p`
  ${({ theme: { fontSize, keyColors } }) => css`
    margin: 40px 0 0;
    color: ${keyColors.lightGrey};
    font-size: ${fontSize.sm};
  `}
`;
