import React, { useEffect, useState } from 'react';
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  Column,
  TableOptions,
  FetchDataFn,
  GetRowPropsFn,
} from 'react-table';

import TableLoading from './TableLoading';
import TableSearchInput from './TableSearchInput';
import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';
import { StyledTableRow } from './TableRow/index.style';
import TablePagination from './TablePagination';
import NoDataComponent from './TableNoDataComponent';
import { TableStyles, TableWrapper, TableHeaderWrapper, TableIconWrapper } from './index.style';

import { tableConfig, defaultTablePageSize } from '../../constants/table';
import { fillTableDataToPageSize } from '../../utils/table';
import loginMan from '../../assets/images/loginMan.png';
import Icon from '../icons/Icon';

interface Table<D extends {}> extends TableOptions<D> {
  columns: Column<D>[];
  data: D[];
  fetchData: FetchDataFn;
  isLoading: boolean;
  noDataMessagePrimary: string;
  noDataMessageSecondary: string;
  getRowProps: GetRowPropsFn<D>;
  controlButtons: React.ReactNode;
}

const Table = <D extends {}>({
  columns,
  data,
  fetchData,
  pageCount,
  isLoading,
  getRowProps,
  noDataMessagePrimary,
  noDataMessageSecondary,
  controlButtons,
}: Table<D>) => {
  const {
    getTableProps,
    getTableBodyProps,
    columns: tableColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    gotoPage,
    previousPage,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable<D>(
    { ...tableConfig, columns, data: fillTableDataToPageSize<D>(data, defaultTablePageSize), pageCount },
    useFilters,
    useSortBy,
    usePagination
  );
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    gotoPage(0);
  };

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters, sortBy, searchQuery });
  }, [fetchData, pageIndex, pageSize, filters, sortBy, searchQuery]);

  return (
    <>
      <TableHeaderWrapper>
        {controlButtons}
        <TableSearchInput
          positionAbsolute={Boolean(controlButtons)}
          placeholder="Search..."
          handleChange={handleSearch}
        />
      </TableHeaderWrapper>
      <TableStyles>
        <TableIconWrapper>
          <Icon iconSrc={loginMan} size="xl" />
        </TableIconWrapper>
        <TableWrapper>
          <table {...getTableProps()}>
            <thead>
              <StyledTableRow headerRow>
                {tableColumns.map((column) => (
                  <TableHeaderCell key={column.id} column={column} />
                ))}
              </StyledTableRow>
            </thead>
            <tbody {...getTableBodyProps()}>
              <TableLoading isLoading={isLoading}>
                {page.length ? (
                  page.map((row) => {
                    prepareRow(row);

                    return <TableRow<D> key={row.id} row={row} getRowProps={getRowProps} />;
                  })
                ) : (
                  <NoDataComponent
                    noDataMessagePrimary={noDataMessagePrimary}
                    noDataMessageSecondary={noDataMessageSecondary}
                    filters={filters}
                    searchQuery={searchQuery}
                  />
                )}
              </TableLoading>
            </tbody>
          </table>
        </TableWrapper>
        <TablePagination
          page={pageIndex}
          totalPages={pageOptions.length}
          nextPage={nextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </TableStyles>
    </>
  );
};

export default Table;
