import React from 'react';

import { ErrorWrapper, ErrorStatus, ErrorMessage } from './index.style';
import { ButtonThird } from '../buttons/index.style';
import { useHistory } from 'react-router-dom';

const AccessDeniedError = () => {
  const history = useHistory();

  return (
    <ErrorWrapper>
      <ErrorStatus>403</ErrorStatus>
      <ErrorMessage>Access denied/Forbidden</ErrorMessage>
      <p>The page or resource you were trying to reach is absolutely forbidden for some reason</p>
      <ButtonThird onClick={history.goBack}>Go Back</ButtonThird>
    </ErrorWrapper>
  );
};

export default AccessDeniedError;
