import styled from 'styled-components';

export const LoadingTableRow = styled.tr`
  td {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
