import React from 'react';

import { StyledLoader } from './index.style';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const Loader = ({ isLoading, children }: Props) =>
  isLoading ? <StyledLoader>Loading...</StyledLoader> : <>{children}</>;

export default Loader;
