import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOr } from 'lodash/fp';
import { useParams } from 'react-router-dom';

import WPQDialog from '../../../components/modals/Dialog/WPQ/index';
import SupplyInputRow from './SupplyInputRow';
import InviteUserDialog from '../../../components/modals/Dialog/InviteUserDialog';
import RequestInformationDialog from '../../../components/modals/Dialog/RequestInformationDialog';
import ButtonAsLink from '../../../components/buttons/ButtonAsLink';
import WorkingPracticesButton from './WorkingPracticesButton';
import IR35NavigatorParty from './IR35NavigatorParty';
import UserTypeLabel from './UserTypeLabel';
import { OneSizeGrid, DoubleSizeGrid } from '../index.style';
import { RowContainer } from './index.style';

import { setRequestInfoParties } from '../../../redux/documents/actions';
import { useModalToggle } from '../../../hooks/useModalToggle';
import { getRequestInfoParties } from '../../../utils/supplyChainUtils';
import { addToPartyEngagement, deletePartyFromEngagement } from '../../../redux/engagement/thunks';
import { AppState } from '../../../redux/reducers';

import { userTypes } from '../../../constants/user/userTypes';
import { IR_35_NAVIGATOR, supplyChainColumnWidths } from '../../../constants/supplyChain/constants';
import { EngagementParty, PartiesInfo } from '../../../constants/engagement/actionTypes';

interface Props {
  addedUser?: boolean;
  partiesInfo: PartiesInfo | null;
  userType: { type: string; label: string };
}

const ChainRow = ({ partiesInfo, userType, addedUser }: Props) => {
  const [showInviteUser, setShowInviteUser] = useModalToggle(false);
  const [showRequestInformation, setShowRequestInformation] = useModalToggle(false);
  const [showWPQ, setShowWPQ] = useModalToggle(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const ir35LTDDefaultUserInfo = useSelector<AppState, EngagementParty>(
    ({ engagement }) => engagement.ir35LTDDefaultUserInfo
  );
  const engagementId = useSelector<AppState, string | undefined>(({ engagement }) => engagement.values.id);
  const currentUser = { ...getOr({}, userType.type, partiesInfo), userType: userType.type };

  const handleDeleteClick = () => {
    dispatch(deletePartyFromEngagement(userType.type, id));
  };

  const handleUserAddClick = () => {
    if (userType.type === IR_35_NAVIGATOR) {
      if (ir35LTDDefaultUserInfo.userName && engagementId) {
        dispatch(addToPartyEngagement(IR_35_NAVIGATOR, ir35LTDDefaultUserInfo, engagementId));
      }
      return;
    }

    setShowInviteUser();
  };

  const openRequestInfoPopup = () => {
    if (partiesInfo) {
      dispatch(setRequestInfoParties(getRequestInfoParties(partiesInfo, currentUser)));
      setShowRequestInformation();
    }
  };

  return (
    <>
      <RowContainer userType={userType?.type}>
        <OneSizeGrid width={supplyChainColumnWidths.partyColumn} fullWidthOnTablet>
          <UserTypeLabel
            addedUser={addedUser}
            userType={userType}
            handleDeleteClick={handleDeleteClick}
            handleUserAddClick={handleUserAddClick}
          />
        </OneSizeGrid>
        {userType.type === IR_35_NAVIGATOR ? (
          <IR35NavigatorParty addedUser={addedUser} />
        ) : (
          <DoubleSizeGrid container alignItems="flex-end">
            <SupplyInputRow worker={userType.type === userTypes.WORKER} userType={userType.type} />
          </DoubleSizeGrid>
        )}
        <OneSizeGrid width={supplyChainColumnWidths.requestInfoColumn} justify="center">
          {addedUser && (
            <ButtonAsLink secondaryButton supplyChain label="Request Information" onClick={openRequestInfoPopup} />
          )}
        </OneSizeGrid>
        <OneSizeGrid width={supplyChainColumnWidths.wpqColumn} justify="flex-end" noPadding>
          <WorkingPracticesButton
            isEngager={userType.type === userTypes.ENGAGER}
            isWorker={userType.type === userTypes.WORKER}
            wpqAuthor={currentUser}
            disabled={!addedUser}
            setShowWPQ={setShowWPQ}
          />
        </OneSizeGrid>
      </RowContainer>
      <InviteUserDialog isOpen={showInviteUser} onClose={setShowInviteUser} userType={userType.type} />
      {showRequestInformation && (
        <RequestInformationDialog isOpen={showRequestInformation} onClose={setShowRequestInformation} />
      )}
      <WPQDialog isOpen={showWPQ} onClose={setShowWPQ} requestFor={currentUser} />
    </>
  );
};

export default ChainRow;
