export const companyNavigation = [
  { title: 'Contact Us', path: 'https://www.ir35navigator.com/get-in-touch' },
  { title: 'Privacy Policy', path: 'https://www.ir35navigator.com/privacy-policy' },
  { title: 'Terms of Service', path: 'https://www.ir35navigator.com/terms-of-service' },
];

export const resourcesNavigation = [
  { title: 'General', path: 'https://www.ir35navigator.com/general-resources' },
  { title: 'Recruiters', path: 'https://www.ir35navigator.com/recruiters-resources' },
  { title: 'End Client', path: 'https://www.ir35navigator.com/end-client-resources' },
];
