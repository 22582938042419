import * as Yup from 'yup';

export const WPQValidation = (baseLink: string) =>
  Yup.object().shape({
    wpqLink: Yup.string()
      .required('Required')
      .test('basic-link-validation', 'Your URLs domain name has to be exactly as in the input above', (value) => {
        if (!value) return true;

        return value.startsWith(baseLink);
      }),
  });
