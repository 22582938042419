import React from 'react';
import styled, { css } from 'styled-components';

const BreakLine = () => (
  <BreakLineContainer>
    <Line />
    <Text>OR</Text>
    <Line />
  </BreakLineContainer>
);

const BreakLineContainer = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Line = styled.div`
  flex: 10;
  border-bottom: 2px solid black;
  margin-bottom: 10px;
`;

const Text = styled.div`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    font-size: ${fontSize.sm};
    color: ${fontColors.primary};
    flex: 2;
    display: flex;
    justify-content: center;
    font-family: ${fontFamily.primary};
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
    text-align: right;
  `}
`;

export default BreakLine;
