import React from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { StyledMainWrapper } from './index.style';
import { ROUTES } from '../../../constants/routing';

interface Props {
  children: React.ReactNode;
}

const MainWrapper = ({ children }: Props) => {
  const { pathname } = useLocation();
  const isPreviewPage = matchPath(pathname, {
    path: [ROUTES.PREVIEW_SDS, ROUTES.PREVIEW],
    exact: true,
  });

  return isPreviewPage ? <>{children}</> : <StyledMainWrapper>{children}</StyledMainWrapper>;
};

export default MainWrapper;
