import styled from 'styled-components';
import { ButtonThird } from '../../../../components/buttons/index.style';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const IR35Navigator = styled(ButtonThird)<{ isVisible?: boolean }>`
  width: 70%;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  ${mediaMinWidth.md} {
    margin: 0 auto;
  }
`;
