/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios';
import { ExtendedUser, NewUser, SuggestionUserPayload, User } from '../../constants/user/actionTypes';
import { USERNAME } from '../../constants/auth';

interface AgreementRequestBody {
  agreementStatus: boolean;
}

const createUser = (user: NewUser): Promise<NewUser> => axios.post('/people', user);

const getUser = (info: string): Promise<{ data: User }> => {
  const email = localStorage.getItem(USERNAME);

  return axios.get(`/people/${email}`, {
    params: { info },
  });
};

const getAgreementStatus = (): Promise<{ data: { agreementStatus: boolean } }> => {
  const email = localStorage.getItem(USERNAME);

  return axios.get(`/agreement/${email}`);
};

const updateAgreementStatus = (values: AgreementRequestBody) => {
  const email = localStorage.getItem(USERNAME);

  return axios.post(`/agreement`, { ...values, userName: email });
};

const updateUser = (user: ExtendedUser): Promise<{ data: User }> => axios.put(`/people/${user.userName}`, user);

const searchUser = (query: string, userType: string): Promise<{ data: SuggestionUserPayload[] }> =>
  axios.post(`/user-search`, { query, userType }, { headers: { 'Accept-Language': '*' } });

export default {
  createUser,
  updateUser,
  getUser,
  getAgreementStatus,
  updateAgreementStatus,
  searchUser,
};
