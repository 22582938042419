import React from 'react';
import { Field, FieldInputProps } from 'formik';

interface Props {
  name: string;
  label?: string;
  component: React.ComponentType<any>;
}

const CheckboxField = ({ name, label, component: Component }: Props) => (
  <Field name={name}>
    {({ field }: { field: FieldInputProps<any> }) => <Component label={label} checked={field.value} {...field} />}
  </Field>
);

export default CheckboxField;
