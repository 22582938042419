import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikValues } from 'formik';
import { useLocation } from 'react-router-dom';

import InputField from '../SetUpAccountBaseInfo/SetUpAccountBaseInfoInput';
import { FormContent, ButtonWrapper } from '../SetUpAccountBaseInfo/index.style';
import { FormRow } from '../index.style';
import { ButtonFirst } from '../../../components/buttons/index.style';

import { ChangePasswordPayload } from '../../../constants/user/actionTypes';
import { AppState } from '../../../redux/reducers';
import { changePasswordAfterFirstLoginUserData } from '../../../redux/auth/thunks';
import { changePasswordValidation } from './validation';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePasswordForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const errorMessage = useSelector<AppState, string | undefined>(({ auth }) => auth.errorMessage);
  const isLoading = useSelector<AppState, boolean>(({ auth }) => auth.isLoading);

  const handleSubmit = (values: FormikValues, { setSubmitting }: { setSubmitting: Function }) => {
    dispatch(changePasswordAfterFirstLoginUserData(values as ChangePasswordPayload, location));
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={changePasswordValidation}
      onSubmit={handleSubmit}
    >
      <Form id="setUpAccountChangePassword">
        <FormContent>
          <FormRow>
            <InputField
              fullWidth
              errorMessage={errorMessage}
              name="oldPassword"
              type="password"
              label="Old Password*"
            />
          </FormRow>
          <FormRow>
            <InputField fullWidth name="newPassword" type="password" label="New Password*" />
          </FormRow>
          <FormRow>
            <InputField fullWidth name="confirmPassword" type="password" label="Confirm Password*" />
          </FormRow>
          <FormRow>
            <ButtonWrapper>
              <ButtonFirst type="submit" disabled={isLoading}>
                Confirm
              </ButtonFirst>
            </ButtonWrapper>
          </FormRow>
        </FormContent>
      </Form>
    </Formik>
  );
};

export default ChangePasswordForm;
