import styled from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 0;
  padding: 25px 20px 25px;
  border-top: 4px solid ${({ theme: { keyColors } }) => keyColors.first};

  ${mediaMinWidth.md} {
    padding: 55px 45px 25px;
  }
`;

export const FooterSection = styled.div`
  width: 100%;

  ${mediaMinWidth.xs} {
    width: calc(50% - 25px);
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  div:first-child {
    margin: 0 45px 0 0;

    ${mediaMinWidth.sm} {
      margin: 0 90px 0 0;
    }
  }

  ${mediaMinWidth.xs} {
    justify-content: flex-end;
    padding: 0 30px 0 0;
  }

  ${mediaMinWidth.md} {
    justify-content: flex-end;
    padding: 0 75px 0 0;
  }
`;
