import React from 'react';

import { IR35Navigator } from './index.style';
import { DoubleSizeGrid } from '../../index.style';

interface Props {
  addedUser?: boolean;
}

const IR35NavigatorParty = ({ addedUser }: Props) => (
  <DoubleSizeGrid container>
    <IR35Navigator as="div" isVisible={addedUser} active secondaryFontType>
      IR35 Navigator Limited (fee-payer)
    </IR35Navigator>
  </DoubleSizeGrid>
);

export default IR35NavigatorParty;
