import React from 'react';

import Icon from '../../icons/Icon';
import { Title, TitleWrapper } from './index.style';

interface Props {
  title?: string;
  icon: string;
  controlButton?: React.ReactNode;
  iconSize?: 'xl';
}

const SectionTitle = ({ title, icon, iconSize, controlButton }: Props) => (
  <TitleWrapper>
    <Icon iconSrc={icon} size={iconSize || 'lg'} />
    {title && <Title>{title}</Title>}
    {controlButton}
  </TitleWrapper>
);

export default SectionTitle;
