import React from 'react';
import { isEmpty } from 'lodash/fp';
import { Formik, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../../../../components/modals/Dialog';
import BackButton from '../../../../../components/BackButton';
import FormControl from '../../../../../components/form/FormControl';
import Loader from '../../../../../components/Loader';
import UserResultDialog from '../../../../../components/form/UserForm/UserResultDialog';
import { AppState } from '../../../../../redux/reducers';
import { changeUserPassword } from '../../../../../redux/user/thunks';
import { changePasswordValidation } from './validation';
import { mainTheme } from '../../../../../assets/styles/variables/themes';
import { ButtonFirst } from '../../../../../components/buttons/index.style';
import { ValidationError } from '../../../../Login/index.styles';
import { ChangePasswordPayload } from '../../../../../constants/user/actionTypes';
import { Container, FormWrapper, ChangePasswordDialogTitle } from './index.styles';

interface Props {
  isOpen: boolean;
  onBackClick: () => void;
}

const formInitValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePasswordDialog = ({ isOpen, onBackClick }: Props) => {
  const dispatch = useDispatch();

  const isDialogLoading = useSelector<AppState, boolean>(({ user }) => user.isDialogLoading);

  const handleSubmit = (values: FormikValues) => {
    dispatch(changeUserPassword(values as ChangePasswordPayload));
  };

  return (
    <Dialog
      maxWidth={mainTheme.dialogMaxSize.xs}
      isOpen={isOpen}
      dialogTitle={
        <ChangePasswordDialogTitle>
          <BackButton insideDialog onClick={onBackClick}>
            <ButtonFirst disabled={isDialogLoading} maxWidth={145} form="change-pass">
              update
            </ButtonFirst>
          </BackButton>
        </ChangePasswordDialogTitle>
      }
    >
      <Container>
        <Loader isLoading={isDialogLoading}>
          <Formik initialValues={formInitValues} validationSchema={changePasswordValidation} onSubmit={handleSubmit}>
            {({ handleChange, values, errors }) => (
              <FormWrapper id="change-pass">
                {!isEmpty(errors) && (
                  <ValidationError>
                    *{errors.oldPassword || errors.newPassword || errors.confirmPassword}*
                  </ValidationError>
                )}
                <FormControl
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  label="Old Password"
                  value={values.oldPassword}
                  onChange={handleChange}
                />
                <FormControl
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label="New Password"
                  value={values.newPassword}
                  onChange={handleChange}
                />
                <FormControl
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
              </FormWrapper>
            )}
          </Formik>
        </Loader>
        <UserResultDialog onSuccess={onBackClick} successMessage="Success - password has been updated" />
      </Container>
    </Dialog>
  );
};

export default ChangePasswordDialog;
