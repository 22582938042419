import React from 'react';

import Dialog from '../index';
import DialogBackTitle from '../DialogBackButton';
import { mainTheme } from '../../../../assets/styles/variables/themes';
import { DescriptionMessage, FormContent } from './index.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  description: string;
  title: string;
}

const DialogWithDescription = ({ isOpen, onClose, children, description, title }: Props) => (
  <Dialog
    maxWidth={mainTheme.dialogMaxSize.md}
    minHeight={400}
    isOpen={isOpen}
    dialogTitle={<DialogBackTitle onClick={onClose} title={title} />}
  >
    <FormContent>
      <DescriptionMessage>{description}</DescriptionMessage>
      {children}
    </FormContent>
  </Dialog>
);

export default DialogWithDescription;
