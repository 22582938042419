import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const Title = styled.h3`
  ${({ theme: { fontSize, fontFamily, keyColors } }) => css`
    display: inline-block;
    margin: 0 0 0 20px;
    color: ${keyColors.first};
    font: ${fontSize.md} ${fontFamily.primary};
    font-weight: bold;
    text-decoration: underline;

    & + button,
    & + a {
      padding: 0 0 0 15px;
    }

    & + a {
      padding: 0 0 0 15px;
      color: #000;
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  & img {
    min-width: 26px;

    ${mediaMinWidth.md} {
      min-width: 30px;
    }
  }
`;
