import styled, { css } from 'styled-components';

export const NavigationListTitle = styled.h4`
  ${({ theme: { fontSize, fontFamily } }) => css`
    margin: 0 0 14px;
    color: #5c5c5c;
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: 400;
    text-transform: uppercase;
  `}
`;

export const NavigationLink = styled.a`
  ${({ theme: { fontSize, fontFamily } }) => css`
    margin: 0 0 10px;
    color: #5c5c5c;
    font: ${fontSize.xxs} ${fontFamily.primary};
  `}
`;
