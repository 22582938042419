import styled from 'styled-components';
import { Dialog, DialogContent } from '@material-ui/core';

import { mediaMinWidth } from '../../assets/styles/media';

export const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    margin: 5px;
    padding: 30px 20px 15px;
    border-radius: 70px;
    background: ${({ theme: { bgColors } }) => bgColors.primary};
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.5);

    &:first-child {
      padding-top: 30px;
    }

    form {
      margin: 0 0 auto;
    }

    ${mediaMinWidth.xs} {
      padding: 20px 0 15px;
    }
  }
`;

export const LogoHolder = styled.div`
  max-width: 70px;
  width: 100%;
  margin: 0 auto 0;

  ${mediaMinWidth.xl} {
    max-width: 105px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background: none;
  }

  .MuiDialog-paper {
    max-height: calc(100% - 20px);
    margin: 10px;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    background: transparent;

    ${mediaMinWidth.xs} {
      max-width: 490px;
    }

    ${mediaMinWidth.xl} {
      max-height: calc(100% - 60px);
      margin: 30px;
    }
  }
`;

export const Stepper = styled.span`
  display: block;
  margin: 10px 0 0;
  font: ${({ theme: { fontSize } }) => fontSize.xs} ${({ theme: { fontFamily } }) => fontFamily.primary};
  font-weight: bold;
  text-align: center;

  ${mediaMinWidth.xl} {
    font-size: ${({ theme: { fontSize } }) => fontSize.md};
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 15px;
  }

  & button {
    font-size: ${({ theme: { fontSize } }) => fontSize.xxs};
  }
`;

export const FormTooltip = styled.div`
  display: inline-block;
  margin: 20px auto 0;
  font: ${({ theme: { fontSize } }) => fontSize.xs} ${({ theme: { fontFamily } }) => fontFamily.primary};
  font-weight: 300;

  ${mediaMinWidth.xl} {
    margin-top: 50px;
    font-size: ${({ theme: { fontSize } }) => fontSize.md};
  }
`;
