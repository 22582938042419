import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const ButtonWrapper = styled.div`
  width: 45%;
  min-width: 109px;
  margin: 10px auto 0;

  ${mediaMinWidth.xs} {
    max-width: 109px;
    margin: 0 0 0 auto;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 25px 0 0;

  ${mediaMinWidth.xs} {
    padding: 0 25px 0;
  }
`;
