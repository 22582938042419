import 'date-fns';
import React from 'react';
import { FieldInputProps } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Icon from '../icons/Icon';

import calendar from '../../assets/images/calendar.png';
import { parse, DATE_FORMAT } from '../../utils/dateUtils';

interface Props {
  setFieldValue: Function;
  field: FieldInputProps<any>;
  placeholder: string;
}

const DatePicker = (props: Props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      margin="normal"
      id="date-picker-dialog"
      format={DATE_FORMAT}
      placeholder={props.placeholder}
      value={props.field.value && parse(props.field.value)}
      onChange={(value, formattedValue) => {
        props.setFieldValue(props.field.name, formattedValue);
      }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      keyboardIcon={<Icon iconSrc={calendar} size="lg" />}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
