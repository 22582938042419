import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  margin: 40px auto 0;

  ${mediaMinWidth.md} {
    margin: 0 auto;
  }

  ${mediaMinWidth.xl} {
    max-width: 690px;
  }
`;

export const DocumentsWrapper = styled.div`
  padding: 20px 0 0;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 15px;
  flex-direction: column;

  & > button,
  & > a {
    max-width: 110px;
    margin: 10px auto 0;

    ${mediaMinWidth.xs} {
      margin: 0;
    }

    ${mediaMinWidth.xl} {
      max-width: 125px;
    }
  }

  ${mediaMinWidth.xs} {
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
  }
`;

export const StyledText = styled.span`
  ${({ theme: { fontSize, fontFamily, fontColors } }) => css`
    align-self: center;
    font: ${fontSize.sm} ${fontFamily.primary};
    font-weight: 300;
    color: ${fontColors.primary};

    ${mediaMinWidth.xs} {
      margin: 0 15px 0 0;
    }
  `}
`;

export const NoDocsMessage = styled.span`
  display: inline-block;
  margin: 25px 0 0;
  font-size: ${({ theme }) => theme.fontSize.sm};
`;
