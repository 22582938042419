import styled, { css } from 'styled-components';
import { Dialog } from '@material-ui/core';

import { mediaMinWidth } from '../../../assets/styles/media';

export const DialogBody = styled.div<{ dialogType?: string }>`
  padding: 0 20px 0px;
`;

export const CustomDialog = styled(Dialog)<{
  dialogType?: string;
  customMaxWidth?: number;
  customMinHeight?: number;
}>`
  ${({ customMaxWidth, dialogType, customMinHeight }) => css`
    .MuiPaper-root {
      overflow-x: hidden;
      max-width: 100%;
      width: 100%;
      min-height: ${customMinHeight ? `${customMinHeight}px` : 'inherit'};
      border-radius: 8px;

      ${dialogType === 'requestInformationDialog' &&
      css`
        min-width: 320px;
        width: auto;
        height: 100%;
        min-height: 100%;
        position: absolute;
        right: 0;
        margin: 0;

        ${mediaMinWidth.xl} {
          width: 100%;
          max-width: ${customMaxWidth ? `${customMaxWidth}px` : 'none'};
        }
      `};

      ${dialogType === 'resultDialog' &&
      css`
        ${mediaMinWidth.xs} {
          ${customMaxWidth &&
          ` width: 100%;
            max-width: ${customMaxWidth}px;`}
        }
      `}

      ${!dialogType &&
      css`
        ${mediaMinWidth.xs} {
          max-width: 85%;
        }

        ${mediaMinWidth.sm} {
          max-width: 70%;
        }

        ${mediaMinWidth.md} {
          max-width: 50%;
        }
      `}

      ${mediaMinWidth.xxl} {
        ${customMaxWidth &&
        `width: 100%;
         max-width: ${customMaxWidth}px;
      `};
      }
    }
  `}
`;

export const ContentWrapper = styled.div`
  text-align: center;
  padding: 20px;

  & p {
    max-width: 60%;
    margin: 30px auto 50px;
    font: ${({ theme: { fontSize } }) => fontSize.xs} FaricyNew;
    font-weight: bold;
  }
`;
