import React from 'react';

import { StyledSection, SectionContent } from './index.style';
import SectionTitle from '../../../../components/titles/SectionTitle';

interface Props {
  children: React.ReactNode;
  icon: string;
  iconSize?: 'xl';
}

const Section = ({ children, icon, iconSize }: Props) => (
  <StyledSection>
    <SectionTitle icon={icon} iconSize={iconSize} />
    <SectionContent>{children}</SectionContent>
  </StyledSection>
);

export default Section;
