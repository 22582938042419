import React, { MouseEvent } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';

import MenuCloseButton from './UserInfoPopupCloseButton';
import NavigationMenu from './UserInfoPopupNavigation';
import Avatar from '../Avatar';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { User } from '../../../constants/user/actionTypes';
import { Menu as StyledMenu, UserInfo, MenuContentWrapper, GrowTransition } from './index.style';

const Menu = () => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const user = useSelector<AppState, User>(({ user }) => user.values);
  const isOpen = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box display="inline-block">
        <Avatar openMenu={openMenu} user={user} />
        <StyledMenu id="menu" anchorEl={anchorEl} open={isOpen} transition>
          {({ TransitionProps }) => (
            <GrowTransition in={isOpen} {...TransitionProps}>
              <div>
                <MenuContentWrapper>
                  <UserInfo>
                    <p>
                      {user.firstName} {user.lastName}
                    </p>
                  </UserInfo>
                  <NavigationMenu closeMenu={closeMenu} />
                </MenuContentWrapper>
                <MenuCloseButton closeMenu={closeMenu} />
              </div>
            </GrowTransition>
          )}
        </StyledMenu>
      </Box>
    </ClickAwayListener>
  );
};

export default Menu;
