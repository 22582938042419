import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import UserRows from './UserRows';
import UserResultDialog from './UserResultDialog';
import { FormContent } from '../index.styles';

interface Props {
  isEdit?: boolean;
  handleSubmit: (values: FormikValues) => void;
  beforeRows?: (errors: any, formikValues: FormikValues, setFieldValue: Function) => React.ReactNode;
  validationSchema?: any | (() => any);
  formInitValues?: any;
  successMessage: string;
  onSuccess?: () => void;
}

const UserForm = ({
  isEdit,
  beforeRows,
  handleSubmit,
  validationSchema,
  formInitValues,
  successMessage,
  onSuccess,
}: Props) => (
  <Formik initialValues={formInitValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
    {({ values, errors, setFieldValue }) => (
      <Form id="user-form">
        <FormContent>
          {beforeRows && beforeRows(errors, values, setFieldValue)}
          <UserRows isEdit={isEdit} formikValues={values} />
        </FormContent>
        <UserResultDialog onSuccess={onSuccess} successMessage={successMessage} />
      </Form>
    )}
  </Formik>
);

export default UserForm;
