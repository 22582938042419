import React from 'react';
import { useHistory } from 'react-router-dom';

import { EngagementReference } from './index.style';
import PageTitle from '../../../components/titles/PageTitle';

interface Props {
  reference: string;
}

const EngagementSummaryHeader = ({ reference }: Props) => {
  const history = useHistory();

  return (
    <PageTitle onBackButtonClick={() => history.push(`/`)}>
      <PageTitle.Title>Engagement Overview</PageTitle.Title>
      <EngagementReference>{reference}</EngagementReference>
    </PageTitle>
  );
};

export default EngagementSummaryHeader;
