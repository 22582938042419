import React from 'react';

import { LogoLink, MainTitle } from './index.style';
import logo from '../../assets/images/logo.png';

const Logo = () => (
  <MainTitle>
    <LogoLink to="/">
      <img src={logo} alt="IR35" />
    </LogoLink>
  </MainTitle>
);

export default Logo;
