import React from 'react';

import Dialog from '../index';
import BackButton from '../../../BackButton';
import { ButtonFirst } from '../../../buttons/index.style';
import { ContentWrapper } from '../index.styles';

import { mainTheme } from '../../../../assets/styles/variables/themes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;
  message: string;
}

const DeleteDialog = ({ isOpen, onClose, handleDelete, isLoading, message }: Props) => (
  <Dialog
    maxWidth={mainTheme.dialogMaxSize.md}
    isOpen={isOpen}
    dialogTitle={<BackButton insideDialog onClick={onClose} />}
  >
    <ContentWrapper>
      <p>{message}</p>
      <ButtonFirst maxWidth={mainTheme.buttonMaxSize.xs} onClick={handleDelete} disabled={isLoading}>
        Delete
      </ButtonFirst>
    </ContentWrapper>
  </Dialog>
);

export default DeleteDialog;
