import React from 'react';

import { ContentWrapper, StyledMain } from './index.style';

interface Props {
  children: React.ReactNode;
  preview?: boolean;
}

const Main = ({ children, preview }: Props) => <StyledMain preview={preview}>{children}</StyledMain>;

Main.Content = ContentWrapper;

export default Main;
