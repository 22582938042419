import React, { ChangeEvent, useRef } from 'react';

interface Props {
  onUpload: Function;
  Button: React.ComponentType<React.HTMLProps<HTMLButtonElement>>;
  buttonProps?: any;
  title?: string;
}

const UploadButton = ({ onUpload, title, Button, buttonProps }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const fileUploadAction = () => {
    inputRef.current && inputRef.current.click();
  };
  const fileUploadInputChange = (e: ChangeEvent<HTMLInputElement>) => onUpload(e.target.files?.[0]);

  return (
    <>
      <input hidden type="file" ref={inputRef} onChange={fileUploadInputChange} />
      <Button {...buttonProps} onClick={fileUploadAction}>
        {title || 'Upload'}
      </Button>
    </>
  );
};

export default UploadButton;
