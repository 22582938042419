import React from 'react';

import UploadRow from '../UploadRow';
import ViewRow from '../ViewRow';
import { ContractName } from '../index.styles';

import { checkIsWttAdmin } from '../../../../../utils/auth';
import { contractLabelsForUploadContractsModal } from '../../../../../constants/documents/constants';

interface Props {
  requestedDirectly: any;
  contractType: string;
  userType: string;
  handleUpload: Function;
  handleDelete: (id: string) => void;
}

const buildName = (name: string) => `with [${name}]`;

const FilteredRow = ({ requestedDirectly, contractType, userType, handleUpload, handleDelete }: Props) => {
  const contractsList = requestedDirectly[contractType];

  if (!contractsList) {
    return null;
  }

  return (
    <div>
      <ContractName>{contractLabelsForUploadContractsModal[contractType]}</ContractName>
      {contractsList.map((it: any) =>
        it.id ? (
          <ViewRow
            hideDeleteButton={!checkIsWttAdmin(userType)}
            document={{ name: buildName(it.contractWithName), id: it.id }}
            onDelete={handleDelete}
          />
        ) : (
          <UploadRow
            title={buildName(it.contractWithName)}
            handleUpload={handleUpload(contractType, it.contractWith)}
          />
        )
      )}
    </div>
  );
};

export default FilteredRow;
