import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const PreviewBodyHeaderContainer = styled.div`
  ${({ theme: { bgColors } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 65px;
    width: 100%;
    margin: 0 auto;
    padding: 5px 90px;
    background-color: ${bgColors.secondary};

    & > div {
      position: absolute;
      left: 20px;
    }

    & p {
      text-align: center;
    }

    ${mediaMinWidth.xs} {
      padding-top: 0;
      padding-bottom: 0;
    }
  `}
`;

export const PreviewMainWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

  ${mediaMinWidth.sm} {
    padding: 0 25px;
  }
`;
