export const GET_ENGAGEMENTS_REQUEST = 'GET_ENGAGEMENTS_REQUEST';
export const GET_ENGAGEMENTS_SUCCESS = 'GET_ENGAGEMENTS_SUCCESS';
export const GET_ENGAGEMENTS_ERROR = 'GET_ENGAGEMENTS_ERROR';

export interface Engagement {
  id: string;
  reference: string;
  alternateReference?: string;
  shortDescription: string;
  engager: string;
  worker: string;
  sdsStatus: string;
  ir35Status: string;
  startDate: string;
  isActive: boolean;
  sdsDocumentId: string;
}

export interface EngagementsPayload {
  results: Engagement[];
  pageCount: number;
}

export interface GetEngagementsBodyRequest {
  query?: string;
  filter?: {
    sdsStatus?: string;
    ir35Status?: string;
  };
  sort: {
    field?: string;
    asc?: boolean;
  };
  page: {
    maxItems: number;
    skipCount: number;
  };
}

interface GetEngagementsRequest {
  type: typeof GET_ENGAGEMENTS_REQUEST;
}

interface GetEngagementsSuccess {
  type: typeof GET_ENGAGEMENTS_SUCCESS;
  payload: EngagementsPayload;
}

interface GetEngagementsError {
  type: typeof GET_ENGAGEMENTS_ERROR;
  payload: {
    error: string;
  };
}

export type engagementsTypes = GetEngagementsRequest | GetEngagementsSuccess | GetEngagementsError;
