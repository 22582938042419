import React from 'react';
import { FieldInputProps } from 'formik';
import { ErrorMessage } from '../../inputs/InputErrorMessage';
import { MainInput as StyledMainInput, MainTextarea } from '../../inputs/index.style';

interface Props {
  field: FieldInputProps<any>;
  showError: boolean;
  type: string;
  placeholder?: string;
  maxWidth?: number;
  textarea?: boolean;
  disabled?: boolean;
  rows?: number;
}

const InputOrTextarea = ({ rows, textarea, showError, placeholder, maxWidth, field, type, disabled }: Props) => (
  <>
    {textarea ? (
      <MainTextarea showError={showError} placeholder={placeholder} rows={rows} maxWidth={maxWidth} {...field} />
    ) : (
      <StyledMainInput
        showError={showError}
        type={type}
        placeholder={placeholder}
        maxWidth={maxWidth}
        {...field}
        value={field.value || ''}
        disabled={disabled}
      />
    )}
    <ErrorMessage name={field.name} component="span" />
  </>
);

export default InputOrTextarea;
