import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const VerificationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;

  ${mediaMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0 50px 0;
  }
`;

export const LeftColumn = styled.div`
  width: 100%;
  order: 2;

  ${mediaMinWidth.sm} {
    order: 1;
    width: calc(55% - 25px);
  }

  ${mediaMinWidth.md} {
    width: calc(60% - 55px);
  }
`;

export const RightColumn = styled.div`
  width: 100%;
  order: 1;

  ${mediaMinWidth.sm} {
    order: 2;
    width: calc(45% - 25px);
  }

  ${mediaMinWidth.md} {
    width: calc(40% - 55px);
  }
`;

export const InfoMessage = styled.div`
  margin: 0 0 40px;
  padding: 10px 15px;
  background: rgba(116, 63, 133, 0.17);
  font: ${({ theme: { fontSize, fontFamily } }) => `${fontSize.xxs} ${fontFamily.primary}`};
  font-weight: 300;
  font-style: italic;
  line-height: 1.86;

  ${mediaMinWidth.sm} {
    margin: 30px 0 0;
  }

  ${mediaMinWidth.md} {
    margin: 37px 0 0;
    padding: 15px 25px;
  }

  ${mediaMinWidth.lg} {
    margin: 39px 0 0;
  }

  ${mediaMinWidth.xl} {
    margin: 50px 0 0;
  }

  & > p:first-child {
    margin: 0 0 15px;

    ${mediaMinWidth.md} {
      margin: 0 0 30px;
    }
  }
`;
