import styled from 'styled-components';

export const UserTypeContainer = styled.div`
  display: flex;
  width: 100%;

  && > div {
    margin-left: 0;
  }
`;

export const SelectValidationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  position: relative;
`;

export const TitleContainer = styled.div`
  &&& {
    display: flex;
    align-items: center;
  }

  & > h2 {
    margin-top: 0;
  }
`;
