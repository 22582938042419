import React from 'react';

import Logo from '../Logo';
import Menu from './UserInfoPopup';
import { HeaderContainer, StyledHeader } from './index.style';
import PageTitle from './PageTitle';

const Header = () => (
  <HeaderContainer>
    <StyledHeader>
      <Logo />
      <PageTitle />
      <Menu />
    </StyledHeader>
  </HeaderContainer>
);

export default Header;
