import React, { useState } from 'react';
import { isEmpty } from 'lodash/fp';
import { Formik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FormControl from '../../components/form/FormControl';
import { login } from '../../redux/auth/thunks';
import { loginValidation } from './validation';
import LoginLayout from '../../components/layouts/LoginLayout';
import BreakLine from './BreakLine';
import { AppState } from '../../redux/reducers';
import LoginFormTitle from '../../components/layouts/LoginLayout/LoginFormTitle';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { LoginPayload } from '../../constants/auth/actionTypes';
import { ButtonFirst, LinkedButton } from '../../components/buttons/index.style';
import { ForgotPassword, LoginForm, LearnMoreLink, ValidationError } from './index.styles';

interface SelectedState {
  loginError: string | undefined;
}

const formikInit = { email: '', password: '' };

const Login = () => {
  const location = useLocation();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const { loginError } = useSelector<AppState, SelectedState>((state) => ({
    loginError: state.auth.errorMessage,
  }));

  const dispatch = useDispatch();

  const handleSubmit = (values: LoginPayload, { setSubmitting }: { setSubmitting: Function }) => {
    dispatch(login(values, location));
    setSubmitting(false);
  };

  return (
    <LoginLayout>
      <>
        <LoginFormTitle title="Login" />
        <Formik initialValues={formikInit} validationSchema={loginValidation} onSubmit={handleSubmit}>
          {({ isSubmitting, handleChange, values, errors }) => (
            <LoginForm>
              {(!isEmpty(errors) || loginError) && (
                <ValidationError>*{errors.email || errors.password || loginError}*</ValidationError>
              )}
              <FormControl
                id="email"
                name="email"
                autoComplete="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
              />
              <FormControl
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                label="Password"
                value={values.password}
                onChange={handleChange}
              />
              <ForgotPassword>
                <LinkedButton type="button" onClick={() => setShowForgotPassword(true)}>
                  Forgot password?
                </LinkedButton>
              </ForgotPassword>
              <ButtonFirst disabled={isSubmitting}>Login</ButtonFirst>
              <BreakLine />
              <LearnMoreLink>
                Don’t have an account?
                <a href="https://www.ir35navigator.com">Learn more</a>
              </LearnMoreLink>
            </LoginForm>
          )}
        </Formik>
        <ForgotPasswordDialog isOpen={showForgotPassword} onClose={() => setShowForgotPassword(false)} />
      </>
    </LoginLayout>
  );
};

export default Login;
