import React from 'react';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';

import InputOrTextarea from '../../form/InputOrTextarea';
import DatePickerInput from '../../form/DatePickerInput';

import { InputWrapper } from './index.styles';

interface Props {
  name: string;
  fullWidth?: boolean;
  type?: string;
  placeholder?: string;
  maxWidth?: number;
  maxFieldWidth?: number;
  datePicker?: boolean;
  textarea?: boolean;
  disabled?: boolean;
  wpq?: boolean;
  rows?: number;
}

interface FieldProps {
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  form: { setFieldValue: Function };
}

const InputField = ({
  name,
  maxWidth,
  maxFieldWidth,
  datePicker,
  textarea,
  fullWidth,
  type = 'text',
  placeholder = '',
  disabled,
  wpq,
  rows = 1,
}: Props) => (
  <Field name={name}>
    {({ field, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
      <InputWrapper wpq={wpq} fullWidth={Boolean(fullWidth)} maxWidth={maxFieldWidth}>
        {datePicker ? (
          <DatePickerInput
            showError={touched && Boolean(error)}
            maxWidth={maxWidth}
            field={field}
            setFieldValue={setFieldValue}
            placeholder={placeholder}
          />
        ) : (
          <InputOrTextarea
            textarea={textarea}
            field={field}
            showError={touched && Boolean(error)}
            placeholder={placeholder}
            maxWidth={maxWidth}
            type={type}
            disabled={disabled}
            rows={rows}
          />
        )}
      </InputWrapper>
    )}
  </Field>
);

export default InputField;
