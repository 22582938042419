import React from 'react';

import { ControlContainer } from '../../RequestDocumentControl/index.style';
import { PartyName } from '../../index.style';

import { getPartyTitle } from '../../../../../../utils/supplyChainUtils';
import { EngagementParty } from '../../../../../../constants/engagement/actionTypes';

interface Props {
  party?: EngagementParty;
}

const Party = ({ party }: Props) =>
  party ? (
    <ControlContainer>
      <PartyName>{getPartyTitle(party)}</PartyName>
    </ControlContainer>
  ) : null;

export default Party;
