import { ROUTES, ROUTE_TYPE_PUBLIC } from './constants/routing';
import Login from './pages/Login';
import SetUpAccount from './pages/SetUpAccount';
import MainRouting from './pages/PagesRouting';
import SDSFinalise from './pages/SDS/SDSFinalise';
import SDSAccept from './pages/SDS/SDSAccept';
import DocumentPreview from './pages/Preview/PreviewDocument';
import { MainRouter } from './declarations/routing';
import SDSVerification from './pages/SDS/SDSVerification';

const routes: MainRouter[] = [
  {
    path: ROUTES.LOGIN,
    exact: true,
    type: ROUTE_TYPE_PUBLIC,
    component: Login,
  },
  {
    path: ROUTES.SET_UP_ACCOUNT,
    exact: true,
    component: SetUpAccount,
  },
  {
    path: ROUTES.PREVIEW_SDS,
    auth: ['engager'],
    exact: true,
    requireTermsAgreement: true,
    component: SDSFinalise,
  },
  {
    path: ROUTES.ACCEPT_SDS,
    auth: ['intermediary'],
    exact: true,
    requireTermsAgreement: true,
    component: SDSAccept,
  },
  {
    path: ROUTES.PREVIEW,
    exact: true,
    requireTermsAgreement: true,
    component: DocumentPreview,
  },
  {
    path: ROUTES.SDS_VERIFICATION,
    exact: true,
    type: ROUTE_TYPE_PUBLIC,
    component: SDSVerification,
  },
  {
    path: '/*',
    exact: true,
    requireTermsAgreement: true,
    component: MainRouting,
  },
];

export default routes;
