import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import {
  DropzoneWrapper,
  DropzoneInnerWrapper,
  DropzoneInputWrapper,
  DropzoneTab,
  DropzoneLabel,
  DropzoneContainer,
} from './index.style';
import Icon from '../../icons/Icon';
import attachFile from '../../../assets/images/attach-file.png';
import { ErrorMessage } from '../InputErrorMessage';

interface Props {
  name: string;
  label?: string;
  showError: boolean;
  setFieldValue: (field: string, value: any) => void;
}

const Dropzone = ({ name, label, setFieldValue, showError }: Props) => {
  const [fileName, setFileName] = useState<string | undefined>('');
  const onDrop = useCallback(
    (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0];

      if (acceptedFile) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const arrayBuffer = reader.result;

          setFieldValue(name, { arrayBuffer, name: acceptedFile.path, type: acceptedFile.type });
          setFileName(acceptedFile.path);
        };

        reader.readAsArrayBuffer(acceptedFile);
      }
    },
    [name, setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <DropzoneContainer>
      {label && <DropzoneLabel htmlFor={name}>{label}</DropzoneLabel>}
      <DropzoneWrapper>
        <DropzoneTab>
          <Icon iconSrc={attachFile} size="xs" />
        </DropzoneTab>
        <DropzoneInnerWrapper>
          <DropzoneInputWrapper showError={showError} {...getRootProps()}>
            <input id={name} {...getInputProps()} />
            <p>
              {fileName ? (
                fileName
              ) : (
                <>
                  <span>Drag and drop or Click here to upload file</span>{' '}
                  <span>Once uploaded the file name will appear here</span>
                </>
              )}
            </p>
          </DropzoneInputWrapper>
        </DropzoneInnerWrapper>
      </DropzoneWrapper>
      <ErrorMessage name={name} component="span" />
    </DropzoneContainer>
  );
};

export default Dropzone;
