import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ImageContainer } from './index.styles';

interface Props {
  fileURl: string;
  fileName: string;
  getCurrentImageWidth?: (value: number) => void;
}

const PreviewImageViewer = ({ fileURl, fileName, getCurrentImageWidth }: Props) => {
  const onLoad = (value: any) => {
    getCurrentImageWidth && getCurrentImageWidth(value?.target?.width);
  };

  return (
    <ImageContainer>
      <TransformWrapper pan={{ lockAxisX: true, lockAxisY: true }}>
        <TransformComponent>
          <img onLoad={onLoad} src={fileURl} alt={fileName} />
        </TransformComponent>
      </TransformWrapper>
    </ImageContainer>
  );
};

export default PreviewImageViewer;
