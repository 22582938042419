import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const InfoRowWrapper = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 25px 0;

  ${({ withBorder, theme: { keyColors, borderWidth } }) =>
    withBorder &&
    css`
      border-bottom: ${borderWidth.xs} solid ${keyColors.first};
    `};

  ${mediaMinWidth.xs} {
    flex-direction: row;
  }
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > span {
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

export const StyledText = styled.span<{ isEmpty?: boolean }>`
  ${({ isEmpty, theme: { fontSize, fontFamily, fontColors } }) => css`
    color: ${isEmpty ? fontColors.secondary : fontColors.primary};
    font: ${fontSize.xs} ${fontFamily.primary};
    font-weight: 300;
  `}
`;

export const EditButton = styled.button`
  ${({ theme: { fontSize, transition, keyColors } }) => css`
    margin-left: auto;
    color: ${keyColors.first};
    background-color: transparent;
    border: none;
    font-size: ${fontSize.sm};
    font-weight: 700;
    text-decoration: underline;
    transition: ${transition};
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      transition: ${transition};
    }
  `}
`;
