import React from 'react';
import Dialog from '../../index';
import DialogBackTitle from '../../DialogBackButton';
import EngagementFormComponent from '../../../../form/EngagementForm/index';
import { mainTheme } from '../../../../../assets/styles/variables/themes';
import { TitleWrapper } from '../EditEngagementDialog/index.style';
import { ButtonFirst } from '../../../../buttons/index.style';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/reducers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateEngagementDialog = (props: Props) => {
  const isLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isLoading);

  return (
    <Dialog
      maxWidth={mainTheme.dialogMaxSize.md}
      isOpen={props.isOpen}
      dialogTitle={
        <TitleWrapper>
          <DialogBackTitle title="New Engagement" onClick={props.onClose} />
          <ButtonFirst maxWidth={mainTheme.buttonMaxSize.xs} type="submit" disabled={isLoading} form="engagement">
            Next
          </ButtonFirst>
        </TitleWrapper>
      }
    >
      <EngagementFormComponent />
    </Dialog>
  );
};

export default CreateEngagementDialog;
