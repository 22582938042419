import styled from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const Iframe = styled.iframe`
  width: 100%;
  border: none;
  height: 100%;
  min-height: 650px;

  ${mediaMinWidth.xl} {
    height: calc(100vh - 240px);
    min-height: 100px;
    overflow-y: auto;
  }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Toolbar = styled.div<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
  padding: 4px;
  background: rgba(71, 71, 71, 1);
  user-select: none;

  i {
    position: static;
    transform: none;
  }
`;

export const DownloadButton = styled.a`
  display: inline-flex;
  align-items: center;
  height: 25px;
  padding: 5px;
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 12px;
  text-decoration: none;

  i {
    margin-right: 5px;
    color: inherit;
    font-size: 16px;
  }

  &:hover {
    background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.32) rgba(0, 0, 0, 0.38) rgba(0, 0, 0, 0.42);
  }
`;
