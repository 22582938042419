import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const ContactsContainer = styled.div`
  margin: 0 0 30px;

  & p:last-child {
    margin: 25px 0 0;
  }

  ${mediaMinWidth.xs} {
    margin: 0;
  }
`;

export const ContactsRow = styled.p`
  ${({ theme: { fontSize, fontFamily } }) => css`
    font: ${fontSize.xxs} ${fontFamily.primary};
    color: #5c5c5c;
    margin: 0 0 10px;

    & a {
      color: inherit;
      text-decoration: none;
    }
  `}
`;
