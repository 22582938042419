import React from 'react';
import { LoginInput } from '../../inputs/index.style';
import { InputLabel, MaterialFormControl } from './index.style';

interface Props {
  label: string;
  name: string;
  id?: string;
  type?: string;
  autoComplete?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: any) => void;
}

const FormControl = (props: Props) => (
  <MaterialFormControl fullWidth>
    <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
    <LoginInput
      value={props.value}
      onChange={props.onChange}
      id={props.id}
      type={props.type}
      name={props.name}
      autoComplete={props.autoComplete}
      placeholder={props.placeholder}
    />
  </MaterialFormControl>
);

export default FormControl;
