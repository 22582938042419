import styled from 'styled-components';

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    max-height: 691px;
    max-width: 1105px;
    width: 100%;
  }
`;
