import axios from 'axios';
import { RequestWPQPayload } from '../../constants/wpq/actionTypes';

const requestWPQ = (requestBody: RequestWPQPayload): Promise<any> =>
  axios.post(`/documents/request-wpq-document`, requestBody);

const getLinkToWPQForm = (engagementId: string): Promise<{ data: { wpqLink: string } }> =>
  axios.get(`/documents/wpq/link/${engagementId}`);

const uploadUWPQDocument = (fileData: FormData): Promise<{ data: { id: string } }> =>
  axios.post('/documents/upload/wpq-document', fileData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export default {
  requestWPQ,
  getLinkToWPQForm,
  uploadUWPQDocument,
};
