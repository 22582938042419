import {
  GET_ENGAGEMENTS_REQUEST,
  GET_ENGAGEMENTS_SUCCESS,
  GET_ENGAGEMENTS_ERROR,
  EngagementsPayload,
} from '../../constants/engagements/actionTypes';

export const getEngagementsRequest = () => ({
  type: GET_ENGAGEMENTS_REQUEST,
});

export const getEngagementsSuccess = (payload: EngagementsPayload) => ({
  type: GET_ENGAGEMENTS_SUCCESS,
  payload,
});

export const getEngagementsError = (error: string) => ({
  type: GET_ENGAGEMENTS_ERROR,
  payload: {
    error,
  },
});
