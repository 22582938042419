import React from 'react';
import { Formik, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonFirst } from '../../../../components/buttons/index.style';
import Dropzone from '../../../../components/inputs/InputDropzone';
import Section from '../SDSVerificationSection';
import ButtonWithSpinner from '../../../../components/buttons/ButtonWithSpinner';
import { Form } from './index.style';

import { verifySDSFile } from '../../../../redux/sdsVerification/thunks';
import { resetVerifySDSState } from '../../../../redux/sdsVerification/actions';
import { AppState } from '../../../../redux/reducers';
import { verifySDSValidation } from './validation';

import { mainTheme } from '../../../../assets/styles/variables/themes';
import logoWithPuzzle from '../../../../assets/images/logo_with_puzzle.png';

const initialValues = {
  sdsFile: null,
};

const SDSVerificationForm = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector<AppState, boolean>(({ sdsVerification }) => sdsVerification.isLoading);

  const handleSubmit = async ({ sdsFile }: FormikValues, { setSubmitting }: { setSubmitting: Function }) => {
    dispatch(resetVerifySDSState());
    dispatch(verifySDSFile(sdsFile));
    setSubmitting(false);
  };

  return (
    <Section icon={logoWithPuzzle}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={verifySDSValidation}>
        {({ errors, setFieldValue, handleSubmit, touched }) => (
          <Form
            id="upload-sds"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Dropzone
              name="sdsFile"
              label="Upload SDS"
              setFieldValue={(...props) => {
                dispatch(resetVerifySDSState());
                setFieldValue(...props);
              }}
              showError={Boolean(touched.sdsFile && errors.sdsFile)}
            />
            <ButtonWithSpinner
              Component={ButtonFirst}
              label="Verify"
              type="submit"
              maxWidth={mainTheme.buttonMaxSize.xs}
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </Section>
  );
};

export default SDSVerificationForm;
