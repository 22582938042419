import {
  Contracts,
  DELETE_DOCUMENT_ERROR,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DocumentsFormValues,
  documentsTypes,
  FormValues,
  GET_CONTRACTS_ERROR,
  GET_CONTRACTS_REQUEST,
  GET_CONTRACTS_SUCCESS,
  GET_REQUIRED_DOCUMENTS_ERROR,
  GET_REQUIRED_DOCUMENTS_REQUEST,
  GET_REQUIRED_DOCUMENTS_SUCCESS,
  REQUEST_DOCUMENTS_REQUEST,
  REQUEST_DOCUMENTS_SUCCESS,
  REQUEST_DOCUMENTS_ERROR,
  RequestInfoParties,
  RESET_DOCUMENTS_STATE,
  SET_REQUEST_INFO_PARTIES,
  UPLOAD_CONTRACT_ERROR,
  UPLOAD_CONTRACT_REQUEST,
  UPLOAD_CONTRACT_SUCCESS,
  UploadedContractFormValues,
} from '../../constants/documents/actionTypes';
import { AllUserDocuments } from '../../constants/user/actionTypes';

export interface DocumentsStore {
  isLoading: boolean;
  isRequiredDocumentsLoading: boolean;
  isContractsLoading: boolean;
  parties: RequestInfoParties;
  values: { contracts: FormValues; documents: DocumentsFormValues };
  userDocuments: AllUserDocuments;
  contracts: Contracts;
  uploadedContracts: UploadedContractFormValues;
  errorMessage: string;
  successRequest: boolean;
}

const initialState: DocumentsStore = {
  isLoading: false,
  isRequiredDocumentsLoading: false,
  isContractsLoading: false,
  parties: {
    requestFrom: {
      firstName: '',
      lastName: '',
      userName: '',
    },
  },
  userDocuments: {
    personal: {},
    company: {},
  },
  contracts: {
    requestedDirectly: [],
    requestedInDirectly: [],
    uploaded: [],
  },
  uploadedContracts: {},
  values: {
    contracts: {},
    documents: { personal: {}, company: {} },
  },
  successRequest: false,
  errorMessage: '',
};

export default (state: DocumentsStore = initialState, action: documentsTypes): DocumentsStore => {
  switch (action.type) {
    case SET_REQUEST_INFO_PARTIES: {
      return { ...state, parties: action.payload };
    }
    case REQUEST_DOCUMENTS_REQUEST:
    case DELETE_DOCUMENT_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_REQUIRED_DOCUMENTS_REQUEST: {
      return { ...state, isRequiredDocumentsLoading: true };
    }
    case GET_CONTRACTS_REQUEST: {
      return { ...state, isContractsLoading: true };
    }
    case GET_REQUIRED_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        values: {
          ...state.values,
          documents: action.payload.documentsFormValues,
        },
        userDocuments: action.payload.userDocuments,
        isRequiredDocumentsLoading: false,
      };
    }
    case GET_CONTRACTS_SUCCESS: {
      return {
        ...state,
        values: {
          ...state.values,
          contracts: action.payload.contractsFormValues,
        },
        contracts: action.payload.allContracts,
        uploadedContracts: action.payload.uploadedContracts,
        isContractsLoading: false,
      };
    }
    case DELETE_DOCUMENT_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case REQUEST_DOCUMENTS_SUCCESS: {
      return { ...state, isLoading: false, errorMessage: '', successRequest: true };
    }
    case GET_REQUIRED_DOCUMENTS_ERROR: {
      return { ...state, ...action.payload, isRequiredDocumentsLoading: false };
    }
    case GET_CONTRACTS_ERROR: {
      return { ...state, ...action.payload, isContractsLoading: false };
    }
    case REQUEST_DOCUMENTS_ERROR:
    case DELETE_DOCUMENT_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case UPLOAD_CONTRACT_REQUEST: {
      return { ...state, isContractsLoading: true };
    }
    case UPLOAD_CONTRACT_SUCCESS: {
      return { ...state, isContractsLoading: false };
    }
    case UPLOAD_CONTRACT_ERROR: {
      return { ...state, ...action.payload, isContractsLoading: false };
    }
    case RESET_DOCUMENTS_STATE:
      return { ...initialState };
    default: {
      return state;
    }
  }
};
