import React from 'react';

import SectionTitle from '../../../components/titles/SectionTitle';
import { SectionContainer, SectionBody } from './index.style';

interface Props {
  children: React.ReactNode;
  title: string;
  icon: string;
  controlButton?: React.ReactNode;
}

const Section = ({ children, title, icon, controlButton }: Props) => (
  <SectionContainer>
    <SectionTitle title={title} icon={icon} controlButton={controlButton} />
    <SectionBody>{children}</SectionBody>
  </SectionContainer>
);

export default Section;
