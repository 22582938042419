export const GET_ENGAGEMENT_REQUEST = 'GET_ENGAGEMENT_REQUEST';
export const GET_ENGAGEMENT_SUCCESS = 'GET_ENGAGEMENT_SUCCESS';
export const CREATE_ENGAGEMENT_REQUEST = 'CREATE_ENGAGEMENT_REQUEST';
export const CREATE_ENGAGEMENT_SUCCESS = 'CREATE_ENGAGEMENT_SUCCESS';
export const UPDATE_ENGAGEMENT_REQUEST = 'UPDATE_ENGAGEMENT_REQUEST';
export const UPDATE_ENGAGEMENT_SUCCESS = 'UPDATE_ENGAGEMENT_SUCCESS';
export const DELETE_ENGAGEMENT_REQUEST = 'DELETE_ENGAGEMENT_REQUEST';
export const DELETE_ENGAGEMENT_SUCCESS = 'DELETE_ENGAGEMENT_SUCCESS';
export const SET_ENGAGEMENT_DATA_ERROR = 'SET_ENGAGEMENT_DATA_ERROR';
export const RESET_ENGAGEMENT_STATE = 'RESET_ENGAGEMENT_STATE';
export const DELETE_PARTY_FROM_ENGAGEMENT_REQUEST = 'DELETE_PARTY_FROM_ENGAGEMENT_REQUEST';
export const DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS = 'DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS';
export const DELETE_PARTY_FROM_ENGAGEMENT_ERROR = 'DELETE_PARTY_FROM_ENGAGEMENT_ERROR';
export const ADD_TO_PARTY_ENGAGEMENT_REQUEST = 'ADD_TO_PARTY_ENGAGEMENT_REQUEST';
export const ADD_TO_PARTY_ENGAGEMENT_SUCCESS = 'ADD_TO_PARTY_ENGAGEMENT_SUCCESS';
export const ADD_TO_PARTY_ENGAGEMENT_ERROR = 'ADD_TO_PARTY_ENGAGEMENT_ERROR';
export const UPLOAD_SDS_FILE_REQUEST = 'UPLOAD_SDS_FILE_REQUEST';
export const UPLOAD_SDS_FILE_SUCCESS = 'UPLOAD_SDS_FILE_SUCCESS';
export const UPLOAD_ATTACHED_DOCUMENTS_REQUEST = 'UPLOAD_ATTACHED_DOCUMENTS_REQUEST';
export const UPLOAD_ATTACHED_DOCUMENTS_SUCCESS = 'UPLOAD_ATTACHED_DOCUMENTS_SUCCESS';
export const UPLOAD_ATTACHED_DOCUMENTS_ERROR = 'UPLOAD_ATTACHED_DOCUMENTS_ERROR';
export const DELETE_ATTACHED_DOCUMENTS_REQUEST = 'DELETE_ATTACHED_DOCUMENTS_REQUEST';
export const DELETE_ATTACHED_DOCUMENTS_SUCCESS = 'DELETE_ATTACHED_DOCUMENTS_SUCCESS';
export const DELETE_ATTACHED_DOCUMENTS_ERROR = 'DELETE_ATTACHED_DOCUMENTS_ERROR';
export const FINALISE_ENGAGEMENT_REQUEST = 'FINALISE_ENGAGEMENT_REQUEST';
export const FINALISE_ENGAGEMENT_SUCCESS = 'FINALISE_ENGAGEMENT_SUCCESS';
export const FINALISE_ENGAGEMENT_ERROR = 'FINALISE_ENGAGEMENT_ERROR';
export const RESET_FINALISE_ENGAGEMENT_RESULT = 'RESET_FINALISE_ENGAGEMENT_RESULT';
export const RESET_UPLOAD_SDS_STATE = 'RESET_UPLOAD_SDS_STATE';
export const RESET_EDIT_ENGAGEMENT_STATE = 'RESET_EDIT_ENGAGEMENT_STATE';
export const TOGGLE_FINALISE_SDS_MODAL = 'TOGGLE_FINALISE_SDS_MODAL';

export interface EngagementPayload {
  id?: string;
  shortDescription: string;
  alternateReference?: string;
  description?: string;
  startDate?: string | null;
  endDate?: string | null;
  budget?: number;
  assumedTaxRisk?: number;
  reference: string;
  sdsStatus: string;
  ir35Status: string;
}

export interface EngagementParty {
  firstName: string | null;
  lastName: string | null;
  company?: string | null;
  userName: string;
  userType?: string;
}

export interface PartiesInfo {
  engager: EngagementParty;
  intermediary1: EngagementParty;
  intermediary2: EngagementParty;
  intermediary3: EngagementParty;
  intermediary4: EngagementParty;
  worker: EngagementParty;
  ir35LTD: EngagementParty;
}

export interface EngagementDocument {
  name: string;
  id: string;
}

export interface SdsDocumentsInfo {
  finalSDS: string | null;
  indicativeSDS: string | null;
}

export interface EngagementDocumentsInfo {
  id: string;
  name: string;
}

export interface WPQInfo {
  engagerWPQInfo: {
    enagagerWPQBaseLink: string;
    engagerWPQ: string | null;
    requested: boolean;
  };
  workerWPQInfo: {
    workerWPQBaseLink: string;
    workerWPQ: string | null;
    requested: boolean;
  };
}

export interface FullEngagementPayload {
  engagementInfo: {
    id?: string;
    shortDescription: string;
    alternateReference?: string;
    description?: string;
    startDate?: string | null;
    endDate?: string | null;
    budget?: number;
    assumedTaxRisk?: number;
    reference: string;
    sdsStatus: string;
    ir35Status: string;
  };
  ir35LTDDefaultUserInfo: EngagementParty;
  partiesInfo: PartiesInfo;
  sdsDocumentsInfo: SdsDocumentsInfo;
  engagementDocumentsInfo: EngagementDocument[];
  wpqInfo: WPQInfo;
}

export interface SDSFilePayload {
  sdsFile: {
    arrayBuffer: ArrayBuffer;
    name: string;
    type: string;
  };
  sdsStatus: string;
  ir35Status: string;
}

interface GetEngagementRequest {
  type: typeof GET_ENGAGEMENT_REQUEST;
}

interface GetEngagementSuccess {
  type: typeof GET_ENGAGEMENT_SUCCESS;
  payload: FullEngagementPayload;
}

interface CreateEngagementRequest {
  type: typeof CREATE_ENGAGEMENT_REQUEST;
}

interface CreateEngagementSuccess {
  type: typeof CREATE_ENGAGEMENT_SUCCESS;
  payload: {
    engagementId: string;
  };
}

interface UpdateEngagementRequest {
  type: typeof UPDATE_ENGAGEMENT_REQUEST;
}

interface UpdateEngagementSuccess {
  type: typeof UPDATE_ENGAGEMENT_SUCCESS;
}

interface DeleteEngagementRequest {
  type: typeof DELETE_ENGAGEMENT_REQUEST;
}

interface DeleteEngagementSuccess {
  type: typeof DELETE_ENGAGEMENT_SUCCESS;
}

interface SetEngagementDataError {
  type: typeof SET_ENGAGEMENT_DATA_ERROR;
  payload: {
    error: string;
  };
}

interface ResetEngagementState {
  type: typeof RESET_ENGAGEMENT_STATE;
}

interface DeletePartyFromEngagementRequest {
  type: typeof DELETE_PARTY_FROM_ENGAGEMENT_REQUEST;
}

interface DeletePartyFromEngagementSuccess {
  type: typeof DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS;
  payload: {
    engagementPartyType: string;
  };
}

interface DeletePartyFromEngagementError {
  type: typeof DELETE_PARTY_FROM_ENGAGEMENT_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface AddToPartyEngagementRequest {
  type: typeof ADD_TO_PARTY_ENGAGEMENT_REQUEST;
}

interface AddToPartyEngagementSuccess {
  type: typeof ADD_TO_PARTY_ENGAGEMENT_SUCCESS;
  payload: {
    engagementParty: EngagementParty;
    engagementPartyType: string;
  };
}

interface AddToPartyEngagementError {
  type: typeof ADD_TO_PARTY_ENGAGEMENT_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface UploadSDSFileRequest {
  type: typeof UPLOAD_SDS_FILE_REQUEST;
}

interface UploadSDSFileSuccess {
  type: typeof UPLOAD_SDS_FILE_SUCCESS;
}

interface UploadAttachedDocumentRequest {
  type: typeof UPLOAD_ATTACHED_DOCUMENTS_REQUEST;
}

interface UploadAttachedDocumentSuccess {
  type: typeof UPLOAD_ATTACHED_DOCUMENTS_SUCCESS;
}

interface UploadAttachedDocumentError {
  type: typeof UPLOAD_ATTACHED_DOCUMENTS_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface DeleteAttachedDocumentRequest {
  type: typeof DELETE_ATTACHED_DOCUMENTS_REQUEST;
}

interface DeleteAttachedDocumentSuccess {
  type: typeof DELETE_ATTACHED_DOCUMENTS_SUCCESS;
}

interface DeleteAttachedDocumentError {
  type: typeof DELETE_ATTACHED_DOCUMENTS_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface FinaliseEngagementRequest {
  type: typeof FINALISE_ENGAGEMENT_REQUEST;
}

interface FinaliseEngagementSuccess {
  type: typeof FINALISE_ENGAGEMENT_SUCCESS;
}

interface FinaliseEngagementError {
  type: typeof FINALISE_ENGAGEMENT_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface ResetFinaliseEngagementResult {
  type: typeof RESET_FINALISE_ENGAGEMENT_RESULT;
}

interface ResetUploadSdsState {
  type: typeof RESET_UPLOAD_SDS_STATE;
}

interface ResetEditEngagementState {
  type: typeof RESET_EDIT_ENGAGEMENT_STATE;
}

interface ToggleFinaliseSDSModal {
  type: typeof TOGGLE_FINALISE_SDS_MODAL;
}

export type engagementTypes =
  | GetEngagementRequest
  | GetEngagementSuccess
  | CreateEngagementRequest
  | CreateEngagementSuccess
  | UpdateEngagementRequest
  | UpdateEngagementSuccess
  | DeleteEngagementRequest
  | DeleteEngagementSuccess
  | SetEngagementDataError
  | ResetEngagementState
  | DeletePartyFromEngagementSuccess
  | DeletePartyFromEngagementError
  | DeletePartyFromEngagementRequest
  | AddToPartyEngagementSuccess
  | AddToPartyEngagementError
  | AddToPartyEngagementRequest
  | UploadSDSFileRequest
  | UploadSDSFileSuccess
  | UploadAttachedDocumentRequest
  | UploadAttachedDocumentSuccess
  | UploadAttachedDocumentError
  | DeleteAttachedDocumentRequest
  | DeleteAttachedDocumentSuccess
  | DeleteAttachedDocumentError
  | FinaliseEngagementRequest
  | FinaliseEngagementSuccess
  | FinaliseEngagementError
  | ResetFinaliseEngagementResult
  | ResetUploadSdsState
  | ResetEditEngagementState
  | ToggleFinaliseSDSModal;
