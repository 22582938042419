import styled from 'styled-components';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 50px;
`;

export const SectionBody = styled.div`
  padding: 30px 10px 0 3.438rem;
`;
