import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../../../icons/Icon';
import trash from '../../../../../../assets/images/trash-bin.jpg';
import { ButtonSecond } from '../../../../../buttons/index.style';

interface Props {
  onClick: (arg?: any) => void;
  idDocument: string;
}

const PreviewButton = ({ onClick, idDocument }: Props) => (
  <>
    <Icon iconSrc={trash} size="lg" onClick={onClick} />
    <ButtonSecond as={Link} to={`/preview/${idDocument}`} active defaultPointer>
      on file
    </ButtonSecond>
  </>
);

export default PreviewButton;
