import styled, { css } from 'styled-components';
import Select from 'react-select';

import { mediaMinWidth } from '../../assets/styles/media';

export const PrimarySelect = styled(Select)<{ showError?: boolean }>`
  ${({ showError, theme: { fontSize, bgColors, keyColors, fontColors, fontFamily } }) => css`
    width: 100%;

    & > .select__control {
      min-height: 40px;
      border: solid 1px ${showError ? keyColors.third : '#88bfff'};

      &:hover {
        border: solid 1px ${showError ? keyColors.third : '#88bfff'};
      }

      .select__indicator-separator {
        display: none;
      }

      ${mediaMinWidth.xxl} {
        min-height: 60px;
      }
    }

    & > .select__menu {
      margin: 0;

      .select__option {
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${fontColors.tertiary};

        font-family: ${fontFamily.primary};
        font-size: ${fontSize.xxxs};
        font-weight: bold;
        line-height: 1.29;

        &:hover,
        &:active {
          background-color: ${bgColors.secondary};
        }

        ${mediaMinWidth.xxl} {
          height: 60px;
        }
      }

      .select__option--is-selected {
        background-color: ${bgColors.secondary};
      }
    }
  `}
`;
