export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_FULL_USER_DATA = 'SET_FULL_USER_DATA';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL';
export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_ERROR = 'INVITE_USER_ERROR';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INFO_DIALOG_CLOSE = 'INFO_DIALOG_CLOSE';
export const USER_REQUEST_FAIL = 'USER_REQUEST_FAIL';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const RESET_USER_STATE = 'RESET_USER_STATE';
export const GET_SUGGESTION_USER_LIST_REQUEST = 'GET_SUGGESTION_USER_LIST_REQUEST';
export const GET_SUGGESTION_USER_LIST_SUCCESS = 'GET_SUGGESTION_USER_LIST_SUCCESS';
export const GET_SUGGESTION_USER_LIST_ERROR = 'GET_SUGGESTION_USER_LIST_ERROR';
export const GET_USER_DOCUMENTS_REQUEST = 'GET_USER_DOCUMENTS_REQUEST';
export const GET_USER_DOCUMENTS_SUCCESS = 'GET_USER_DOCUMENTS_SUCCESS';
export const GET_USER_DOCUMENTS_ERROR = 'GET_USER_DOCUMENTS_ERROR';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const UPLOAD_REQUIRE_DOCUMENT_REQUEST = 'UPLOAD_REQUIRE_DOCUMENT_REQUEST';
export const UPLOAD_REQUIRE_DOCUMENT_SUCCESS = 'UPLOAD_REQUIRE_DOCUMENT_SUCCESS';
export const UPLOAD_REQUIRE_DOCUMENT_ERROR = 'UPLOAD_REQUIRE_DOCUMENT_ERROR';

export interface SuggestionUserPayload {
  firstName: string;
  lastName: string;
  company: string;
  userName: string;
}

export interface SuggestionUser {
  engager?: SuggestionUserPayload[];
  intermediary1?: SuggestionUserPayload[];
  intermediary2?: SuggestionUserPayload[];
  intermediary3?: SuggestionUserPayload[];
  intermediary4?: SuggestionUserPayload[];
  ir35Navigator?: SuggestionUserPayload[];
  worker?: SuggestionUserPayload[];
}

export interface SuggestionLoading {
  engager?: boolean;
  intermediary1?: boolean;
  intermediary2?: boolean;
  intermediary3?: boolean;
  intermediary4?: boolean;
  ir35Navigator?: boolean;
  worker?: boolean;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  company?: string;
  jobTitle?: string;
  userType: string;
  userName: string;
  userFirstLogin: boolean;
}

export interface ExtendedUser extends User {
  addressLine1: string;
  postcode: string;
  sendInvite?: boolean;
  mobile?: string;
  addressLine2?: string;
  addressLine3?: string;
}

export interface NewUser extends ExtendedUser {
  userName: string;
  password: string;
}

export interface UserDocuments {
  [key: string]: {
    requested: boolean;
    title: string;
    id: string;
  };
}

export interface AllUserDocuments {
  company: UserDocuments;
  personal: UserDocuments;
}

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UploadRequiredDocumentPayload {
  userName?: string;
  documentType: string;
  file: File;
}

interface SetUserData {
  type: typeof SET_USER_DATA;
  payload: User;
}

interface SetFullUserData {
  type: typeof SET_FULL_USER_DATA;
  payload: ExtendedUser;
}

interface GetUserData {
  type: typeof GET_USER_DATA;
}

interface UpdateUserRequest {
  type: typeof UPDATE_USER_REQUEST;
}

interface UpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
  payload: User;
}

interface UpdateUserSuccess {
  type: typeof UPDATE_USER_SUCCESS;
}

interface UserRequestFail {
  type: typeof USER_REQUEST_FAIL;
  payload: {
    errorMessage: string;
  };
}

interface InviteUserRequest {
  type: typeof INVITE_USER_REQUEST;
}

interface InviteUserSuccess {
  type: typeof INVITE_USER_SUCCESS;
}

interface InviteUserError {
  type: typeof INVITE_USER_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface InfoDialogClose {
  type: typeof INFO_DIALOG_CLOSE;
}

interface GetSuggestionUserListRequest {
  type: typeof GET_SUGGESTION_USER_LIST_REQUEST;
  payload: {
    userType: string;
  };
}

interface GetSuggestionUserListSuccess {
  type: typeof GET_SUGGESTION_USER_LIST_SUCCESS;
  payload: {
    suggestionUserList: SuggestionUserPayload[];
    userType: string;
  };
}

interface GetSuggestionUserListError {
  type: typeof GET_SUGGESTION_USER_LIST_ERROR;
  payload: {
    errorMessage: string;
    userType: string;
  };
}

interface ResetUserState {
  type: typeof RESET_USER_STATE;
}

interface GetUserDocumentsRequest {
  type: typeof GET_USER_DOCUMENTS_REQUEST;
}

interface GetUserDocumentsSuccess {
  type: typeof GET_USER_DOCUMENTS_SUCCESS;
  payload: {
    userDocuments: AllUserDocuments;
  };
}

interface GetUserDocumentsError {
  type: typeof GET_USER_DOCUMENTS_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface ChangePasswordRequest {
  type: typeof CHANGE_PASSWORD_REQUEST;
}

interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
}

interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR;
  payload: {
    errorMessage: string;
  };
}

interface UploadRequireDocumentRequest {
  type: typeof UPLOAD_REQUIRE_DOCUMENT_REQUEST;
}

interface UploadRequireDocumentSuccess {
  type: typeof UPLOAD_REQUIRE_DOCUMENT_SUCCESS;
}

interface UploadRequireDocumentError {
  type: typeof UPLOAD_REQUIRE_DOCUMENT_ERROR;
  payload: {
    errorMessage: string;
  };
}

export type userTypes =
  | SetUserData
  | SetFullUserData
  | GetUserData
  | UserRequestFail
  | InfoDialogClose
  | UpdateUserRequest
  | UpdateUserSuccess
  | InviteUserRequest
  | InviteUserError
  | InviteUserSuccess
  | ResetUserState
  | GetSuggestionUserListRequest
  | GetSuggestionUserListSuccess
  | GetSuggestionUserListError
  | GetUserDocumentsRequest
  | GetUserDocumentsSuccess
  | GetUserDocumentsError
  | ChangePasswordRequest
  | ChangePasswordSuccess
  | ChangePasswordError
  | UploadRequireDocumentRequest
  | UploadRequireDocumentSuccess
  | UploadRequireDocumentError;
