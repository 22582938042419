import React from 'react';
import Icon from '../../../icons/Icon/index';
import leftArrow from '../../../../assets/images/left-arrow.png';
import { BackTextContainer, StyledBackTitle } from './index.styles';

interface Props {
  onClick: () => void;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
}

const DialogBackTitle = ({ title, onClick, children }: Props) => (
  <StyledBackTitle>
    <BackTextContainer onClick={onClick}>
      <Icon iconSrc={leftArrow} />
      <span>{title}</span>
    </BackTextContainer>
    {children}
  </StyledBackTitle>
);

export default DialogBackTitle;
