import React from 'react';
import { components, SingleValueProps } from 'react-select';

import StatusSelect from '../../selects/StatusSelect';
import { TableFilterWrapper } from './index.style';

interface Props {
  name: string;
  defaultLabel: string;
  options: { value: string; label: string }[];
  column: { setFilter: (value: string) => void };
}

const SingleValue = (props: SingleValueProps<any>) => {
  const { value, label } = props.data;

  return <components.SingleValue {...props}>{value ? label : props.selectProps.defaultLabel}</components.SingleValue>;
};

const TableFilter = ({ column: { setFilter }, name, options, defaultLabel }: Props) => (
  <TableFilterWrapper>
    <StatusSelect
      name={name}
      defaultValue={options[0]}
      options={options}
      defaultLabel={defaultLabel}
      onChange={setFilter}
      components={{ SingleValue }}
    />
  </TableFilterWrapper>
);

export default TableFilter;
