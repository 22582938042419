export const userTypes = {
  WORKER: 'worker',
  ENGAGER: 'engager',
  INTERMEDIARY: 'intermediary',
  WTT_ADMIN: 'wtt_admin',
};

export const userInfoTypes = {
  SHORT: 'short',
  ALL: 'all',
};
