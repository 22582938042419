import React from 'react';

import ChangePasswordDialog from './ChangePasswordDialog';
import { useModalToggle } from '../../../../hooks/useModalToggle';
import { ExtendedUser } from '../../../../constants/user/actionTypes';
import { Container } from '../index.styles';
import { Section, SectionContent } from '../../index.styles';
import SectionTitle from '../../../../components/titles/SectionTitle';
import { EditButton, InfoRow, InfoRowWrapper, StyledText } from './index.styles';

import lockIcon from '../../../../assets/images/lock.jpg';
import manArmsUp from '../../../../assets/images/man_arms_up.png';

interface Props {
  user: ExtendedUser;
  onEditClick: () => void;
}

const AccountInfo = ({ onEditClick, user }: Props) => {
  const [showChangePass, setShowChangePass] = useModalToggle(false);

  return (
    <Container>
      <Section>
        <SectionTitle
          title="Account"
          iconSize="xl"
          icon={manArmsUp}
          controlButton={<EditButton onClick={onEditClick}>Edit</EditButton>}
        />
        <SectionContent>
          <InfoRowWrapper withBorder>
            <InfoRow>
              <StyledText>
                {user.firstName} {user.lastName}
              </StyledText>
              <StyledText>{user.email}</StyledText>
            </InfoRow>
          </InfoRowWrapper>
          <InfoRowWrapper withBorder>
            <InfoRow>
              <StyledText isEmpty={!user.company}>{user.company || 'Company'}</StyledText>
              <StyledText>{user.telephone}</StyledText>
            </InfoRow>
            <InfoRow>
              <StyledText isEmpty={!user.jobTitle}>{user.jobTitle || 'Job Title'}</StyledText>
              <StyledText isEmpty={!user.mobile}>{user.mobile || 'Mobile Number'}</StyledText>
            </InfoRow>
          </InfoRowWrapper>
          <InfoRowWrapper>
            <InfoRow>
              <StyledText>{user.addressLine1}</StyledText>
              <StyledText isEmpty={!user.addressLine3}>{user.addressLine3 || 'Address Line 3'}</StyledText>
            </InfoRow>
            <InfoRow>
              <StyledText isEmpty={!user.addressLine2}>{user.addressLine2 || 'Address Line 2'}</StyledText>
              <StyledText>{user.postcode}</StyledText>
            </InfoRow>
          </InfoRowWrapper>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle
          title="Security"
          iconSize="xl"
          icon={lockIcon}
          controlButton={<EditButton onClick={setShowChangePass}>Edit</EditButton>}
        />
        <SectionContent>
          <InfoRowWrapper>
            <InfoRow>
              <StyledText>Change Password</StyledText>
            </InfoRow>
          </InfoRowWrapper>
        </SectionContent>
      </Section>
      <ChangePasswordDialog isOpen={showChangePass} onBackClick={setShowChangePass} />
    </Container>
  );
};

export default AccountInfo;
