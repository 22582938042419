import React from 'react';

import Icon from '../../../../../icons/Icon';
import UploadButton from '../../../../../buttons/UploadButton';
import CheckboxField from '../../../../../form/CheckboxField';
import { StyledCheckbox } from './index.style';

import upload from '../../../../../../assets/images/images-9@3x.png';
import check from '../../../../../../assets/images/fill-1.svg';

interface Props {
  onClick: Function;
  name: string;
}

const RequestDocumentCheckbox = ({ name, onClick }: Props) => (
  <>
    <UploadButton onUpload={onClick} Button={(props) => <Icon iconSrc={upload} size="md" onClick={props.onClick} />} />
    <CheckboxField
      name={name}
      component={(props) => (
        <StyledCheckbox
          icon={<span />}
          checkedIcon={<img src={check} alt="check" height={16} width={17} />}
          {...props}
        />
      )}
    />
  </>
);

export default RequestDocumentCheckbox;
