import React from 'react';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid/Grid';
import { useSelector } from 'react-redux';

import InputField from '../../../../inputs/InputField/index';
import { ButtonFirst } from '../../../../buttons/index.style';
import { AppState } from '../../../../../redux/reducers';
import { FormContent } from '../index.styles';

import { mainTheme } from '../../../../../assets/styles/variables/themes';

interface Props {
  handleSubmit: (values: { wpqLink: string }) => void;
}

const BasicUrlForm = ({ handleSubmit }: Props) => {
  const { buttonMaxSize } = mainTheme;
  const baseLink = useSelector<AppState, string>(({ wpq }) => wpq.baseLink);
  const isLoading = useSelector<AppState, boolean>(({ wpq }) => wpq.isLoading);

  return (
    <Formik initialValues={{ wpqLink: baseLink }} enableReinitialize onSubmit={handleSubmit}>
      <Form id="basicURL">
        <FormContent>
          <Grid item xs={12} sm={9}>
            <InputField wpq disabled fullWidth name="wpqLink" placeholder="[ INSERT Short Standard JOT Form URL ]" />
          </Grid>
          <ButtonFirst maxWidth={buttonMaxSize.xs} type="submit" disabled={isLoading}>
            invite
          </ButtonFirst>
        </FormContent>
      </Form>
    </Formik>
  );
};

export default BasicUrlForm;
