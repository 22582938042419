import React from 'react';
import Grid from '@material-ui/core/Grid';
import { values } from 'lodash/fp';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { FormBlock, CustomLabel } from '../index.style';
import { TwoColumnContainer } from './index.style';
import RequestDocumentControl from '../RequestDocumentControl';
import { RequestInfoParties, UploadedContractFormValues } from '../../../../../constants/documents/actionTypes';
import Party from './ContractDocumentsParty';

import { partyTypes, contractTypes, contractLabels, documentTypes } from '../../../../../constants/documents/constants';
import { uploadContract } from '../../../../../redux/documents/thunks';
import { AppState } from '../../../../../redux/reducers';

interface Props {
  parties: RequestInfoParties;
  handleDelete: Function;
}

const RequestContractsDocuments = ({ handleDelete, parties }: Props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const uploadedContracts = useSelector<AppState, UploadedContractFormValues>(
    ({ documents }) => documents.uploadedContracts
  );
  const { upperParty, lowerParty, requestFrom } = parties;
  const partyType = upperParty ? partyTypes.UPPER_PARTY : partyTypes.LOWER_PARTY;

  const handleUploadRequiredDocument = ({
    contractWith,
    contractType,
  }: {
    contractWith: string;
    contractType: string;
  }) => (file: File) => {
    dispatch(
      uploadContract({ engagementId: id, contractType, contractWith, contractFrom: requestFrom.userName, file })
    );
  };

  const getRequestControlProps = (contractType: string, party: string) => ({
    handleUploadRequiredDocument: handleUploadRequiredDocument({
      contractWith: parties[party]?.userName || '',
      contractType,
    }),
    documentType: documentTypes.CONTRACT,
    handleDelete,
    name: `contracts.${contractType}-${party}`,
    idDocument: uploadedContracts[`${contractType}-${party}`],
  });

  return (
    <FormBlock>
      <h2>Engagement Documents</h2>
      <Grid container justify="flex-end" alignItems="center">
        <Party party={upperParty} />
        <Party party={lowerParty} />
      </Grid>
      {upperParty && lowerParty
        ? values(contractTypes).map((type) => (
            <Grid key={type} container justify="space-between" alignItems="center">
              <CustomLabel>{contractLabels[type]}</CustomLabel>
              <TwoColumnContainer>
                <RequestDocumentControl {...getRequestControlProps(type, partyTypes.UPPER_PARTY)} />
                <RequestDocumentControl {...getRequestControlProps(type, partyTypes.LOWER_PARTY)} />
              </TwoColumnContainer>
            </Grid>
          ))
        : values(contractTypes).map((type) => (
            <RequestDocumentControl
              key={type}
              label={contractLabels[type]}
              {...getRequestControlProps(type, partyType)}
            />
          ))}
    </FormBlock>
  );
};

export default RequestContractsDocuments;
