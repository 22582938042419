import styled from 'styled-components';

import { DoubleSizeGrid } from '../index.style';
import { mediaMinWidth } from '../../../assets/styles/media';

const svg = `<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23979797; stroke-width: 3; stroke-dasharray: 10 7;'/></svg>`;

export const DoubleSizeGridForDashedBox = styled(DoubleSizeGrid)`
  position: relative;
  display: none;

  ${mediaMinWidth.md} {
    display: flex;
  }
`;

export const DashedBox = styled.div`
  ${mediaMinWidth.md} {
    /* prettier-ignore */
    background-image: url("data:image/svg+xml;utf8,${svg}");
    position: absolute;
    z-index: 1;
    top: -330px;
    display: block;
    height: 180px;
    width: 100%;
    margin: 0 0 0 -8px;
  }

  ${mediaMinWidth.lg} {
    top: -350px;
    height: 200px;
  }

  ${mediaMinWidth.xl} {
    top: -380px;
    height: 215px;
  }

  ${mediaMinWidth.xxl} {
    top: -440px;
    height: 260px;
  }

  ${mediaMinWidth.xxxl} {
    top: -440px;
    height: 260px;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 5px;
    width: 85%;
    background-color: white;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    bottom: -1px;
  }

  &:before {
    top: -1px;
  }
`;
