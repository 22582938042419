import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

import { FormTitle } from '../../../form/index.styles';
import { ButtonWithIconWrapper as ButtonWrapper } from '../../../buttons/index.style';

export const Container = styled.div`
  width: 100%;

  ${mediaMinWidth.md} {
    padding: 15px;
  }
`;

export const FormContent = styled.div`
  padding: 20px;
`;

export const RowWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;

  & > button {
    max-width: 145px;
    align-self: center;
  }

  ${mediaMinWidth.sm} {
    flex-direction: row;
  }
`;

export const StyledText = styled.span`
  ${({ theme: { fontSize, fontFamily, fontColors, borderColors } }) => css`
    align-self: center;
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.sm};
    font-weight: 300;
    color: ${fontColors.secondary};
    font-style: italic;
    border-bottom: 1px solid ${borderColors.darkGrey};
    min-width: 200px;
    width: 100%;
    margin-bottom: 30px;

    ${mediaMinWidth.sm} {
      margin-bottom: 0;
      min-width: 250px;
    }

    ${mediaMinWidth.md} {
      margin-bottom: 0;
      min-width: 250px;
    }

    ${mediaMinWidth.xxl} {
      min-width: 350px;
    }
  `}
`;

export const DescriptionMessage = styled.div`
  ${({ theme: { fontSize, fontFamily, fontColors } }) => css`
    font-family: ${fontFamily.primary};
    font-style: italic;
    font-size: ${fontSize.xs};
    font-weight: 300;
    color: ${fontColors.secondary};

    ${mediaMinWidth.xl} {
      font-size: ${fontSize.md};
      margin-bottom: 30px;
    }
  `}
`;

export const ButtonWithIconWrapper = styled(ButtonWrapper)`
  max-width: 170px;
  width: 100%;
  margin: 0 0 0 40px;

  & button,
  & a {
    min-width: 100px;
  }

  & span {
    min-width: 250px;
  }

  ${mediaMinWidth.xxl} {
    max-width: 200px;
  }
`;

export const ContractName = styled(FormTitle)`
  margin: 15px 0 5px;
`;
