import { Action } from 'redux';
import { AppState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { LoginPayload } from '../../constants/auth/actionTypes';
import {
  setLoginError,
  authRequestFail,
  loginUserSuccess,
  userLogout,
  setAgreementStatus,
  getAgreementStatus,
  updateAgreementStatusRequest,
  updateAgreementStatusSuccess,
  getAuthenticatedUserRequest,
  getAuthenticatedUserSuccess,
  checkAccessToEngagementRequest,
  checkAccessToEngagementSuccess,
  checkAccessToEngagementError,
  changePasswordAfterFirstLoginRequest,
  changePasswordAfterFirstLoginSuccess,
} from './actions';
import { history } from '../../history';

import api from '../../services/api';
import { TICKET, USERNAME } from '../../constants/auth';
import { ChangePasswordPayload } from '../../constants/user/actionTypes';
import { getUserInfo } from '../user/thunks';
import { resetUserState } from '../user/actions';
import { userInfoTypes } from '../../constants/user/userTypes';
import { logoutByStatusCode, redirectAfterLogin } from '../../utils/auth';

export const getAuthenticatedUser = (): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getAuthenticatedUserRequest());

    await dispatch(getUserInfo(userInfoTypes.SHORT, 'auth'));

    dispatch(getAuthenticatedUserSuccess());
  } catch (e) {
    dispatch(authRequestFail(e));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const getUserAgreementStatus = (): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getAgreementStatus());

    const { data } = await api.people.getAgreementStatus();

    dispatch(setAgreementStatus(data));
  } catch (e) {
    dispatch(authRequestFail('Error occurred'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const updateAgreementStatus = (values: {
  agreementStatus: boolean;
}): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(updateAgreementStatusRequest());

    await api.people.updateAgreementStatus(values);

    dispatch(updateAgreementStatusSuccess(values));
  } catch (e) {
    dispatch(authRequestFail('Error occurred'));
  }
};

export const login = (auth: LoginPayload, location?: any): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    const {
      data: { ticket },
    } = await api.auth.getTicket(auth);

    dispatch(loginUserSuccess({ ticket }));

    window.localStorage.setItem(TICKET, ticket);
    window.localStorage.setItem(USERNAME, auth.email);

    dispatch(getUserAgreementStatus());
    dispatch(getAuthenticatedUser());

    const { from }: any = redirectAfterLogin('/login', location);

    history.replace(from);
  } catch (e) {
    dispatch(setLoginError('Wrong email or password'));
  }
};

export const logout = (): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    await api.auth.logout();
    window.localStorage.removeItem(TICKET);
    window.localStorage.removeItem(USERNAME);
    dispatch(userLogout());
    dispatch(resetUserState());
    history.push('/login');
  } catch (e) {
    // todo add error handler
    console.error('Something went wrong during logout');
  }
};

export const changePasswordAfterFirstLoginUserData = (
  values: ChangePasswordPayload,
  location?: any
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(changePasswordAfterFirstLoginRequest());

    await api.person.changePassword(values);
    const { from }: any = redirectAfterLogin('/set-up-account', location);

    dispatch(changePasswordAfterFirstLoginSuccess());
    dispatch(resetUserState());
    history.push(from);
  } catch (e) {
    const message = e.message.includes('401') ? 'Old password is wrong' : 'Cannot change password';
    dispatch(authRequestFail(message));
  }
};

export const checkAccessToEngagement = (id: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(checkAccessToEngagementRequest());

    //todo api call
    dispatch(checkAccessToEngagementSuccess());
  } catch (e) {
    dispatch(checkAccessToEngagementError('Error occurred'));
  }
};
