import React from 'react';
import { Column, ColumnTitle, ColumnValue } from './index.style';

interface Props {
  width: string;
  align?: 'center' | 'right';
  title?: string;
  value?: React.ReactNode;
}

const VerificationResultColumn = ({ width, align, title, value }: Props) => {
  return (
    <Column width={width} align={align}>
      {title && <ColumnTitle>{title}</ColumnTitle>}
      {value && <ColumnValue>{value}</ColumnValue>}
    </Column>
  );
};

export default VerificationResultColumn;
