import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { mediaMinWidth, mediaWidthAndHeight } from '../../../assets/styles/media';

export const Container = styled(Grid)`
  && {
    padding: 0;
  }

  height: 100vh;
  background-color: white;
`;

export const PageTitleGrid = styled(Grid)`
  && {
    display: none;
  }

  ${mediaMinWidth.md} {
    && {
      display: flex;
    }
  }
`;

export const PageTitleWrapper = styled(Grid)`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    display: flex;
    align-items: center;

    && {
      max-width: 100%;
    }

    h2 {
      z-index: 1;
      font-family: ${fontFamily.secondary};
      text-align: left;
      font-size: ${fontSize.xxl};
      color: ${fontColors.primary};
    }
  `}
`;

export const PageImageWrapper = styled(Grid)`
  && {
    max-width: 100%;
  }
`;

export const CompanyImage = styled.img`
  height: auto;
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 35vw;

  ${mediaMinWidth.md} {
    width: 38vw;
    max-width: 570px;
  }

  ${mediaWidthAndHeight('md', 'md')} {
    width: 50vw;
  }

  ${mediaWidthAndHeight('lg', 'md')} {
    width: 45vw;
  }

  ${mediaWidthAndHeight('xl', 'md')} {
    width: 60vw;
  }

  ${mediaMinWidth.xxl} {
    max-width: 765px;
    width: 60vw;
  }
`;

export const FormGrid = styled(Grid)`
  && {
    max-width: 100%;
    flex-basis: 100%;
  }

  ${mediaMinWidth.md} {
    && {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
`;

export const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 240px;

  ${mediaMinWidth.xl} {
    max-width: 310px;
  }
`;
