import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { updateAgreementStatus } from '../../../redux/auth/thunks';
import CheckboxField from '../../../components/form/CheckboxField';
import Checkbox from './SetUpAgreementCheckbox';
import { ButtonFirst } from '../../../components/buttons/index.style';
import { GrettingText, Subtitle, AgreeDescription, OuterLink, FormContent, FormRow } from './index.style';
import { AppState } from '../../../redux/reducers';

interface Props {
  nextStep: Function;
}

const initialValues = { agreementStatus: false };

const Agreement = ({ nextStep }: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector<AppState, boolean>(({ auth }) => auth.isLoading);

  const handleSubmit = async ({ agreementStatus }: FormikValues) => {
    await dispatch(updateAgreementStatus({ agreementStatus }));
    nextStep();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form id="userAgreement">
          <FormContent>
            <GrettingText>Hi there!</GrettingText>
            <Subtitle>Let’s get you quickly set up.</Subtitle>
            <AgreeDescription>
              By clicking “get started”, I agree to the{' '}
              <OuterLink href="https://www.ir35navigator.com/terms-of-service" target="_blank">
                terms & conditions
              </OuterLink>{' '}
              and processing of my personal data as described in the{' '}
              <OuterLink href="https://www.ir35navigator.com/privacy-policy" target="_blank">
                Privacy Policy
              </OuterLink>
              .
            </AgreeDescription>
            <FormRow>
              <CheckboxField name="agreementStatus" label="I agree" component={Checkbox} />
              <div>
                <ButtonFirst type="submit" disabled={!values.agreementStatus || isLoading}>
                  Get Started
                </ButtonFirst>
              </div>
            </FormRow>
          </FormContent>
        </Form>
      )}
    </Formik>
  );
};

export default Agreement;
