import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../redux/reducers';
import {
  EngagementDocument,
  EngagementParty,
  EngagementPayload,
  PartiesInfo,
} from '../constants/engagement/actionTypes';
import { getSdsId } from '../utils/supplyChainUtils';
import { useEffect } from 'react';
import { getEngagement } from '../redux/engagement/thunks';
import { values } from 'lodash/fp';
import { resetEngagementState } from '../redux/engagement/actions';

interface FullEngagement {
  engagement: EngagementPayload;
  sdsId?: string | null;
  isEngagementLoading: boolean;
  engagementDocumentsInfo: EngagementDocument[];
  partiesInfo?: {
    engager: EngagementParty;
    ir35LTD: EngagementParty;
    worker: EngagementParty;
    intermediaries: EngagementParty[];
  };
}

export const useEngagementData = (): FullEngagement => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);
  const partiesInfo = useSelector<AppState, PartiesInfo | null>(({ engagement }) => engagement.partiesInfo);
  const engagement = useSelector<AppState, EngagementPayload>(({ engagement }) => engagement.values);
  const sdsId = useSelector<AppState, string | null | undefined>(({ engagement }) =>
    getSdsId(engagement.sdsDocumentsInfo, engagement.values.sdsStatus)
  );
  const engagementDocumentsInfo = useSelector<AppState, EngagementDocument[]>(
    ({ engagement }) => engagement.engagementDocumentsInfo
  );

  useEffect(() => {
    dispatch(getEngagement(id));

    return () => {
      dispatch(resetEngagementState());
    };
  }, []);

  const fullEngagement: FullEngagement = {
    engagement,
    sdsId,
    isEngagementLoading,
    engagementDocumentsInfo,
  };

  if (partiesInfo) {
    const { engager, ir35LTD, worker, ...parties } = partiesInfo;
    const intermediaries = values(parties).filter((party) => party?.firstName);

    fullEngagement.partiesInfo = {
      engager,
      ir35LTD,
      worker,
      intermediaries,
    };
  }

  return fullEngagement;
};
