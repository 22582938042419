import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const InputWrapper = styled.div<{ wpq?: boolean; fullWidth: boolean; maxWidth?: number }>`
  position: relative;
  width: 100%;
  margin: 0 0 25px;
  max-width: none;

  ${mediaMinWidth.xs} {
    margin: 0;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 15px)')};
  }

  ${mediaMinWidth.md} {
    margin: 0;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 20px)')};
  }

  ${mediaMinWidth.xl} {
    max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
  }
`;
