import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const PageTitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 20px 0 50px;

  ${mediaMinWidth.xs} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const Title = styled.h2<{ mini?: boolean }>`
  ${({ mini, theme: { fontSize, fontFamily, keyColors } }) => css`
    color: ${keyColors.first};
    font: ${mini ? fontSize.xs : fontSize.md} ${fontFamily.primary};
    font-weight: bold;
    text-align: center;
  `}
`;

export const ButtonWrapper = styled.div`
  margin: 15px 0 0;

  ${mediaMinWidth.xs} {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;
