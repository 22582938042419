import React from 'react';

import { PageTitleContainer, Title, TitleContainer, ButtonWrapper } from './index.style';
import BackButton from '../../../components/BackButton';

interface Props {
  onBackButtonClick?: () => void;
  children: React.ReactNode;
}

const PageTitle = ({ children, onBackButtonClick }: Props) => (
  <PageTitleContainer>
    <TitleContainer>{children}</TitleContainer>
    {onBackButtonClick && (
      <ButtonWrapper>
        <BackButton onClick={onBackButtonClick} />
      </ButtonWrapper>
    )}
  </PageTitleContainer>
);

PageTitle.Title = Title;

export default PageTitle;
