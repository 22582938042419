import {
  VERIFY_SDS_REQUEST,
  VERIFY_SDS_SUCCESS,
  VERIFY_SDS_ERROR,
  RESET_VERIFY_SDS_STATE,
  SdsVerificationPayload,
} from '../../constants/sds/actionTypes';

export const verifySDSRequest = () => ({
  type: VERIFY_SDS_REQUEST,
});

export const verifySDSSuccess = (payload: SdsVerificationPayload) => ({
  type: VERIFY_SDS_SUCCESS,
  payload,
});

export const resetVerifySDSState = () => ({
  type: RESET_VERIFY_SDS_STATE,
});

export const verifySDSError = (errorMessage: string) => ({
  type: VERIFY_SDS_ERROR,
  payload: {
    errorMessage,
  },
});
