import { Action } from 'redux';
import { FetchDataFnArguments } from 'react-table';
import { ThunkAction } from 'redux-thunk';

import { AppState } from '../reducers';
import { getEngagementsRequest, getEngagementsSuccess, getEngagementsError } from './actions';
import api from '../../services/api';
import { buildGetEngagementsRequestBody, getActiveEngagements } from '../../utils/engagements';
import { logoutByStatusCode } from '../../utils/auth';

export const getEngagements = (
  requestOptions: FetchDataFnArguments
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(getEngagementsRequest());

    const { data } = await api.engagements.getEngagements(buildGetEngagementsRequestBody(requestOptions));

    const tableData = {
      results: getActiveEngagements(data.results),
      pageCount: data.totalCount ? Math.ceil(data.totalCount / requestOptions.pageSize) : 1,
    };

    dispatch(getEngagementsSuccess(tableData));
  } catch (e) {
    dispatch(getEngagementsError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};
