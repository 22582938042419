export const IR_35_NAVIGATOR = 'ir35LTD';

export const supplyUserTypes: Array<{ type: string; label: string }> = [
  { type: 'engager', label: 'End client' },
  { type: 'intermediary1', label: 'Intermediary 1' },
  { type: 'intermediary2', label: 'Intermediary 2' },
  { type: 'intermediary3', label: 'Intermediary 3' },
  { type: 'intermediary4', label: 'Intermediary 4' },
  { type: 'ir35LTD', label: 'IR35 Navigator Limited' },
  { type: 'worker', label: 'Worker' },
];

export const supplyChainColumnWidths = {
  partyColumn: '20%',
  requestInfoColumn: '10%',
  wpqColumn: '15%',
};
