import {
  SET_WPQ_BASE_LINK,
  REQUEST_WPQ_REQUEST,
  REQUEST_WPQ_SUCCESS,
  REQUEST_WPQ_ERROR,
  GET_LINK_TO_WPQ_FORM_REQUEST,
  GET_LINK_TO_WPQ_FORM_SUCCESS,
  GET_LINK_TO_WPQ_FORM_ERROR,
  UPLOAD_WPQ_DOCUMENT_REQUEST,
  UPLOAD_WPQ_DOCUMENT_SUCCESS,
  UPLOAD_WPQ_DOCUMENT_ERROR,
  RESET_WPQ_SUCCESS_RESULT,
} from '../../constants/wpq/actionTypes';

export const setWPQBaseLink = (payload: { baseLink: string }) => ({
  type: SET_WPQ_BASE_LINK,
  payload,
});

export const requestWPQRequest = () => ({
  type: REQUEST_WPQ_REQUEST,
});

export const requestWPQSuccess = () => ({
  type: REQUEST_WPQ_SUCCESS,
});

export const requestWPQError = (errorMessage: string) => ({
  type: REQUEST_WPQ_ERROR,
  payload: { errorMessage },
});

export const getLinkToWPQFormRequest = () => ({
  type: GET_LINK_TO_WPQ_FORM_REQUEST,
});

export const getLinkToWPQFormSuccess = (payload: { wpqLink: string }) => ({
  type: GET_LINK_TO_WPQ_FORM_SUCCESS,
  payload,
});

export const getLinkToWPQFormError = (errorMessage: string) => ({
  type: GET_LINK_TO_WPQ_FORM_ERROR,
  payload: { errorMessage },
});

export const uploadWPQDocumentRequest = () => ({
  type: UPLOAD_WPQ_DOCUMENT_REQUEST,
});

export const uploadWPQDocumentSuccess = () => ({
  type: UPLOAD_WPQ_DOCUMENT_SUCCESS,
});

export const uploadWPQDocumentError = (errorMessage: string) => ({
  type: UPLOAD_WPQ_DOCUMENT_ERROR,
  payload: { errorMessage },
});

export const resetWPQSuccessResult = () => ({
  type: RESET_WPQ_SUCCESS_RESULT,
});
