import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Main from '../../components/Main';
import Loader from '../../components/Loader';
import EditEngagementDialog from '../../components/modals/Dialog/EngagementDialog/EditEngagementDialog';
import DeleteEngagementDialog from '../../components/modals/Dialog/EngagementDialog/DeleteEngagementDialog';

import { useModalToggle } from '../../hooks/useModalToggle';
import { AppState } from '../../redux/reducers';
import { useEngagementData } from '../../hooks/useEngagementData';
import { checkIsWttAdmin } from '../../utils/auth';

import { ContentContainer, LeftColumn, RightColumn } from './index.style';
import EngagementSummaryHeader from './EngagementSummaryHeader';

import MainSection from './EngagementSummarySection/EngagementSummarySectionMain';
import DocumentsSection from './EngagementSummarySection/EngagementSummarySectionDocuments';
import DecisionStatusSection from './EngagementSummarySection/EngagementSummarySectionDecisionStatus';
import MembersSection from './EngagementSummarySection/EngagementSummarySectionParties';

const EngagementSummary = () => {
  const { partiesInfo, engagement, sdsId, isEngagementLoading, engagementDocumentsInfo } = useEngagementData();
  const userType = useSelector<AppState, string>(({ user }) => user.values.userType);
  const userName = useSelector<AppState, string>(({ user }) => user.values.userName);
  const isWTTAdmin = checkIsWttAdmin(userType);
  const [isEditModalOpen, toggleEditModal] = useModalToggle(false);
  const [isDeleteOpen, toggleDeleteModal] = useModalToggle(false);

  const openCloseDeleteModal = useCallback(() => {
    toggleDeleteModal();
    toggleEditModal();
  }, [toggleDeleteModal, toggleEditModal]);

  return (
    <>
      <Loader isLoading={isEngagementLoading}>
        <Main>
          <EngagementSummaryHeader reference={engagement.reference} />
          <Main.Content>
            <ContentContainer>
              <LeftColumn>
                <MainSection engagement={engagement} isWTTAdmin={isWTTAdmin} toggleEditModal={toggleEditModal} />
                <DocumentsSection
                  isWTTAdmin={isWTTAdmin}
                  userName={userName}
                  engagementDocumentsInfo={engagementDocumentsInfo}
                />
              </LeftColumn>
              <RightColumn>
                <DecisionStatusSection
                  ir35Status={engagement.ir35Status}
                  sdsStatus={engagement.sdsStatus}
                  isWTTAdmin={isWTTAdmin}
                  sdsId={sdsId}
                />
                <MembersSection
                  engagementTitle={engagement.shortDescription}
                  partiesInfo={partiesInfo}
                  isWTTAdmin={isWTTAdmin}
                />
              </RightColumn>
            </ContentContainer>
          </Main.Content>
        </Main>
      </Loader>
      <EditEngagementDialog
        skipResetEngagement
        isOpen={isEditModalOpen}
        onClose={toggleEditModal}
        openDeleteModal={openCloseDeleteModal}
      />
      <DeleteEngagementDialog isOpen={isDeleteOpen} onClose={openCloseDeleteModal} />
    </>
  );
};

export default EngagementSummary;
