import styled from 'styled-components';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 10px 0;

  ${mediaMinWidth.xs} {
    flex-direction: row;
    justify-content: space-between;
  }

  button {
    margin: 0 10px 0 0;

    ${mediaMinWidth.xxl} {
      margin-right: 30px;
    }
  }

  h2 {
    margin-bottom: 15px;
    padding: 0;

    ${mediaMinWidth.xs} {
      margin-bottom: 0;
    }
  }
`;
