export const ROUTES = {
  LOGIN: '/login',
  SET_UP_ACCOUNT: '/set-up-account',
  DOCUMENTS: '/documents',
  TEAM: '/team',
  ACCOUNT: '/account',
  DASHBOARD: '/',
  SUPPLY_CHAIN: '/engagement/:id/supply-chain',
  ENGAGEMENT_SUMMARY: '/engagement/:id',
  SDS_UPLOAD: '/engagement/:id/sds-upload',
  PREVIEW_SDS: '/engagement/:idEngagement/sds-preview/:id',
  ACCEPT_SDS: '/engagement/:idEngagement/accept/:id',
  PREVIEW: '/preview/:id',
  WPQ: '/wpq/:id',
  SDS_VERIFICATION_ON_PLATFORM: '/sds-verification',
  SDS_VERIFICATION: '/sds-verify',
};

export const ROUTE_TYPE_PUBLIC = 'public';
export const ROUTE_TYPE_PRIVATE = 'private';
