import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  & > div {
    text-align: center;
  }

  ${mediaMinWidth.xs} {
    max-width: 500px;
  }

  ${mediaMinWidth.md} {
    max-width: 700px;
  }

  ${mediaMinWidth.xl} {
    max-width: 850px;
  }
`;

export const TitleText = styled.h2`
  ${({ theme: { fontSize, fontFamily, keyColors } }) => css`
    margin: 0 0 15px;
    color: ${keyColors.first};
    font: ${fontSize.md} ${fontFamily.primary};
    font-weight: bold;
  `}
`;

export const Reference = styled.p`
  ${({ theme: { fontSize, fontFamily, keyColors } }) => css`
    color: ${keyColors.lightGrey};
    font: ${fontSize.xs} ${fontFamily.primary};
    line-height: 1.2;
  `}
`;
