import React from 'react';
import styled, { css } from 'styled-components';

import Dialog from '../../components/modals/Dialog';
import { ButtonFirst } from '../../components/buttons/index.style';
import { mainTheme } from '../../assets/styles/variables/themes';
import { mediaMinWidth } from '../../assets/styles/media';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ForgotPasswordDialog = (props: Props) => (
  <Dialog isOpen={props.isOpen} maxWidth={mainTheme.dialogMaxSize.sm}>
    <ForgotPasswordDialogContent>
      <span>To reset your password, please contact our helpdesk:</span>
      <a href="tel:+44 (0) 20 3468 0008">+44 (0) 20 3468 0008</a>
      <ButtonFirst maxWidth={mainTheme.buttonMaxSize.xs} onClick={props.onClose}>
        Ok
      </ButtonFirst>
    </ForgotPasswordDialogContent>
  </Dialog>
);

export const ForgotPasswordDialogContent = styled.div`
  ${({ theme: { fontColors, fontSize, fontFamily } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    & > span,
    a {
      font-family: ${fontFamily.primary};
      font-size: ${fontSize.sm};
      font-weight: bold;
      text-align: center;
      color: ${fontColors.primary};
    }

    & button {
      margin: 50px 0 0;
    }

    ${mediaMinWidth.xxl} {
      padding: 20px;
    }
  `}
`;

export default ForgotPasswordDialog;
