import styled, { css } from 'styled-components';

export const EngagementTitle = styled.div`
  ${({ theme: { keyColors, fontSize, borderWidth } }) => css`
    margin: 0 0 0 40px;
    padding: 10px 0;
    color: ${keyColors.first};
    border-top: ${borderWidth.xs} solid ${keyColors.lightGrey};
    font-size: ${fontSize.sm};
  `}
`;
