import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Main from '../../components/Main';
import EngagementTable from './EngagementTable';
import { DashboardContent } from './index.style';
import ResultDialog from '../../components/modals/Dialog/ResultDialog';

import { useModalToggle } from '../../hooks/useModalToggle';

const Dashboard = () => {
  const { hash } = useLocation();
  const history = useHistory();
  const [isWPQSuccessSubmissionModalOpen, toggleWPQSuccessSubmissionModal] = useModalToggle(hash === '#wpq-success');

  const closeWPQSuccessSubmissionModal = () => {
    history.replace('/');
    toggleWPQSuccessSubmissionModal();
  };

  return (
    <>
      <Main>
        <Main.Content>
          <DashboardContent>
            <EngagementTable />
          </DashboardContent>
        </Main.Content>
      </Main>
      <ResultDialog
        isOpen={isWPQSuccessSubmissionModalOpen}
        onClose={closeWPQSuccessSubmissionModal}
        successMessage="Success - your submission has been received"
      />
    </>
  );
};

export default Dashboard;
