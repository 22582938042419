import styled from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const Paragraph = styled.p`
  max-width: 450px;
  padding: 10px 0 10px;
  font-style: italic;
  font-weight: 300;

  ${mediaMinWidth.sm} {
    padding: 30px 0;
  }
`;

export const BodyWrapper = styled.div`
  padding: 20px 0 40px;

  ${mediaMinWidth.xxl} {
    padding-bottom: 80px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  ${mediaMinWidth.xs} {
    flex-wrap: nowrap;

    & button {
      margin: 0 0 0 15px;
    }
  }
`;
