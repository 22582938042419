import axios from 'axios';
import { EngagementPayload, FullEngagementPayload } from '../../constants/engagement/actionTypes';

const getEngagement = (id: string): Promise<{ data: FullEngagementPayload }> => axios.get(`/engagement/${id}`);

const createEngagement = (engagement: EngagementPayload): Promise<{ data: { id: string } }> =>
  axios.post('/engagement', engagement);

const updateEngagement = (engagement: EngagementPayload): Promise<{ data: { id: string } }> =>
  axios.put(`/engagement/${engagement.id}`, engagement);

const deleteEngagement = (id: string): Promise<any> => axios.delete(`/engagement/${id}`);

const uploadSDSFile = (data: FormData): Promise<any> =>
  axios.post(`/documents/upload/sds-document`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const addUserToEngagement = (engagementId: string, userName: string, userRole: string) =>
  axios.post(`/engagement/user`, {
    engagementId,
    userName,
    userRole,
  });

const deleteUserFromEngagement = (engagementId: string, userRole: string) =>
  axios.delete(`/engagement/user`, {
    data: {
      engagementId,
      userRole,
    },
  });

const finaliseEngagement = (engagementId: string) =>
  axios.post('/finalise-engagement', {
    engagementId,
  });

const verifySDSFile = (data: FormData) =>
  axios.post('/documents/upload/verify-document', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const acceptSDSFile = (sdsId: string) =>
  axios.post('/document/accept-sds', {
    sdsId,
  });

export default {
  getEngagement,
  createEngagement,
  updateEngagement,
  deleteEngagement,
  uploadSDSFile,
  addUserToEngagement,
  deleteUserFromEngagement,
  finaliseEngagement,
  verifySDSFile,
  acceptSDSFile,
};
