import React from 'react';

import { StyledFooter, NavigationContainer, FooterSection } from './index.style';
import NavigationList from './FooterNavigation';
import Contacts from './FooterContacts';

import { resourcesNavigation, companyNavigation } from '../../constants/navigation';

const Footer = () => (
  <StyledFooter>
    <FooterSection>
      <Contacts />
    </FooterSection>
    <FooterSection>
      <NavigationContainer>
        <NavigationList title="Company" items={companyNavigation} />
        <NavigationList title="Resources" items={resourcesNavigation} />
      </NavigationContainer>
    </FooterSection>
  </StyledFooter>
);

export default Footer;
