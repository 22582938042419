import React from 'react';
import { FieldInputProps } from 'formik';
import DatePicker from '../../DatePicker';
import { ErrorMessage } from '../../inputs/InputErrorMessage';
import { DatePickerWrapper } from './index.style';

interface Props {
  field: FieldInputProps<any>;
  setFieldValue: Function;
  placeholder?: string;
  maxWidth?: number;
  showError?: boolean;
}

const DatePickerInput = ({ maxWidth, field, setFieldValue, showError, placeholder = '' }: Props) => (
  <DatePickerWrapper showError={showError} maxWidth={maxWidth}>
    <DatePicker field={field} setFieldValue={setFieldValue} placeholder={placeholder} />
    <ErrorMessage name={field.name} component="span" />
  </DatePickerWrapper>
);

export default DatePickerInput;
