import React from 'react';

import Dialog from '../index';
import ButtonWithSpinner from '../../../buttons/ButtonWithSpinner';
import { ButtonFirst, ButtonSecond } from '../../../buttons/index.style';
import { ContentWrapper } from '../index.styles';
import { ButtonWrapper } from './index.styles';

import { mainTheme } from '../../../../assets/styles/variables/themes';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading: boolean;
  message: string;
}

const ConfirmationDialog = ({ isOpen, onCancel, onConfirm, isLoading, message }: Props) => (
  <Dialog maxWidth={mainTheme.dialogMaxSize.md} isOpen={isOpen}>
    <ContentWrapper>
      <p>{message}</p>
      <ButtonWrapper>
        <ButtonSecond maxWidth={mainTheme.buttonMaxSize.xs} onClick={onCancel}>
          Cancel
        </ButtonSecond>
        <ButtonWithSpinner
          Component={ButtonFirst}
          label="Accept"
          maxWidth={mainTheme.buttonMaxSize.xs}
          isLoading={isLoading}
          onConfirm={onConfirm}
        />
      </ButtonWrapper>
    </ContentWrapper>
  </Dialog>
);

export default ConfirmationDialog;
