import styled from 'styled-components';

import { mediaMinWidth } from '../../../../../assets/styles/media';

export const ControlContainer = styled.div`
  display: flex;
  max-width: 150px;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;

  ${mediaMinWidth.sm} {
    max-width: 150px;
  }

  ${mediaMinWidth.xl} {
    max-width: 180px;
  }

  ${mediaMinWidth.xxl} {
    max-width: 200px;
  }

  div {
    margin: 0 10px;
  }

  & a {
    min-height: 50px;

    ${mediaMinWidth.xxl} {
      min-height: 64px;
    }
  }

  & > a,
  & > span,
  & > h3 {
    width: 90px;
    text-align: center;

    ${mediaMinWidth.xs} {
      width: 100px;
    }

    ${mediaMinWidth.sm} {
      width: 100px;
      min-width: 100px;
    }

    ${mediaMinWidth.xl} {
      width: 120px;
      min-width: 120px;
    }

    ${mediaMinWidth.xxl} {
      width: 150px;
      min-width: 150px;
    }
  }

  img {
    cursor: pointer;
  }
`;
