import styled, { css } from 'styled-components';

import { mediaMinWidth } from '../../assets/styles/media';

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const LeftColumn = styled.div`
  width: 100%;

  ${mediaMinWidth.md} {
    width: 50%;
  }
`;

export const RightColumn = styled.div`
  width: 100%;

  ${mediaMinWidth.md} {
    width: 35%;
  }
`;

export const List = styled.ul`
  ${({ theme: { fontSize, transition, keyColors } }) => css`
    font-size: ${fontSize.sm};

    & > li {
      margin-bottom: 10px;
      color: ${keyColors.lightGrey};
    }

    a {
      padding: 0;
      color: inherit;
      font-weight: bold;
      word-wrap: break-word;
      transition: ${transition};

      &:hover {
        opacity: 0.6;
      }
    }
  `}
`;
