import React, { ReactElement } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from '../../redux/reducers';
import { MainRouter } from '../../declarations/routing';
import Loader from '../Loader';
import { useCheckUserAgreement } from '../../hooks/useCheckUserAgreement';
import AccessDeniedError from '../AccessDeniedError';
import { checkPermissions, getStoredTicket } from '../../utils/auth';

const PrivateRoute = ({ component: Component, auth, requireTermsAgreement, ...rest }: MainRouter) => {
  const location = useLocation();
  const isAgreementLoading = useSelector<AppState, boolean>(({ auth }) => auth.isAgreementLoading);
  const isUserLoading = useSelector<AppState, boolean>(({ auth }) => auth.isUserLoading);
  const userType = useSelector<AppState, string>(({ user }) => user.values.userType);
  const isLoading = isAgreementLoading || isUserLoading;
  const redirectLocation = useCheckUserAgreement({
    requireTermsAgreement,
    isLoading,
  });

  const render = (props: any): ReactElement => {
    if (!getStoredTicket()) {
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }

    if (!isLoading && auth && auth.length) {
      const isAccessAllowed = checkPermissions({
        allowedUserTypes: auth,
        userType,
      });
      if (!isAccessAllowed) {
        return <AccessDeniedError />;
      }
    }

    return redirectLocation ? (
      <Redirect to={redirectLocation} />
    ) : (
      <Loader isLoading={isLoading}>
        <Component {...props} />
      </Loader>
    );
  };

  return <Route render={render} {...rest} />;
};

export default PrivateRoute;
