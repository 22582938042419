import { FetchDataFnArguments } from 'react-table';
import { Engagement, GetEngagementsBodyRequest } from '../constants/engagements/actionTypes';
import { NO_STATUS } from '../constants/engagement/constants';

export const buildGetEngagementsRequestBody = ({
  searchQuery,
  filters,
  sortBy,
  pageSize,
  pageIndex,
}: FetchDataFnArguments): GetEngagementsBodyRequest => {
  const filter = filters.reduce(
    (previousValue, { id, value }) => ({
      ...previousValue,
      [id]: value === NO_STATUS ? '' : value,
    }),
    {}
  );

  const sortByField = sortBy[0];
  const sort = {
    field: sortByField ? `ir35:${sortByField.id}` : 'cm:modified',
    asc: sortByField ? !sortByField.desc : false,
  };

  return {
    query: searchQuery,
    filter,
    sort,
    page: { maxItems: pageSize, skipCount: pageSize * pageIndex },
  };
};

export const getActiveEngagements = (engagements: Engagement[]): Engagement[] =>
  engagements.filter(({ isActive }) => isActive);

export const upperCaseFirstLetter = (value: string): string => `${value[0].toUpperCase()}${value.slice(1)}`;
