import React from 'react';

import Icon from '../../../../components/icons/Icon';
import { UserTypeLabelContainer, HiddenImage, StyledUserTypeLabel } from './index.style';

import trashBinIcon from '../../../../assets/images/trash-bin.jpg';
import add from '../../../../assets/images/group-4.svg';

import { userTypes } from '../../../../constants/user/userTypes';

interface Props {
  addedUser?: boolean;
  handleDeleteClick: () => void;
  handleUserAddClick: () => void;
  userType: { type: string; label: string };
}

const UserTypeLabel = ({ addedUser, handleDeleteClick, handleUserAddClick, userType }: Props) => (
  <UserTypeLabelContainer>
    <HiddenImage isVisible={addedUser}>
      <Icon iconSrc={trashBinIcon} size="md" onClick={handleDeleteClick} />
    </HiddenImage>
    <HiddenImage isVisible={!addedUser}>
      <Icon iconSrc={add} size="md" onClick={handleUserAddClick} />
    </HiddenImage>
    <StyledUserTypeLabel engager={userType?.type === userTypes.ENGAGER} worker={userType?.type === userTypes.WORKER}>
      {userType?.label}
    </StyledUserTypeLabel>
  </UserTypeLabelContainer>
);

export default UserTypeLabel;
