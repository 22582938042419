import styled from 'styled-components';
import { mediaMinWidth, mediaMinHeight } from '../../../../assets/styles/media';

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px 10px 20px;

  ${mediaMinWidth.xs} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${mediaMinHeight.lg} {
    padding: 20px 40px 60px 20px;
  }

  h2 {
    padding: 0;
  }

  p {
    width: 100%;
    padding: 0;
    font-style: italic;
    text-align: center;

    ${mediaMinWidth.xs} {
      width: auto;
    }
  }
`;

export const FormBlock = styled.div`
  width: 100%;
  padding: 20px 0 10px;

  & > div {
    padding: 0 0 15px;

    ${mediaMinHeight.lg} {
      padding: 0 0 40px;
    }
  }

  h2 {
    font-size: ${({ theme: { fontSize } }) => fontSize.md};
    margin: 0 0 15px;
  }

  span {
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
    font-weight: 300;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 20px;

  & button {
    max-width: 148px;
  }
`;

export const CustomLabel = styled.span`
  max-width: 40%;

  ${mediaMinWidth.xs} {
    max-width: 56%;
  }

  ${mediaMinWidth.sm} {
    padding-left: 90px;
  }

  ${mediaMinWidth.xl} {
    padding-left: 120px;
  }
`;

export const PartyName = styled.h3`
  font-weight: bold;
`;
