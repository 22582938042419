import styled from 'styled-components';

export const BaseContainer = styled.div<{ preview?: boolean }>`
  display: flex;
  width: 100%;
  flex: 1;
  height: ${({ preview }) => (preview ? '100%' : 'auto')};
`;

export const ColumnContainer = styled(BaseContainer)`
  flex-direction: column;
`;
