import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Section from '../index';
import { EngagementDocument } from '../../../../constants/engagement/actionTypes';
import { ButtonSecond } from '../../../../components/buttons/index.style';
import UploadContractsDialog from '../../../../components/modals/Dialog/DialogWithDescription/UploadContractsDialog';
import Icon from '../../../../components/icons/Icon';
import { List } from '../../index.style';
import { ButtonWrapper } from './index.style';

import { useModalToggle } from '../../../../hooks/useModalToggle';
import { getContractsList } from '../../../../redux/documents/thunks';
import { AppState } from '../../../../redux/reducers';
import { UploadedContract } from '../../../../constants/documents/actionTypes';

import attachIcon from '../../../../assets/images/bitmap@3x.png';
import documentsIcon from '../../../../assets/images/documents.png';

interface Props {
  isWTTAdmin: boolean;
  userName: string;
  engagementDocumentsInfo: EngagementDocument[];
}

const DocumentsSection = ({ isWTTAdmin, userName, engagementDocumentsInfo }: Props) => {
  const { id } = useParams();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const [isContractsOpen, toggleContractsModal] = useModalToggle(hash === '#contracts');
  const uploadedContracts = useSelector<AppState, UploadedContract[]>(({ documents }) => documents.contracts.uploaded);

  useEffect(() => {
    dispatch(getContractsList(id, isWTTAdmin ? undefined : userName));
  }, [id, isWTTAdmin, userName]);

  const engagementDocs = isWTTAdmin ? [...engagementDocumentsInfo, ...uploadedContracts] : uploadedContracts;

  return (
    <>
      <Section title="Engagement Documents" icon={documentsIcon}>
        {!isWTTAdmin && (
          <ButtonWrapper>
            <ButtonSecond onClick={toggleContractsModal}>attach</ButtonSecond>
            <Icon iconSrc={attachIcon} size="lg" />
          </ButtonWrapper>
        )}
        <List>
          {engagementDocs.map(({ name, id }) => (
            <li key={id}>
              {name} (<Link to={`/preview/${id}`}>view</Link>)
            </li>
          ))}
        </List>
      </Section>
      <UploadContractsDialog isOpen={isContractsOpen} onClose={toggleContractsModal} />
    </>
  );
};

export default DocumentsSection;
