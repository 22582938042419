import React from 'react';
import { Typography } from '@material-ui/core';
import {
  CompanyImage,
  Container,
  PageImageWrapper,
  PageTitleWrapper,
  FormWrapper,
  FormContainer,
  PageTitleGrid,
  FormGrid,
} from './index.style';
import image from '../../../assets/images/screenshot-2020-08-16-at-00-25-38@3x.png';

interface Props {
  children: React.ReactChild;
}

const LoginLayout = (props: Props) => (
  <Container container>
    <PageTitleGrid item container direction="column" alignItems="stretch" xs={false} sm={4} md={6}>
      <PageTitleWrapper item xs={false} sm={5} md={3} lg={3}>
        <Typography variant="h4" component="h2">
          Your Supply Chain. Connected.
        </Typography>
      </PageTitleWrapper>
      <PageImageWrapper item xs={false} sm={7} md={7} lg={9}>
        <CompanyImage src={image} alt="image" />
      </PageImageWrapper>
    </PageTitleGrid>
    <FormGrid item xs={12} sm={8} md={6}>
      <FormContainer>
        <FormWrapper>{props.children}</FormWrapper>
      </FormContainer>
    </FormGrid>
  </Container>
);

export default LoginLayout;
