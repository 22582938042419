import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import logo from '../../assets/images/logo_circle.png';
import { ROUTES } from '../../constants/routing';

import Agreement from './SetUpAgreement';
import BaseInfoForm from './SetUpAccountBaseInfo';
import ChangePasswordForm from './SetUpAccountChangePassword';
import SetUpAccountLayout from '../../components/layouts/SetUpAccountLayout';
import { Stepper, StyledDialog, StyledDialogContent, LogoHolder, FormTooltip } from './index.style';

interface Location {
  state?: { activeStep: number };
}

interface FormSteps {
  formTooltip: string;
  component: React.FC<any>;
}

const formSteps: FormSteps[] = [
  {
    formTooltip: 'Please agree to terms',
    component: Agreement,
  },
  {
    formTooltip: 'Please confirm',
    component: BaseInfoForm,
  },
  {
    formTooltip: 'Please change password',
    component: ChangePasswordForm,
  },
];

const isLastStep = (step: number): boolean => step + 1 === formSteps.length;

const SetUpAccount = () => {
  const history = useHistory();
  const { state }: Location = useLocation();
  const activeStep: number = state ? state.activeStep : 0;
  const { formTooltip, component: Form } = formSteps[activeStep];

  const nextStep = () => {
    return history.replace(ROUTES.SET_UP_ACCOUNT, { activeStep: activeStep + 1 });
  };

  return (
    <SetUpAccountLayout>
      <StyledDialog open aria-label="Set up user account form">
        <StyledDialogContent>
          <LogoHolder>
            <img src={logo} alt="IR-35" />
          </LogoHolder>
          {isLastStep(activeStep) ? <Form /> : <Form nextStep={nextStep} />}
          <Stepper>
            {activeStep + 1} / {formSteps.length}
          </Stepper>
        </StyledDialogContent>
        <FormTooltip>*{formTooltip}*</FormTooltip>
      </StyledDialog>
    </SetUpAccountLayout>
  );
};

export default SetUpAccount;
