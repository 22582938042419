import React, { ChangeEvent, useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import SuggestionList from './SuggestionList';
import SuggestionListItem from './SuggestionListItem';
import { getSuggestionUserList } from '../../../redux/user/thunks';
import { userTypes } from '../../../constants/user/userTypes';
import { AppState } from '../../../redux/reducers';
import { SupplyInput } from '../index.style';
import { StyledAutocomplete } from './index.styles';

export interface UserSuggestionSelectedValue {
  company?: string;
  fullName: string;
  userName: string;
}

interface Option {
  title: string;
  value: string;
  userName: string;
}

interface Props {
  options: Option[];
  noOptionsText?: string;
  isLoading?: boolean;
  userType: string;
  userRole: string;
  value?: string | null;
  onSelect?: (value: UserSuggestionSelectedValue) => void;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: Option) => (option?.title ? `${option.title}_${option.value}` : option.value),
});

const defaultValue = { title: '', value: '', userName: '' };

const InputSearch = ({ noOptionsText, options, onSelect, userType, userRole, value }: Props) => {
  const [open, setOpen] = useState(false);
  const [typedValue, setTypedValue] = useState('');

  const isLoading = useSelector<AppState, boolean | undefined>(({ user }) => get(userType, user.suggestionLoading));

  const dispatch = useDispatch();

  const handleChangeSearchQuery = (value: string) => {
    if (value) {
      dispatch(getSuggestionUserList(value, userType, userRole));
    }
  };

  useEffect(() => {
    setTypedValue(value || '');
  }, [value]);

  const handleSelect = (event: ChangeEvent<{}>, value: Option | null) => {
    if (value?.userName && value?.value && onSelect) {
      onSelect({ company: value?.title, fullName: value.value, userName: value.userName });
    }
  };

  return (
    <StyledAutocomplete>
      <Autocomplete
        options={options}
        open={open}
        disabled={Boolean(value)}
        filterOptions={filterOptions}
        loading={isLoading}
        noOptionsText={noOptionsText}
        inputValue={typedValue}
        onOpen={() => setOpen(true)}
        onClose={() => setTimeout(() => setOpen(false), 100)}
        getOptionLabel={(option) => option.title}
        getOptionSelected={(option, value) => option.userName === value.userName}
        onChange={handleSelect}
        defaultValue={defaultValue}
        onInputChange={(e, value) => {
          handleChangeSearchQuery(value);
          setTypedValue(value);
        }}
        PaperComponent={(props) => <SuggestionList>{props.children}</SuggestionList>}
        renderOption={(props) => <SuggestionListItem title={props.title} value={props.value} />}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <SupplyInput ref={params.InputProps.ref} {...params.inputProps} worker={userType === userTypes.WORKER} />
          </div>
        )}
      />
    </StyledAutocomplete>
  );
};

export default InputSearch;
