import { previewTypes, GET_FILE_REQUEST, GET_FILE_SUCCESS, GET_FILE_ERROR } from '../../constants/preview/actionTypes';

export interface PreviewStore {
  fileURL: string;
  fileName: string;
  fileType: string;
  isLoading: boolean;
}

const initialState: PreviewStore = {
  fileURL: '',
  fileName: '',
  fileType: '',
  isLoading: false,
};

export default (state: PreviewStore = initialState, action: previewTypes): PreviewStore => {
  switch (action.type) {
    case GET_FILE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_FILE_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case GET_FILE_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
