import React from 'react';
import CreateEngagementDialog from '../../../components/modals/Dialog/EngagementDialog/CreateEngagementDialog';
import InviteUserDialog from '../../../components/modals/Dialog/InviteUserDialog';
import { mainTheme } from '../../../assets/styles/variables/themes';
import { ButtonFirst, ButtonSecond } from '../../../components/buttons/index.style';
import { ButtonsContainer } from './index.styles';
import { useModalToggle } from '../../../hooks/useModalToggle';

const DashboardButtonsPanel = () => {
  const [showEngagement, setShowEngagement] = useModalToggle(false);
  const [showInviteUser, setShowInviteUser] = useModalToggle(false);

  const { buttonMaxSize } = mainTheme;

  return (
    <>
      <ButtonsContainer>
        <ButtonSecond maxWidth={buttonMaxSize.xs} onClick={setShowInviteUser}>
          ADD USER
        </ButtonSecond>
        <ButtonFirst maxWidth={buttonMaxSize.xs} onClick={setShowEngagement}>
          ENGAGE
        </ButtonFirst>
      </ButtonsContainer>
      <CreateEngagementDialog isOpen={showEngagement} onClose={setShowEngagement} />
      <InviteUserDialog isOpen={showInviteUser} onClose={setShowInviteUser} />
    </>
  );
};

export default DashboardButtonsPanel;
