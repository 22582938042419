import styled from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const TableWrapper = styled.div`
  position: relative;
  max-width: 100%;
  margin: 0 0 30px;
  overflow-x: auto;

  ${mediaMinWidth.xl} {
    margin: 0 0 85px;
  }

  table {
    min-height: 150px;
  }
`;

export const TableStyles = styled.div`
  position: relative;
  display: block;
  max-width: 100%;
  padding-top: 10px;
  margin: auto 0;
  font: ${({ theme: { fontSize } }) => fontSize.sm} Lato;

  ${mediaMinWidth.lg} {
    margin: 60px 0 0;
  }

  table {
    width: 100%;
    height: 100%;
    margin: -1px 0 1px;
    border-collapse: collapse;
    border-spacing: 0;
    min-height: 300px;
  }
`;

export const TableHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & > div {
    margin-bottom: 15px;
  }

  ${mediaMinWidth.sm} {
    display: block;
  }
`;

export const TableIconWrapper = styled.div`
  display: none;

  ${mediaMinWidth.lg} {
    position: absolute;
    left: -60px;
    display: block;
  }
`;
