import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import UploadButton from '../../../components/buttons/UploadButton';
import Loader from '../../../components/Loader';
import { ButtonFirst, ButtonSecond } from '../../../components/buttons/index.style';
import BackButton from '../../../components/BackButton';
import SectionTitle from '../../../components/titles/SectionTitle';
import { AccountInfoContent, SectionContent } from '../index.styles';
import { Container, NoDocsMessage, RowWrapper, StyledText, DocumentsWrapper } from './index.styles';

import { getDocumentsList, isShowEmptyMessage } from '../../../utils/documents';
import { getUserDocumentsList, uploadUserRequiredDocument } from '../../../redux/user/thunks';
import { previewButtonProps } from '../../../utils/preview';
import { AllUserDocuments } from '../../../constants/user/actionTypes';
import { AppState } from '../../../redux/reducers';

import { mainTheme } from '../../../assets/styles/variables/themes';
import documentsIcon from '../../../assets/images/documents.png';

const Documents = () => {
  const userDocuments = useSelector<AppState, AllUserDocuments>(({ user }) => user.userDocuments);
  const isLoading = useSelector<AppState, boolean>(({ user }) => user.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserDocumentsList());
  }, []);

  const handleUpload = (documentType: string) => (file: File) => {
    dispatch(uploadUserRequiredDocument({ documentType, file }));
  };

  const documents = { ...userDocuments.personal, ...userDocuments.company };

  return (
    <Loader isLoading={isLoading}>
      <AccountInfoContent>
        <BackButton onClick={history.goBack} />
        <Container>
          <SectionTitle title="Required Documents" iconSize="xl" icon={documentsIcon} />
          <SectionContent>
            {isShowEmptyMessage(documents) && (
              <NoDocsMessage>There are no documents required at this time</NoDocsMessage>
            )}
            <DocumentsWrapper>
              {getDocumentsList(documents).map(
                (doc) =>
                  (doc.requested || doc.id) && (
                    <RowWrapper key={doc.name}>
                      <StyledText>{doc.title}</StyledText>
                      {doc.id ? (
                        <ButtonSecond active maxWidth={mainTheme.buttonMaxSize.xs} {...previewButtonProps(doc.id)}>
                          on file
                        </ButtonSecond>
                      ) : (
                        <UploadButton onUpload={handleUpload(doc.name)} Button={ButtonFirst} />
                      )}
                    </RowWrapper>
                  )
              )}
            </DocumentsWrapper>
          </SectionContent>
        </Container>
      </AccountInfoContent>
    </Loader>
  );
};

export default Documents;
