import styled from 'styled-components';
import { mediaMinWidth } from '../../../../assets/styles/media';

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  ${mediaMinWidth.xs} {
    justify-content: flex-end;
  }

  & > button:first-child {
    margin-right: 10px;
  }
`;
