import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { mediaMinWidth } from '../../assets/styles/media';

export const OneSizeGrid = styled.div<{
  minWidth?: number;
  width: string;
  justify?: string;
  noPadding?: boolean;
  fullWidthOnTablet?: boolean;
}>`
  ${({ fullWidthOnTablet, minWidth, width, justify, noPadding }) => css`
    display: flex;
    justify-content: ${justify || 'flex-start'};
    min-width: ${minWidth ? `${minWidth}px` : 'unset'};

    ${mediaMinWidth.sm} {
      width: ${fullWidthOnTablet ? '100%' : '15%'};
    }

    ${mediaMinWidth.md} {
      padding: ${noPadding ? '0' : '0 5px'};
      width: ${width};
    }
  `}
`;

export const DoubleSizeGrid = styled(Grid)`
  width: 100%;
  padding-bottom: 10px;
  justify-content: flex-start;
  margin: 0 -5px 10px;

  ${mediaMinWidth.sm} {
    flex-wrap: nowrap;
    width: 60%;
  }

  ${mediaMinWidth.md} {
    margin: 0 -15px 0;
    width: 40%;
    justify-content: space-between;
  }

  ${mediaMinWidth.lg} {
    flex-wrap: nowrap;
    width: 45%;
  }
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 0;
`;
