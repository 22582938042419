import styled from 'styled-components';
import { mediaMinWidth, mediaMinHeight } from '../../../assets/styles/media';

export const StatusSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 115px;

  ${mediaMinWidth.sm} {
    width: 130px;
  }

  ${mediaMinWidth.md} {
    width: 140px;
  }

  .select__control {
    min-height: 30px;

    ${mediaMinHeight.sm} {
      min-height: 40px;
    }

    ${mediaMinHeight.lg} {
      min-height: 55px;
    }
  }

  .select__indicator {
    padding: 0 3px 0 0;
  }

  .select__single-value {
    margin: 0;
  }

  .select__value-container {
    padding-right: 0;
  }
`;
