// eslint-disable-next-line import/named
import { StyledProps } from 'styled-components';
import { Link } from 'react-router-dom';
import { getOr } from 'lodash/fp';
import { DEFAULT_FILE_NAME } from '../constants/preview/constants';
import { PartiesInfo } from '../constants/engagement/actionTypes';

export const getFileName = (contentDispositionHeader: string) => {
  if (contentDispositionHeader) {
    const result = contentDispositionHeader.match(/filename="(.+)"; /);

    return getOr(DEFAULT_FILE_NAME, 1, result);
  }

  return DEFAULT_FILE_NAME;
};

export const previewButtonProps = (documentId: string) =>
  ({ as: Link, to: `/preview/${documentId}` } as StyledProps<any>);

export const buildRecipients = (party: PartiesInfo) => {
  const { ir35LTD, worker, engager, intermediary1, intermediary2, intermediary3, intermediary4 } = party;

  return [
    engager,
    intermediary1,
    intermediary2,
    intermediary3,
    intermediary4,
    ir35LTD,
    { ...worker, isWorker: true },
  ].filter((party) => party.userName);
};
