import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid/Grid';

import { AppState } from '../../../redux/reducers';
import { TitleWrapper, TitleText, Reference } from './index.style';
import ButtonAsLink from '../../buttons/ButtonAsLink';

interface Props {
  icon?: string;
  edit?: boolean;
  editButtonToggle?: Function;
}

const EngagementTitle = ({ edit, editButtonToggle }: Props) => {
  const title = useSelector<AppState, string>(({ engagement }) => engagement.values.shortDescription);
  const reference = useSelector<AppState, string>(({ engagement }) => engagement.values.reference);
  const alternateReference = useSelector<AppState, string | undefined>(
    ({ engagement }) => engagement.values.alternateReference
  );

  return (
    <TitleWrapper>
      <div>
        <Grid container>
          <TitleText>{title}</TitleText>
        </Grid>
        <Reference>{reference}</Reference>
        <Reference>{alternateReference}</Reference>
      </div>
      {edit && <ButtonAsLink inRow label="Edit" onClick={() => editButtonToggle && editButtonToggle()} />}
    </TitleWrapper>
  );
};

export default EngagementTitle;
