import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DeleteDialog from '../../DeleteDialog';

import { deleteEngagement } from '../../../../../redux/engagement/thunks';
import { AppState } from '../../../../../redux/reducers';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteEngagementDialog = (props: Props) => {
  const dispatch = useDispatch();
  const isLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isLoading);
  const { id } = useParams();

  const onDeleteEngagement = () => {
    dispatch(deleteEngagement(id));
  };

  return (
    <DeleteDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      isLoading={isLoading}
      handleDelete={onDeleteEngagement}
      message="Are you sure you want to delete the engagement?"
    />
  );
};

export default DeleteEngagementDialog;
