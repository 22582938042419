import React from 'react';
import { Field, FieldInputProps, FieldMetaProps } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { toNumber } from 'lodash/fp';

import { MainInput } from '../index.style';
import { InputWrapper } from '../InputField/index.styles';
import { ErrorMessage } from '../InputErrorMessage';

interface Props {
  name: string;
  fullWidth?: boolean;
  placeholder?: string;
  maxWidth?: number;
  maxFieldWidth?: number;
}

interface FieldProps {
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  form: { setFieldValue: Function };
}

const defaultMaskOptions = {
  prefix: '£',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ', ',
};

const parseCurrencyValue = (value: string) => {
  if (!value) return 0;

  const parsedValue = value.replace(/(£)|(, )/g, '');
  return toNumber(parsedValue);
};

const InputFieldWithCurrencyMask = ({ name, maxWidth, maxFieldWidth, fullWidth, placeholder = '' }: Props) => {
  const currencyMask = createNumberMask(defaultMaskOptions);

  return (
    <Field name={name}>
      {({ field, meta: { touched, error }, form: { setFieldValue } }: FieldProps) => (
        <InputWrapper fullWidth={Boolean(fullWidth)} maxWidth={maxFieldWidth}>
          <MaskedInput
            {...field}
            type="text"
            value={field.value || ''}
            mask={currencyMask}
            placeholder={placeholder}
            onChange={(event) => setFieldValue(field.name, parseCurrencyValue(event.target.value))}
            render={(ref, props) => (
              <MainInput ref={ref} showError={touched && Boolean(error)} maxWidth={maxWidth} {...props} />
            )}
          />
          <ErrorMessage name={field.name} component="span" />
        </InputWrapper>
      )}
    </Field>
  );
};

export default InputFieldWithCurrencyMask;
