import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from './index.style';

interface Props {
  closeMenu: () => void;
}

const MenuCloseButton = ({ closeMenu }: Props) => (
  <Button onClick={closeMenu}>
    <CloseIcon fontSize="small" />
  </Button>
);

export default MenuCloseButton;
