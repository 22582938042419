import styled from 'styled-components';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  ${mediaMinWidth.xs} {
    width: calc(100% - 95px);
  }

  ${mediaMinWidth.sm} {
    width: calc(100% - 250px);
  }
`;
