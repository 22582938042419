import React from 'react';
import DialogBackTitle from '../../DialogBackButton';
import EmailCheckbox from './EmailCheckbox';
import { ButtonSecond } from '../../../../buttons/index.style';
import { ButtonWithLabel } from './index.styles';

interface Props {
  onBackClick: () => void;
  onEmailInviteClick: (value: boolean) => void;
  emailInvite: boolean;
  disabled?: boolean;
  title?: string | React.ReactNode;
}

const DialogAddUserTitle = (props: Props) => (
  <DialogBackTitle onClick={props.onBackClick} title={props.title}>
    <ButtonWithLabel>
      <ButtonSecond disabled={props.disabled} form="user-form">
        ADD
      </ButtonSecond>
      <EmailCheckbox emailInvite={props.emailInvite} onEmailInviteClick={props.onEmailInviteClick} />
    </ButtonWithLabel>
  </DialogBackTitle>
);

export default DialogAddUserTitle;
