import React from 'react';

import Dialog from '../index';
import { ButtonFirst } from '../../../buttons/index.style';
import { ContentWrapper } from '../index.styles';
import { mainTheme } from '../../../../assets/styles/variables/themes';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  errorMessage?: string;
  successMessage: string;
}

const ResultDialog = ({ onClose, isOpen, errorMessage, successMessage }: Props) => (
  <Dialog isOpen={isOpen} dialogType="resultDialog" maxWidth={mainTheme.dialogMaxSize.xs}>
    <ContentWrapper>
      <p>{errorMessage ? errorMessage : successMessage}</p>
      <ButtonFirst maxWidth={mainTheme.buttonMaxSize.xs} onClick={onClose}>
        Ok
      </ButtonFirst>
    </ContentWrapper>
  </Dialog>
);

export default ResultDialog;
