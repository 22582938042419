import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import EngagementReferences from './EngagementReferences/index';
import Loader from '../../Loader/index';
import InputField from '../../inputs/InputField/index';
import InputWithCurrencyMask from '../../inputs/InputWithCurrencyMask';

import { EngagementPayload } from '../../../constants/engagement/actionTypes';
import { AppState } from '../../../redux/reducers';
import { EngagementValidation } from './validation';
import { createEngagement, updateEngagement, getEngagement } from '../../../redux/engagement/thunks';
import { resetEngagementState } from '../../../redux/engagement/actions';

import { mainTheme } from '../../../assets/styles/variables/themes';
import { ButtonFirst } from '../../buttons/index.style';
import { ButtonWrapper, FormRow, FormContent } from './index.style';

interface Props {
  skipResetEngagement?: boolean;
}

const EngagementFormComponent = ({ skipResetEngagement }: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isLoading);
  const isEngagementLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isEngagementLoading);
  const engagement = useSelector<AppState, EngagementPayload>(({ engagement }) => engagement.values);
  const { inputMaxSize, buttonMaxSize } = mainTheme;

  useEffect(() => {
    if (id) {
      dispatch(getEngagement(id));
    }

    return () => {
      if (!skipResetEngagement) {
        dispatch(resetEngagementState());
      }
    };
  }, []);

  const handleSubmit = (engagementPayload: EngagementPayload, { setSubmitting }: { setSubmitting: Function }) => {
    const submitFunc = id ? updateEngagement : createEngagement;

    dispatch(submitFunc(engagementPayload));
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={engagement}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={EngagementValidation}
    >
      <Loader isLoading={isEngagementLoading}>
        <Form id="engagement">
          <FormContent>
            {id && (
              <ButtonWrapper>
                <ButtonFirst maxWidth={buttonMaxSize.xs} type="submit" disabled={isLoading}>
                  save
                </ButtonFirst>
              </ButtonWrapper>
            )}
            <FormRow>
              <InputField
                maxWidth={inputMaxSize.md}
                fullWidth={false}
                name="shortDescription"
                placeholder="Engagement Title*"
              />
              <EngagementReferences id={id} reference={engagement.reference} />
            </FormRow>
            <FormRow>
              <InputField rows={3} fullWidth textarea name="description" placeholder="Description" />
            </FormRow>
            <FormRow>
              <InputField maxWidth={inputMaxSize.md} name="startDate" placeholder="Start Date (dd/mm/yy)*" datePicker />
              <InputField maxWidth={inputMaxSize.md} name="endDate" placeholder="End Date (dd/mm/yy)*" datePicker />
            </FormRow>
            <FormRow>
              <InputWithCurrencyMask maxWidth={inputMaxSize.sm} name="budget" placeholder="Total Budget (£)" />
              <InputWithCurrencyMask maxWidth={inputMaxSize.sm} name="assumedTaxRisk" placeholder="Tax Risk (£)" />
            </FormRow>
          </FormContent>
        </Form>
      </Loader>
    </Formik>
  );
};

export default EngagementFormComponent;
