import { Action } from 'redux';
import { get } from 'lodash/fp';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../reducers';

import api from '../../services/api';
import { getFileError, getFileRequest, getFileSuccess } from './actions';
import { getFileName } from '../../utils/preview';

export const getFilePreview = (id: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getFileRequest());

    const { data, headers } = await api.preview.getDocument(id);
    const fileURL = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));

    dispatch(
      getFileSuccess({
        fileURL,
        fileName: getFileName(headers['content-disposition']),
        fileType: get('content-type', headers),
      })
    );
  } catch (e) {
    dispatch(getFileError('Error connection'));
  }
};
