import React from 'react';

import { SearchInputWrapper, SearchInput } from './index.style';
import Icon from '../../icons/Icon';

import searchIcon from '../../../assets/images/search_icon.png';

interface Props {
  placeholder: string;
  handleChange: (value: string) => void;
  positionAbsolute: boolean;
}

const TableSearchInput = ({ placeholder, handleChange, positionAbsolute }: Props) => (
  <SearchInputWrapper positionAbsolute={positionAbsolute}>
    <SearchInput placeholder={placeholder} onChange={(e) => handleChange(e.target.value)} />
    <Icon iconSrc={searchIcon} size="sm" />
  </SearchInputWrapper>
);

export default TableSearchInput;
