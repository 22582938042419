import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../../assets/styles/media';

export const ButtonWithLabel = styled.div`
  ${({ theme: { fontColors, fontSize, bgColors, buttonMaxSize, fontFamily } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    padding-right: 10px;

    ${mediaMinWidth.xs} {
      padding-right: 30px;
    }

    button {
      max-width: 110px;

      ${mediaMinWidth.xl} {
        max-width: none;
      }
    }

    & > div > label > span {
      opacity: 0.48;
      font-family: ${fontFamily.primary};
      font-weight: 300;
      font-style: italic;
      font-size: ${fontSize.sm};
      color: ${fontColors.primary};

      ${mediaMinWidth.xl} {
        font-size: ${fontSize.md};
      }

      & > .MuiIconButton-label {
        opacity: 1;
        color: ${fontColors.primary};
        background-color: ${bgColors.primary};
      }
    }
  `}
`;
