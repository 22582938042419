import React from 'react';
import { Row, GetRowPropsFn } from 'react-table';

import { StyledTableRow, CellContent } from './index.style';

interface Props<D extends {}> {
  row: Row<D>;
  getRowProps: GetRowPropsFn<D>;
}

const TableRow = <D extends {}>({ row, getRowProps }: Props<D>) => (
  <StyledTableRow {...row.getRowProps(getRowProps(row))}>
    {row.cells.map((cell) => (
      <td {...cell.getCellProps()}>
        <CellContent className={cell.column.className}>{cell.render('Cell')}</CellContent>
      </td>
    ))}
  </StyledTableRow>
);

export default TableRow;
