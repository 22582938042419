import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const Container = styled.div`
  max-width: 690px;
  width: 100%;
  padding: 40px 0 0;

  ${mediaMinWidth.xs} {
    margin: 0 auto;
  }

  ${mediaMinWidth.md} {
    padding: 0;
  }

  ${mediaMinWidth.xl} {
    max-width: 750px;
  }
`;
