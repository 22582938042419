import styled from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';

export const StyledTableRow = styled.tr<{ headerRow?: boolean }>`
  max-height: 30px;
  border-bottom: ${({ headerRow, theme: { borderColors, borderWidth, keyColors } }) =>
    headerRow ? `4px solid ${keyColors.first}` : `${borderWidth.xs} solid ${borderColors.primary}`};

  ${mediaMinWidth.md} {
    min-height: 40px;
  }

  ${mediaMinWidth.lg} {
    min-height: 55px;
  }

  &:hover {
    td > div {
      background-color: rgba(130, 69, 155, 0.05);
      border-color: ${({ theme: { bgColors } }) => bgColors.primary};
    }
  }

  th,
  td {
    min-height: 30px;
    padding: 0;
    text-align: left;

    ${mediaMinWidth.md} {
      min-height: 40px;
    }

    ${mediaMinWidth.lg} {
      min-height: 55px;
    }
  }

  th {
    padding: 5px 10px;
    color: ${({ theme: { keyColors } }) => keyColors.first};
    font-size: ${({ theme: { fontSize } }) => fontSize.sm};
    font-weight: 700;

    ${mediaMinWidth.lg} {
      padding: 10px;
    }
  }

  td {
    font-weight: 300;
    font-size: ${({ theme: { fontSize } }) => fontSize.xs};

    @-moz-document url-prefix() {
      height: 100%;
    }
  }

  .center {
    justify-content: center;
    text-align: center;
  }
`;

export const CellContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 30px;
  padding: 0 10px;
  border: solid transparent;
  border-width: 5px 0 5px 0;

  ${mediaMinWidth.md} {
    min-height: 40px;
  }

  ${mediaMinWidth.lg} {
    min-height: 55px;
  }
`;
