import {
  Engagement,
  GET_ENGAGEMENTS_ERROR,
  GET_ENGAGEMENTS_REQUEST,
  GET_ENGAGEMENTS_SUCCESS,
  engagementsTypes,
} from '../../constants/engagements/actionTypes';

export interface EngagementsStore {
  results: Engagement[];
  pageCount: number;
  isLoading: boolean;
}

const initialState: EngagementsStore = {
  results: [],
  pageCount: 1,
  isLoading: false,
};

export default (state: EngagementsStore = initialState, action: engagementsTypes): EngagementsStore => {
  switch (action.type) {
    case GET_ENGAGEMENTS_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ENGAGEMENTS_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case GET_ENGAGEMENTS_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
