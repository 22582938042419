import React, { useState } from 'react';
import PreviewImageViewer from '../../pages/Preview/PreviewImageViewer';
import { DownloadButton, Toolbar, Iframe, ToolbarWrapper } from './index.styles';

interface Props {
  fileURl: string;
  fileName: string;
  fileType: string;
}

const DocumentViewer = ({ fileURl, fileName, fileType }: Props) => {
  const [imageWidth, setImageWidth] = useState<number>();

  const isImage = fileType.includes('image');

  return (
    <>
      <ToolbarWrapper>
        <Toolbar maxWidth={imageWidth}>
          <DownloadButton href={fileURl} download={fileName}>
            Download
          </DownloadButton>
        </Toolbar>
      </ToolbarWrapper>
      {isImage ? (
        <PreviewImageViewer fileName={fileName} fileURl={fileURl} getCurrentImageWidth={setImageWidth} />
      ) : (
        <Iframe src={`/pdf-viewer/web/viewer.html?file=${fileURl}`} />
      )}
    </>
  );
};

export default DocumentViewer;
