import styled from 'styled-components';

import topToBottomArrow from '../../../../../assets/images/top-to-bottom-arrow.png';

export const StyledListItem = styled.li`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 20px;
    background-size: 20px;
    background-image: url(${topToBottomArrow});
    background-repeat: no-repeat;
  }
`;
