import styled from 'styled-components';

export const TextContainer = styled.div`
  p:first-child {
    margin-bottom: 15px;
    margin-top: 0;
  }

  p {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 16px;
  }

  p:last-child {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;
