import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';

import Dialog from '../index';
import DialogBackTitle from '../DialogBackButton';
import Loader from '../../../Loader';
import RequestContractsDocuments from './ContractDocuments';
import RequestDocumentControl from './RequestDocumentControl';
import { ButtonThird } from '../../../buttons/index.style';
import { TitleWrapper, ButtonWrapper, FormBlock } from './index.style';

import { AppState } from '../../../../redux/reducers';
import { AllUserDocuments } from '../../../../constants/user/actionTypes';
import { RequestContractsPayload, RequestInfoParties } from '../../../../constants/documents/actionTypes';
import { documentTypes } from '../../../../constants/documents/constants';
import {
  getContractsList,
  getRequiredDocumentsList,
  requestDocuments,
  deleteDocument,
} from '../../../../redux/documents/thunks';
import { getDocumentsList } from '../../../../utils/documents';
import { getPartyTitle } from '../../../../utils/supplyChainUtils';
import { uploadUserRequiredDocument } from '../../../../redux/user/thunks';

import { mainTheme } from '../../../../assets/styles/variables/themes';
import ResultDialog from '../ResultDialog';
import { resetDocumentsState } from '../../../../redux/documents/actions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const RequestInformationDialog = ({ isOpen, onClose }: Props) => {
  const { id } = useParams();
  const initialValues = useSelector<AppState, any>(({ documents }) => documents.values);
  const userDocuments = useSelector<AppState, AllUserDocuments>(({ documents }) => documents.userDocuments);
  const isRequiredDocumentsLoading = useSelector<AppState, boolean>(
    ({ documents }) => documents.isRequiredDocumentsLoading
  );
  const isContractsLoading = useSelector<AppState, boolean>(({ documents }) => documents.isContractsLoading);
  const isLoading = useSelector<AppState, boolean>(({ documents }) => documents.isLoading);
  const successRequest = useSelector<AppState, boolean>(({ documents }) => documents.successRequest);
  const errorMessage = useSelector<AppState, string>(({ documents }) => documents.errorMessage);

  const parties = useSelector<AppState, RequestInfoParties>(({ documents }) => documents.parties);
  const requestFrom = parties.requestFrom.userName;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRequiredDocumentsList(parties.requestFrom.userName));
    dispatch(getContractsList(id, parties.requestFrom.userName));
  }, []);

  const onSubmit = (values: FormikValues) => {
    dispatch(
      requestDocuments({
        engagementId: id,
        formValues: values,
      } as RequestContractsPayload)
    );
  };

  const handleUploadRequiredDocument = (fieldName: string, userName: string) => (file: File) => {
    dispatch(uploadUserRequiredDocument({ userName, documentType: fieldName, file }));
  };

  const handleDeleteDocument = (idDocument: string, documentType: string) => () => {
    dispatch(deleteDocument({ id: idDocument || '', userName: requestFrom, documentType, idEngagement: id }));
  };

  const handleResultDialogClose = () => {
    dispatch(resetDocumentsState());
    onClose();
  };

  const companyDocuments = getDocumentsList(userDocuments.company);
  const personalDocuments = getDocumentsList(userDocuments.personal);

  return (
    <>
      <Dialog
        maxWidth={mainTheme.dialogMaxSize.lg}
        isOpen={isOpen}
        dialogType="requestInformationDialog"
        dialogTitle={
          <TitleWrapper>
            <DialogBackTitle title={`Request Information (${getPartyTitle(parties.requestFrom)})`} onClick={onClose} />
            <p>Tick Applicable</p>
          </TitleWrapper>
        }
      >
        <Loader isLoading={isRequiredDocumentsLoading || isContractsLoading}>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
            <Form id="requestInformation">
              <RequestContractsDocuments parties={parties} handleDelete={handleDeleteDocument} />
              {!isEmpty(companyDocuments) && (
                <FormBlock>
                  <h2>Company Documents</h2>
                  {companyDocuments.map(({ id, name, title }) => (
                    <RequestDocumentControl
                      key={name}
                      label={title}
                      idDocument={id}
                      documentType={documentTypes.REQUIRED_DOCUMENT}
                      name={`documents.company.${name}`}
                      handleDelete={handleDeleteDocument}
                      handleUploadRequiredDocument={handleUploadRequiredDocument(name, requestFrom)}
                    />
                  ))}
                </FormBlock>
              )}
              {!isEmpty(personalDocuments) && (
                <FormBlock>
                  <h2>Personal Documents</h2>
                  {personalDocuments.map(({ id, name, title }) => (
                    <RequestDocumentControl
                      key={name}
                      idDocument={id}
                      label={title}
                      documentType={documentTypes.REQUIRED_DOCUMENT}
                      handleDelete={handleDeleteDocument}
                      name={`documents.personal.${name}`}
                      handleUploadRequiredDocument={handleUploadRequiredDocument(name, requestFrom)}
                    />
                  ))}
                </FormBlock>
              )}
              <ButtonWrapper>
                <ButtonThird type="submit" disabled={isLoading}>
                  request
                </ButtonThird>
              </ButtonWrapper>
            </Form>
          </Formik>
        </Loader>
      </Dialog>
      <ResultDialog
        isOpen={successRequest || Boolean(errorMessage)}
        errorMessage={errorMessage}
        onClose={handleResultDialogClose}
        successMessage="Success - Your information request(s) have been sent"
      />
    </>
  );
};

export default RequestInformationDialog;
