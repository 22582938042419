import * as Yup from 'yup';
import { userTypes } from '../../../../constants/user/userTypes';

export const editUserValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  company: Yup.string().when('userType', {
    is: (value) => value !== userTypes.WORKER,
    then: Yup.string().required('Required'),
  }),
  telephone: Yup.string().required('Required'),
  addressLine1: Yup.string().required('Required'),
  postcode: Yup.string().required('Required'),
});
