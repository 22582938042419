import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import InputField from './SetUpAccountBaseInfoInput';
import Loader from '../../../components/Loader';
import { FormContent, ButtonWrapper } from './index.style';
import { FormRow } from '../index.style';
import { ButtonFirst } from '../../../components/buttons/index.style';

import { userInfoTypes, userTypes } from '../../../constants/user/userTypes';
import { ExtendedUser, User } from '../../../constants/user/actionTypes';
import { AppState } from '../../../redux/reducers';
import { getUserInfo, updateUser } from '../../../redux/user/thunks';
import { setUpAccountValidation } from './validation';

interface Props {
  nextStep: Function;
}

const BaseInfoForm = ({ nextStep }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector<AppState, User>(({ user }) => user.values);
  const isLoading = useSelector<AppState, boolean>(({ user }) => user.isLoading);
  const isUserLoading = useSelector<AppState, boolean>(({ user }) => user.isUserLoading);

  useEffect(() => {
    dispatch(getUserInfo(userInfoTypes.SHORT));
  }, []);

  const handleSubmit = (values: User, { setSubmitting }: { setSubmitting: Function }) => {
    dispatch(updateUser(values as ExtendedUser));
    setSubmitting(false);
    nextStep();
  };

  return (
    <Formik initialValues={user} enableReinitialize onSubmit={handleSubmit} validationSchema={setUpAccountValidation}>
      <Loader isLoading={isUserLoading}>
        <Form id="userAccount">
          <FormContent>
            <FormRow>
              <InputField fullWidth={false} name="firstName" label="First Name*" />
              <InputField fullWidth={false} name="lastName" label="Last Name*" />
            </FormRow>
            <FormRow>
              <InputField name="company" label={`Company Name${user.userType === userTypes.WORKER ? '' : '*'}`} />
            </FormRow>
            <FormRow>
              <InputField name="jobTitle" label="Position in company" />
            </FormRow>
            <FormRow>
              <InputField fullWidth={false} name="telephone" label="Telephone Number*" />
              <ButtonWrapper>
                <ButtonFirst type="submit" disabled={isLoading}>
                  Confirm
                </ButtonFirst>
              </ButtonWrapper>
            </FormRow>
          </FormContent>
        </Form>
      </Loader>
    </Formik>
  );
};

export default BaseInfoForm;
