import { MainRouter } from '../../declarations/routing';
import { ROUTES } from '../../constants/routing';
import Dashboard from '../Dashboard';
import SupplyChain from '../SupplyChain';
import SDSUpload from '../SDS/SDSUpload';
import EngagementSummary from '../EngagementSummary';
import UserInfo from '../UserInfo';
import WPQ from '../WPQ';
import { userTypes } from '../../constants/user/userTypes';
import withCheckAccessToEngagement from '../../hocs/withCheckAccessToEngagement';
import SDSVerification from '../SDS/SDSVerification';

const routes: MainRouter[] = [
  {
    path: ROUTES.DASHBOARD,
    exact: true,
    requireTermsAgreement: true,
    component: Dashboard,
  },
  {
    path: ROUTES.SUPPLY_CHAIN,
    auth: [userTypes.WTT_ADMIN],
    exact: true,
    breadcrumbTitle: 'Supply Chain',
    getPath: ({ id }) => `/engagement/${id}/supply-chain`,
    requireTermsAgreement: true,
    component: SupplyChain,
  },
  {
    path: ROUTES.DOCUMENTS,
    exact: true,
    requireTermsAgreement: true,
    component: UserInfo,
  },
  {
    path: ROUTES.ACCOUNT,
    exact: true,
    requireTermsAgreement: true,
    component: UserInfo,
  },
  {
    path: ROUTES.SDS_UPLOAD,
    auth: [userTypes.WTT_ADMIN],
    exact: true,
    breadcrumbTitle: 'SDS Upload',
    getPath: ({ id }) => `/engagement/${id}/sds-upload`,
    requireTermsAgreement: true,
    component: SDSUpload,
  },
  {
    path: ROUTES.ENGAGEMENT_SUMMARY,
    exact: true,
    requireTermsAgreement: true,
    component: withCheckAccessToEngagement(EngagementSummary),
  },
  {
    path: ROUTES.WPQ,
    exact: true,
    requireTermsAgreement: true,
    component: WPQ,
  },
  {
    path: ROUTES.SDS_VERIFICATION_ON_PLATFORM,
    exact: true,
    requireTermsAgreement: true,
    component: SDSVerification,
  },
];

export default routes;
