import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Main from '../../components/Main';
import Account from './Account';
import Documents from './Documents';

import { UserInfoBody, UserInfoContainer } from './index.styles';

const UserInfo = () => (
  <Main>
    <Main.Content>
      <UserInfoContainer>
        <UserInfoBody>
          <Switch>
            <Route exact path="/account">
              <Account />
            </Route>
            <Route exact path="/documents">
              <Documents />
            </Route>
          </Switch>
        </UserInfoBody>
      </UserInfoContainer>
    </Main.Content>
  </Main>
);

export default UserInfo;
