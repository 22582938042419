import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import Section from '../SDSVerificationSection';
import VerificationResultColumn from './SDSVerificationResultColumn';
import {
  VerificationResultSectionWrapper,
  VerificationResultRow,
  VerificationResultPrompt,
  VerificationResultHeader,
  RegistrationHash,
} from './index.style';

import logoCircle from '../../../../assets/images/logo_circle.png';

import { AppState } from '../../../../redux/reducers';
import {
  registrationStatusCaptions,
  registrationStatusLabels,
  verificationSDSResultColumns,
  verificationSdsResultColumnsForFinalSds,
} from '../../../../constants/sds/constants';
import { SdsVerificationAcceptanceEntry, SdsVerificationColumn } from '../../../../constants/sds/actionTypes';
import { useMediaQuery } from 'react-responsive';

const SDSVerificationResult = () => {
  const registrationState = useSelector<AppState, string>(({ sdsVerification }) => sdsVerification.registrationState);
  const acceptanceEntries = useSelector<AppState, SdsVerificationAcceptanceEntry[]>(
    ({ sdsVerification }) => sdsVerification.acceptanceEntries
  );
  const registrationTime = useSelector<AppState, string | undefined>(
    ({ sdsVerification }) => sdsVerification.registrationTime
  );
  const chainId = useSelector<AppState, string>(({ sdsVerification }) => sdsVerification.chainId);
  const filteredAcceptanceEntries = acceptanceEntries.filter(({ hash }) => hash);
  const isBigScreen = useMediaQuery({ minWidth: 576 });
  // eslint-disable-next-line react/display-name
  const renderResultForFinalSDS = (acceptanceEntry: SdsVerificationAcceptanceEntry) => ({
    label,
    ...column
  }: SdsVerificationColumn) => {
    let value: React.ReactNode = 'Accepted';

    if (label === 'Date') {
      value = acceptanceEntry.registrationTime;
    }

    if (label === 'Organisation') {
      value = (
        <>
          {acceptanceEntry.company}
          <RegistrationHash>Registration Hash: {acceptanceEntry.hash}</RegistrationHash>
        </>
      );
    }

    return <VerificationResultColumn key={label} value={value} title={isBigScreen ? '' : label} {...column} />;
  };

  const renderHeaderColumns = ({ label, ...column }: SdsVerificationColumn) => (
    <VerificationResultColumn key={label} title={label} {...column} />
  );

  const renderResult = ({ label, ...column }: SdsVerificationColumn) => {
    let value: string | undefined = '';

    if (label === 'Date') {
      value = registrationTime;
    }

    if (label === 'Result') {
      value = registrationStatusLabels[registrationState];
    }

    return <VerificationResultColumn key={label} value={value} title={isBigScreen ? '' : label} {...column} />;
  };

  const columns =
    filteredAcceptanceEntries.length > 0 ? verificationSdsResultColumnsForFinalSds : verificationSDSResultColumns;

  return registrationState ? (
    <VerificationResultSectionWrapper>
      <Section iconSize="xl" icon={logoCircle}>
        <Box width={1}>
          <VerificationResultHeader>{isBigScreen && columns.map(renderHeaderColumns)}</VerificationResultHeader>
          {filteredAcceptanceEntries.length > 0 ? (
            filteredAcceptanceEntries.map((acceptanceEntry) => (
              <VerificationResultRow key={acceptanceEntry.hash}>
                {verificationSdsResultColumnsForFinalSds.map(renderResultForFinalSDS(acceptanceEntry))}
              </VerificationResultRow>
            ))
          ) : (
            <VerificationResultRow key={registrationTime}>
              {verificationSDSResultColumns.map(renderResult)}
            </VerificationResultRow>
          )}
          <VerificationResultPrompt>
            {chainId && <p>Chain ID {chainId}</p>}
            <p>{registrationStatusCaptions[registrationState]}</p>
          </VerificationResultPrompt>
        </Box>
      </Section>
    </VerificationResultSectionWrapper>
  ) : null;
};

export default SDSVerificationResult;
