import styled from 'styled-components';
import { mediaMinWidth } from '../../assets/styles/media';

export const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UserInfoBody = styled.div`
  flex: 10;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1.4rem;
`;

export const AccountInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mediaMinWidth.md} {
    flex-direction: row;

    & > div:first-child {
      position: absolute;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  margin: 0 0 40px;
`;

export const SectionContent = styled.div`
  ${mediaMinWidth.sm} {
    padding: 0 0 0 5.063rem;
  }
`;
