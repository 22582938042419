import {
  ADD_TO_PARTY_ENGAGEMENT_ERROR,
  ADD_TO_PARTY_ENGAGEMENT_SUCCESS,
  CREATE_ENGAGEMENT_REQUEST,
  CREATE_ENGAGEMENT_SUCCESS,
  DELETE_ATTACHED_DOCUMENTS_ERROR,
  DELETE_ATTACHED_DOCUMENTS_REQUEST,
  DELETE_ATTACHED_DOCUMENTS_SUCCESS,
  DELETE_ENGAGEMENT_REQUEST,
  DELETE_ENGAGEMENT_SUCCESS,
  DELETE_PARTY_FROM_ENGAGEMENT_ERROR,
  DELETE_PARTY_FROM_ENGAGEMENT_REQUEST,
  DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS,
  EngagementDocument,
  EngagementParty,
  EngagementPayload,
  engagementTypes,
  FINALISE_ENGAGEMENT_ERROR,
  FINALISE_ENGAGEMENT_REQUEST,
  FINALISE_ENGAGEMENT_SUCCESS,
  GET_ENGAGEMENT_REQUEST,
  GET_ENGAGEMENT_SUCCESS,
  PartiesInfo,
  RESET_ENGAGEMENT_STATE,
  RESET_FINALISE_ENGAGEMENT_RESULT,
  SdsDocumentsInfo,
  SET_ENGAGEMENT_DATA_ERROR,
  UPDATE_ENGAGEMENT_REQUEST,
  UPDATE_ENGAGEMENT_SUCCESS,
  UPLOAD_ATTACHED_DOCUMENTS_ERROR,
  UPLOAD_ATTACHED_DOCUMENTS_REQUEST,
  UPLOAD_ATTACHED_DOCUMENTS_SUCCESS,
  UPLOAD_SDS_FILE_REQUEST,
  UPLOAD_SDS_FILE_SUCCESS,
  RESET_UPLOAD_SDS_STATE,
  RESET_EDIT_ENGAGEMENT_STATE,
  TOGGLE_FINALISE_SDS_MODAL,
  WPQInfo,
} from '../../constants/engagement/actionTypes';

export interface EngagementStore {
  values: EngagementPayload;
  engagementId: string;
  errorMessage: string;
  successRequest: boolean;
  isLoading: boolean;
  isEngagementLoading: boolean;
  isDocumentLoading: boolean;
  isConfirmFinaliseModalOpen: boolean;
  partiesInfo: PartiesInfo | null;
  sdsDocumentsInfo: SdsDocumentsInfo | null;
  wpqInfo: WPQInfo;
  engagementDocumentsInfo: EngagementDocument[];
  ir35LTDDefaultUserInfo: EngagementParty;
}

const initialState: EngagementStore = {
  values: {
    id: '',
    shortDescription: '',
    alternateReference: '',
    description: '',
    startDate: null,
    endDate: null,
    budget: 0,
    assumedTaxRisk: 0,
    reference: '',
    sdsStatus: '',
    ir35Status: '',
  },
  partiesInfo: null,
  sdsDocumentsInfo: null,
  wpqInfo: {
    engagerWPQInfo: {
      enagagerWPQBaseLink: '',
      engagerWPQ: null,
      requested: false,
    },
    workerWPQInfo: {
      workerWPQBaseLink: '',
      workerWPQ: null,
      requested: false,
    },
  },
  engagementDocumentsInfo: [],
  engagementId: '',
  errorMessage: '',
  isLoading: false,
  isEngagementLoading: false,
  isDocumentLoading: false,
  ir35LTDDefaultUserInfo: {
    company: '',
    firstName: '',
    lastName: '',
    userName: '',
  },
  isConfirmFinaliseModalOpen: false,
  successRequest: false,
};

export default (state: EngagementStore = initialState, action: engagementTypes): EngagementStore => {
  switch (action.type) {
    case GET_ENGAGEMENT_REQUEST: {
      return { ...state, isEngagementLoading: true };
    }
    case CREATE_ENGAGEMENT_REQUEST:
    case UPDATE_ENGAGEMENT_REQUEST:
    case DELETE_PARTY_FROM_ENGAGEMENT_REQUEST:
    case DELETE_ENGAGEMENT_REQUEST:
    case FINALISE_ENGAGEMENT_REQUEST:
    case UPLOAD_SDS_FILE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ENGAGEMENT_SUCCESS: {
      return {
        ...state,
        values: action.payload.engagementInfo,
        partiesInfo: action.payload.partiesInfo,
        sdsDocumentsInfo: action.payload.sdsDocumentsInfo,
        wpqInfo: action.payload.wpqInfo,
        engagementDocumentsInfo: action.payload.engagementDocumentsInfo,
        ir35LTDDefaultUserInfo: action.payload.ir35LTDDefaultUserInfo,
        isEngagementLoading: false,
      };
    }
    case CREATE_ENGAGEMENT_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case DELETE_ENGAGEMENT_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case UPDATE_ENGAGEMENT_SUCCESS:
    case UPLOAD_SDS_FILE_SUCCESS:
    case FINALISE_ENGAGEMENT_SUCCESS: {
      return { ...state, isLoading: false, successRequest: true };
    }
    case SET_ENGAGEMENT_DATA_ERROR: {
      return { ...state, ...action.payload, isLoading: false, isEngagementLoading: false };
    }
    case RESET_ENGAGEMENT_STATE:
      return { ...initialState };
    case DELETE_PARTY_FROM_ENGAGEMENT_SUCCESS: {
      const { engagementPartyType } = action.payload;
      const newPartiesInfo = {
        ...state.partiesInfo,
        [engagementPartyType]: {
          company: null,
          firstName: null,
          lastName: null,
        },
      } as PartiesInfo;
      return { ...state, partiesInfo: newPartiesInfo, isLoading: false };
    }
    case DELETE_PARTY_FROM_ENGAGEMENT_ERROR:
    case ADD_TO_PARTY_ENGAGEMENT_ERROR:
    case FINALISE_ENGAGEMENT_ERROR: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case ADD_TO_PARTY_ENGAGEMENT_SUCCESS: {
      const { engagementPartyType, engagementParty } = action.payload;
      const newPartiesInfo = {
        ...state.partiesInfo,
        [engagementPartyType]: engagementParty,
      } as PartiesInfo;
      return { ...state, partiesInfo: newPartiesInfo, isLoading: false };
    }
    case UPLOAD_ATTACHED_DOCUMENTS_REQUEST:
    case DELETE_ATTACHED_DOCUMENTS_REQUEST: {
      return { ...state, isDocumentLoading: true };
    }
    case UPLOAD_ATTACHED_DOCUMENTS_SUCCESS:
    case DELETE_ATTACHED_DOCUMENTS_SUCCESS: {
      return { ...state, isDocumentLoading: false };
    }
    case UPLOAD_ATTACHED_DOCUMENTS_ERROR:
    case DELETE_ATTACHED_DOCUMENTS_ERROR: {
      return { ...state, ...action.payload, isDocumentLoading: false };
    }
    case TOGGLE_FINALISE_SDS_MODAL: {
      return { ...state, isConfirmFinaliseModalOpen: !state.isConfirmFinaliseModalOpen };
    }
    case RESET_FINALISE_ENGAGEMENT_RESULT:
    case RESET_UPLOAD_SDS_STATE:
    case RESET_EDIT_ENGAGEMENT_STATE: {
      return { ...state, successRequest: false, errorMessage: '' };
    }
    default: {
      return state;
    }
  }
};
