import React from 'react';
import { Formik, FormikValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { values } from 'lodash/fp';

import { ButtonSecond } from '../../../../components/buttons/index.style';
import Dropzone from '../../../../components/inputs/InputDropzone';
import StatusSelect from '../../../../components/selects/StatusSelect';
import {
  StyledForm,
  FormContent,
  FormColumn,
  FormRow,
  InfoFormWrapper,
  FieldTitle,
  FieldValue,
  DropzoneContainer,
  ButtonWrapper,
} from './index.style';

import { AppState } from '../../../../redux/reducers';
import { ir35Statuses, sdsStatuses, statuses } from '../../../../constants/engagement/constants';
import { uploadSDSFile } from '../../../../redux/engagement/thunks';
import { PartiesInfo, SDSFilePayload } from '../../../../constants/engagement/actionTypes';
import { buildFullName } from '../../../../utils/supplyChainUtils';
import { uploadSDSValidation } from './validation';
import { mainTheme } from '../../../../assets/styles/variables/themes';

const initialValues = {
  sdsStatus: '',
  ir35Status: '',
  sdsFile: null,
};

const SDSUploadForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector<AppState, boolean>(({ engagement }) => engagement.isLoading);
  const engagementParties = useSelector<AppState, PartiesInfo | null>(({ engagement }) => engagement.partiesInfo);

  const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: Function }) => {
    dispatch(uploadSDSFile(id, values as SDSFilePayload));
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={uploadSDSValidation}>
      {({ errors, setFieldValue, touched }) => (
        <StyledForm id="upload-sds">
          <FormContent>
            <InfoFormWrapper>
              <FormRow>
                <FormColumn alignLeft>
                  <FieldTitle>End Client</FieldTitle>
                  <FieldValue alignLeft>{engagementParties?.engager?.company}</FieldValue>
                </FormColumn>
                <FormColumn>
                  <FieldTitle>Worker</FieldTitle>
                  <FieldValue>{buildFullName(engagementParties?.worker)}</FieldValue>
                </FormColumn>
                <FormColumn>
                  <FieldTitle>SDS Status</FieldTitle>
                  <FieldValue>
                    <StatusSelect
                      name={statuses.SDS_STATUS}
                      options={[sdsStatuses.INDICATIVE, sdsStatuses.FINAL]}
                      onChange={(value) => setFieldValue(statuses.SDS_STATUS, value)}
                      showError={Boolean(touched.sdsStatus && errors.sdsStatus)}
                    />
                  </FieldValue>
                </FormColumn>
                <FormColumn>
                  <FieldTitle>IR35 Status</FieldTitle>
                  <FieldValue>
                    <StatusSelect
                      name={statuses.IR35_STATUS}
                      options={values(ir35Statuses)}
                      onChange={(value) => setFieldValue(statuses.IR35_STATUS, value)}
                      showError={Boolean(touched.ir35Status && errors.ir35Status)}
                    />
                  </FieldValue>
                </FormColumn>
              </FormRow>
              <DropzoneContainer>
                <Dropzone
                  name="sdsFile"
                  label="Upload SDS"
                  setFieldValue={setFieldValue}
                  showError={Boolean(touched.sdsFile && errors.sdsFile)}
                />
              </DropzoneContainer>
            </InfoFormWrapper>

            <ButtonWrapper>
              <ButtonSecond type="submit" disabled={isLoading} minWidth={mainTheme.buttonMaxSize.xs}>
                Submit
              </ButtonSecond>
            </ButtonWrapper>
          </FormContent>
        </StyledForm>
      )}
    </Formik>
  );
};

export default SDSUploadForm;
