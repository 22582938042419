import React from 'react';
import { useHistory } from 'react-router-dom';

import Main from '../../../components/Main';
import { ButtonFirst } from '../../../components/buttons/index.style';
import BaseLayout from '../../../components/layouts/BaseLayout';
import { ExpirationMessageContent } from './index.styles';

const SDSAcceptExpirationMessage = () => {
  const history = useHistory();

  const goHome = () => history.push('/');

  return (
    <BaseLayout>
      <Main>
        <Main.Content>
          <ExpirationMessageContent>
            <span>The link has expired.</span>
            <span>To discuss, please contact our helpdesk.</span>
            <a href="tel:+44 (0) 20 3468 0008">+44 (0) 20 3468 0008</a>
            <ButtonFirst onClick={goHome} maxWidth={160} minWidth={100}>
              ok
            </ButtonFirst>
          </ExpirationMessageContent>
        </Main.Content>
      </Main>
    </BaseLayout>
  );
};

export default SDSAcceptExpirationMessage;
