import React from 'react';
import { Link, useParams } from 'react-router-dom';

import Section from '../index';
import ButtonAsLink from '../../../../components/buttons/ButtonAsLink';
import Icon from '../../../../components/icons/Icon';
import { IR35Status } from '../../../../components/IR35Status';
import { StatusContainer, NoDecisionStatusMessage } from './index.style';

import searchIcon from '../../../../assets/images/search_icon.png';
import groupIcon from '../../../../assets/images/group.png';

import { NO_STATUS_MESSAGE } from '../../../../constants/engagement/constants';

interface Props {
  ir35Status?: string;
  sdsStatus?: string;
  isWTTAdmin: boolean;
  sdsId?: string | null;
}

const DecisionStatusSection = ({ ir35Status, sdsStatus, isWTTAdmin, sdsId }: Props) => {
  const { id } = useParams();

  return (
    <Section
      title={`Status Decision ${sdsStatus ? `(${sdsStatus})` : ''}`}
      icon={groupIcon}
      controlButton={isWTTAdmin ? <ButtonAsLink as={Link} label="Upload" to={`/engagement/${id}/sds-upload`} /> : null}
    >
      <StatusContainer>
        {ir35Status ? (
          <>
            <IR35Status status={ir35Status}>{ir35Status}</IR35Status>
            <Link to={`/preview/${sdsId}`}>
              <Icon iconSrc={searchIcon} size="md" />
            </Link>
          </>
        ) : (
          <NoDecisionStatusMessage>{NO_STATUS_MESSAGE}</NoDecisionStatusMessage>
        )}
      </StatusContainer>
    </Section>
  );
};

export default DecisionStatusSection;
