export const OUTSIDE = 'Outside IR35';
export const INSIDE = 'Inside IR35';
export const NO_STATUS = 'no_status';
export const NO_STATUS_MESSAGE = 'No Status decision has been issued yet';

interface Statuses {
  [key: string]: {
    value: string;
    label: string;
  };
}

export const statuses = {
  SDS_STATUS: 'sdsStatus',
  IR35_STATUS: 'ir35Status',
};

export const sdsStatuses: Statuses = {
  FINAL: {
    value: 'Final',
    label: 'Final',
  },
  INDICATIVE: {
    value: 'Indicative',
    label: 'Indicative',
  },
};

export const ir35Statuses: Statuses = {
  [OUTSIDE]: {
    value: OUTSIDE,
    label: 'Outside',
  },
  [INSIDE]: {
    value: INSIDE,
    label: 'Inside',
  },
};
