import styled, { css } from 'styled-components';

export const SuggestionListContainer = styled.div`
  ${({ theme: { fontSize, fontFamily, bgColors, fontColors, keyColors } }) => css`
    width: 100%;
    max-width: 185px;
    min-width: 120px;
    max-height: 326px;
    border-radius: 16px;
    background-color: white;
    overflow-y: auto;

    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    & > ul > li {
      display: flex;
      flex-direction: column;
      align-items: start;

      &:hover {
        background-color: ${bgColors.secondary};
      }

      & > span {
        font-family: ${fontFamily.primary};
        font-size: ${fontSize.xxxs};
        color: ${fontColors.secondary};
      }

      & > span:first-child {
        font-size: ${fontSize.xxs};
        color: ${keyColors.first};
      }
    }

    & > ul > li:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    & > ul {
      margin: 0;
      padding: 0;
      overflow: hidden;
      list-style: none;
      max-height: inherit;
    }
  `}
`;

export const SuggestionTitle = styled.div`
  ${({ theme: { fontSize, fontFamily, fontColors } }) => css`
    padding: 10px 16px;
    font-family: ${fontFamily.primary};
    font-size: ${fontSize.xxxs};
    font-weight: bold;
    text-transform: uppercase;
    color: ${fontColors.secondary};
  `}
`;
