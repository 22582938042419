import { EngagementParty } from '../engagement/actionTypes';
import { AllUserDocuments } from '../user/actionTypes';

export const SET_REQUEST_INFO_PARTIES = 'SET_REQUEST_INFO_PARTIES';
export const GET_CONTRACTS_REQUEST = 'GET_CONTRACTS_REQUEST';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_ERROR = 'GET_CONTRACTS_ERROR';
export const GET_REQUIRED_DOCUMENTS_REQUEST = 'GET_REQUIRED_DOCUMENTS_REQUEST';
export const GET_REQUIRED_DOCUMENTS_SUCCESS = 'GET_REQUIRED_DOCUMENTS_SUCCESS';
export const GET_REQUIRED_DOCUMENTS_ERROR = 'GET_REQUIRED_DOCUMENTS_ERROR';
export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';
export const UPLOAD_CONTRACT_REQUEST = 'UPLOAD_CONTRACT_REQUEST';
export const UPLOAD_CONTRACT_SUCCESS = 'UPLOAD_CONTRACT_SUCCESS';
export const UPLOAD_CONTRACT_ERROR = 'UPLOAD_CONTRACT_ERROR';
export const RESET_DOCUMENTS_STATE = 'RESET_DOCUMENTS_STATE';
export const REQUEST_DOCUMENTS_REQUEST = 'REQUEST_DOCUMENTS_REQUEST';
export const REQUEST_DOCUMENTS_SUCCESS = 'REQUEST_DOCUMENTS_SUCCESS';
export const REQUEST_DOCUMENTS_ERROR = 'REQUEST_DOCUMENTS_ERROR';

interface ObjectKeys {
  [key: string]: EngagementParty | undefined;
}

export interface RequestInfoParties extends ObjectKeys {
  requestFrom: EngagementParty;
  upperParty?: EngagementParty;
  lowerParty?: EngagementParty;
}

export interface RequestedDirectlyContract {
  contractType: string;
  contractWith: string;
  contractWithName: string;
}

export interface RequestedInDirectlyContract {
  contractType: string;
  contractFrom: string;
}

export interface UploadedContract {
  id: string;
  name: string;
  contractType: string;
  contractFrom: string;
  contractWith: string;
  contractWithName: string;
}

export interface Contracts {
  requestedDirectly: RequestedDirectlyContract[];
  requestedInDirectly: RequestedInDirectlyContract[];
  uploaded: UploadedContract[];
}

export interface RequestContractPayload {
  contractType: string;
  contractFrom: string;
  contractWith: string;
}

export interface FormValues {
  [key: string]: boolean;
}

export interface UploadedContractFormValues {
  [key: string]: string;
}

export interface DocumentsFormValues {
  personal: FormValues;
  company: FormValues;
}

export interface RequestRequiredDocumentsPayload {
  engagementId: string;
  formValues: DocumentsFormValues;
}

export interface RequestContractsPayload {
  engagementId: string;
  formValues: {
    contracts: FormValues;
    documents: DocumentsFormValues;
  };
}

export interface DeleteDocumentPayload {
  id: string;
  userName: string;
  documentType: string;
  idEngagement: string;
}

export interface UploadContractPayload {
  file: Blob;
  engagementId: string;
  contractType: string;
  contractFrom: string;
  contractWith: string;
}

interface SetRequestInfoParties {
  type: typeof SET_REQUEST_INFO_PARTIES;
  payload: RequestInfoParties;
}

interface GetRequiredDocumentsRequest {
  type: typeof GET_REQUIRED_DOCUMENTS_REQUEST;
}

interface GetRequiredDocumentsSuccess {
  type: typeof GET_REQUIRED_DOCUMENTS_SUCCESS;
  payload: {
    userDocuments: AllUserDocuments;
    documentsFormValues: DocumentsFormValues;
  };
}

interface GetRequiredDocumentsError {
  type: typeof GET_REQUIRED_DOCUMENTS_ERROR;
  payload: {
    error: string;
  };
}

interface RequestDocumentsRequest {
  type: typeof REQUEST_DOCUMENTS_REQUEST;
}

interface RequestDocumentsSuccess {
  type: typeof REQUEST_DOCUMENTS_SUCCESS;
}

interface RequestDocumentsError {
  type: typeof REQUEST_DOCUMENTS_ERROR;
  payload: {
    error: string;
  };
}

interface GetContractsRequest {
  type: typeof GET_CONTRACTS_REQUEST;
}

interface GetContractsSuccess {
  type: typeof GET_CONTRACTS_SUCCESS;
  payload: {
    allContracts: Contracts;
    uploadedContracts: UploadedContractFormValues;
    contractsFormValues: FormValues;
  };
}

interface GetContractsError {
  type: typeof GET_CONTRACTS_ERROR;
  payload: {
    error: string;
  };
}

interface DeleteDocumentRequest {
  type: typeof DELETE_DOCUMENT_REQUEST;
}

interface DeleteContractsSuccess {
  type: typeof DELETE_DOCUMENT_SUCCESS;
}

interface DeleteDocumentError {
  type: typeof DELETE_DOCUMENT_ERROR;
  payload: {
    error: string;
  };
}

interface UploadContractRequest {
  type: typeof UPLOAD_CONTRACT_REQUEST;
  payload: {
    contracts: Contracts;
    contractsFormValues: FormValues;
  };
}

interface UploadContractSuccess {
  type: typeof UPLOAD_CONTRACT_SUCCESS;
}

interface UploadContractError {
  type: typeof UPLOAD_CONTRACT_ERROR;
  payload: {
    error: string;
  };
}

interface ResetDocumentsState {
  type: typeof RESET_DOCUMENTS_STATE;
}

export type documentsTypes =
  | SetRequestInfoParties
  | GetRequiredDocumentsRequest
  | GetRequiredDocumentsSuccess
  | GetRequiredDocumentsError
  | GetContractsRequest
  | GetContractsSuccess
  | GetContractsError
  | DeleteDocumentRequest
  | DeleteContractsSuccess
  | DeleteDocumentError
  | UploadContractRequest
  | UploadContractSuccess
  | UploadContractError
  | ResetDocumentsState
  | RequestDocumentsRequest
  | RequestDocumentsSuccess
  | RequestDocumentsError;
