import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../../../../assets/styles/media';

export const TextareaWPQ = styled.textarea<{ showError: boolean }>`
  ${({ showError, theme: { keyColors } }) => css`
    width: 100%;
    resize: none;
    background-color: #c5c7c9;

    ${mediaMinWidth.xs} {
      margin: 0 0 -8px;
    }

    ${showError &&
    css`
      border-color: ${keyColors.third};
    `}
  `}
`;
