export const GET_FILE_REQUEST = 'GET_FILE_REQUEST';
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS';
export const GET_FILE_ERROR = 'GET_FILE_ERROR';

interface GetFileRequest {
  type: typeof GET_FILE_REQUEST;
}

interface GetFileSuccess {
  type: typeof GET_FILE_SUCCESS;
  payload: {
    fileUrl: string;
  };
}

interface GetFileError {
  type: typeof GET_FILE_ERROR;
  payload: {
    error: string;
  };
}

export type previewTypes = GetFileRequest | GetFileSuccess | GetFileError;
