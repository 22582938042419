import styled, { css } from 'styled-components';
import avatar from '../../../assets/images/avatar.png';
import { mediaMinWidth } from '../../../assets/styles/media';

export const AvatarButton = styled.button`
  ${({ theme: { fontSize, fontColors, fontFamily } }) => css`
    width: 45px;
    height: 45px;
    padding: 0;
    color: ${fontColors.white};
    background: url(${avatar}) center / contain no-repeat;
    border: none;
    font: ${fontSize.xxs} ${fontFamily.secondary};
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }

    ${mediaMinWidth.xl} {
      width: 60px;
      height: 60px;
      font-size: ${fontSize.md};
    }
  `}
`;
