import { get, map, findIndex } from 'lodash/fp';
import { EngagementParty, PartiesInfo, SdsDocumentsInfo } from '../constants/engagement/actionTypes';
import { UserSuggestionSelectedValue } from '../components/inputs/InputSearch';
import { SuggestionUserPayload } from '../constants/user/actionTypes';
import { userTypes } from '../constants/user/userTypes';
import { sdsStatuses } from '../constants/engagement/constants';

export const buildEngagementParty = ({ company, fullName, userName }: UserSuggestionSelectedValue) => {
  const splittedName = fullName && fullName.split(' ');

  return splittedName
    ? {
        company,
        userName,
        firstName: splittedName[0],
        lastName: splittedName[1],
      }
    : null;
};

export const buildFullName = (value?: EngagementParty | SuggestionUserPayload) =>
  value?.firstName && value?.lastName ? `${value.firstName} ${value.lastName}` : '';

export const toCleanUserType = (userType?: string) =>
  userType && userType.includes('intermediary') ? 'intermediary' : userType;

export const toSuggestionOption = (suggestionUserList?: SuggestionUserPayload[]) =>
  map(
    (it) => ({
      title: it.company,
      value: buildFullName(it),
      userName: it.userName,
    }),
    suggestionUserList
  );

export const getSdsId = (sdsDocumentsInfo: SdsDocumentsInfo | null, sdsStatus: string) => {
  if (!sdsStatus) return;

  return sdsStatus === sdsStatuses.INDICATIVE.value
    ? get('indicativeSDS', sdsDocumentsInfo)
    : get('finalSDS', sdsDocumentsInfo);
};

export const getRequestInfoParties = (partiesInfo: PartiesInfo, requestFrom: EngagementParty) => {
  const { ir35LTD, worker, engager, intermediary1, intermediary2, intermediary3, intermediary4 } = partiesInfo;

  const parties = [
    engager,
    intermediary1,
    intermediary2,
    intermediary3,
    intermediary4,
    ir35LTD,
    { ...worker, userType: userTypes.WORKER },
  ].filter((value) => value.firstName);

  const requestFromIndex = findIndex(['userName', requestFrom.userName], parties);

  return {
    requestFrom,
    upperParty: parties[requestFromIndex - 1],
    lowerParty: parties[requestFromIndex + 1],
  };
};

export const getPartyTitle = (party: EngagementParty) => {
  if (party.userType === userTypes.WORKER) {
    const fullName = buildFullName(party);
    return party.company ? `${fullName} - ${party.company}` : fullName;
  }

  return party.company;
};

export const getLowestIntermediary = (partiesInfo: PartiesInfo) => {
  const { ir35LTD, intermediary1, intermediary2, intermediary3, intermediary4 } = partiesInfo;

  const intermediaries = [intermediary1, intermediary2, intermediary3, intermediary4, ir35LTD]
    .filter((value) => value.userName)
    .map(({ userName }) => userName);

  return intermediaries[intermediaries.length - 1];
};
