import styled, { css } from 'styled-components';
import { mediaMinWidth } from '../../../assets/styles/media';
import { FormRow as Row } from '../index.style';

export const GrettingText = styled.p`
  margin: 0 0 20px;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.secondary};
  font-size: ${({ theme: { fontSize } }) => fontSize.lg};
  color: #bdbdbd;

  ${mediaMinWidth.xl} {
    margin: 0 0 35px;
    font-size: ${({ theme: { fontSize } }) => fontSize.xxxl};
  }
`;

export const Subtitle = styled.p`
  ${({ theme: { fontSize, fontColors, fontFamily } }) => css`
    margin: 0 0 20px;
    color: ${fontColors.primary};
    font: ${fontSize.sm} ${fontFamily.primary};
    font-weight: 300;

    ${mediaMinWidth.xl} {
      margin: 0 0 30px;
      font-size: ${({ theme: { fontSize } }) => fontSize.md};
    }
  `}
`;

export const AgreeDescription = styled.p`
  ${({ theme: { fontSize, fontFamily } }) => css`
    margin: 0 0 25px;
    color: #151515;
    font: ${fontSize.xxs} ${fontFamily.primary};
    font-weight: bold;
    text-align: center;

    ${mediaMinWidth.xs} {
      text-align: left;
    }
  `}
`;

export const OuterLink = styled.a`
  color: #29539a;
`;

export const FormContent = styled.div`
  margin: 30px 0 0;
  text-align: center;

  ${mediaMinWidth.xs} {
    padding: 0 95px 0;
  }

  ${mediaMinWidth.xl} {
    margin: 50px 0 0;
  }
`;

export const FormRow = styled(Row)`
  && > * {
    margin: 5px 10px;
  }

  ${mediaMinWidth.xs} {
    && > * {
      margin: 0;
    }
  }
`;
