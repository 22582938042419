import {
  AUTH_REQUEST_FAIL,
  authTypes,
  CHECK_ACCESS_TO_ENGAGEMENT_ERROR,
  CHECK_ACCESS_TO_ENGAGEMENT_REQUEST,
  CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS,
  GET_AGREEMENT_STATUS,
  GET_AUTHENTICATED_USER_REQUEST,
  GET_AUTHENTICATED_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGOUT,
  SET_AGREEMENT_STATUS,
  SET_LOGIN_ERROR,
  UPDATE_AGREEMENT_STATUS_REQUEST,
  UPDATE_AGREEMENT_STATUS_SUCCESS,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS,
  CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL,
} from '../../constants/auth/actionTypes';

export interface AuthStore {
  isLoading: boolean;
  ticket: string;
  errorMessage?: string;
  agreementStatus: boolean;
  isAgreementLoading: boolean;
  isUserLoading: boolean;
  isUserConnectedToEngagement: boolean;
}

const initialState: AuthStore = {
  agreementStatus: false,
  errorMessage: '',
  ticket: '',
  isLoading: false,
  isAgreementLoading: true,
  isUserLoading: true,
  isUserConnectedToEngagement: true,
};

export default (state: AuthStore = initialState, action: authTypes): AuthStore => {
  switch (action.type) {
    case GET_AGREEMENT_STATUS: {
      return { ...state, isAgreementLoading: true };
    }
    case GET_AUTHENTICATED_USER_REQUEST: {
      return { ...state, isUserLoading: true };
    }
    case CHECK_ACCESS_TO_ENGAGEMENT_REQUEST:
    case UPDATE_AGREEMENT_STATUS_REQUEST:
    case CHANGE_PASSWORD_AFTER_FIRST_LOGIN_REQUEST: {
      return { ...state, isLoading: true };
    }
    case UPDATE_AGREEMENT_STATUS_SUCCESS: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case CHANGE_PASSWORD_AFTER_FIRST_LOGIN_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case GET_AUTHENTICATED_USER_SUCCESS: {
      return { ...state, isUserLoading: false };
    }
    case SET_AGREEMENT_STATUS: {
      return { ...state, ...action.payload, isAgreementLoading: false, errorMessage: '' };
    }
    case CHECK_ACCESS_TO_ENGAGEMENT_SUCCESS: {
      return { ...state, isLoading: false, isUserConnectedToEngagement: true };
    }
    case AUTH_REQUEST_FAIL: {
      return { ...state, ...action.payload, isLoading: false, isUserLoading: false };
    }
    case LOGIN_USER_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case SET_LOGIN_ERROR: {
      return { ...state, ...action.payload };
    }
    case CHECK_ACCESS_TO_ENGAGEMENT_ERROR: {
      return { ...state, ...action.payload, isLoading: false, isUserConnectedToEngagement: false };
    }
    case CHANGE_PASSWORD_AFTER_FIRST_LOGIN_FAIL: {
      return { ...state, ...action.payload, isLoading: false };
    }
    case LOGOUT: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};
