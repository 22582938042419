import {
  RequestInfoParties,
  Contracts,
  DocumentsFormValues,
  FormValues,
  SET_REQUEST_INFO_PARTIES,
  GET_CONTRACTS_REQUEST,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACTS_ERROR,
  GET_REQUIRED_DOCUMENTS_REQUEST,
  GET_REQUIRED_DOCUMENTS_SUCCESS,
  GET_REQUIRED_DOCUMENTS_ERROR,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_ERROR,
  UPLOAD_CONTRACT_REQUEST,
  UPLOAD_CONTRACT_SUCCESS,
  UPLOAD_CONTRACT_ERROR,
  UploadedContractFormValues,
  RESET_DOCUMENTS_STATE,
  REQUEST_DOCUMENTS_REQUEST,
  REQUEST_DOCUMENTS_SUCCESS,
  REQUEST_DOCUMENTS_ERROR,
} from '../../constants/documents/actionTypes';

import { AllUserDocuments } from '../../constants/user/actionTypes';

export const setRequestInfoParties = (payload: RequestInfoParties) => ({
  type: SET_REQUEST_INFO_PARTIES,
  payload,
});

export const getContractsRequest = () => ({
  type: GET_CONTRACTS_REQUEST,
});

export const getContractsSuccess = (payload: {
  allContracts: Contracts;
  uploadedContracts: UploadedContractFormValues;
  contractsFormValues: FormValues;
}) => ({
  type: GET_CONTRACTS_SUCCESS,
  payload,
});

export const getContractsError = (error: string) => ({
  type: GET_CONTRACTS_ERROR,
  payload: {
    error,
  },
});

export const getRequiredDocumentsListRequest = () => ({
  type: GET_REQUIRED_DOCUMENTS_REQUEST,
});

export const getRequiredDocumentsListSuccess = (payload: {
  userDocuments: AllUserDocuments;
  documentsFormValues: DocumentsFormValues;
}) => ({
  type: GET_REQUIRED_DOCUMENTS_SUCCESS,
  payload,
});

export const getRequiredDocumentsListSuccessError = (error: string) => ({
  type: GET_REQUIRED_DOCUMENTS_ERROR,
  payload: {
    error,
  },
});

export const requestDocumentsRequest = () => ({
  type: REQUEST_DOCUMENTS_REQUEST,
});

export const requestDocumentsSuccess = () => ({
  type: REQUEST_DOCUMENTS_SUCCESS,
});

export const requestDocumentsError = (error: string) => ({
  type: REQUEST_DOCUMENTS_ERROR,
  payload: {
    error,
  },
});

export const deleteDocumentRequest = () => ({
  type: DELETE_DOCUMENT_REQUEST,
});

export const deleteDocumentSuccess = () => ({
  type: DELETE_DOCUMENT_SUCCESS,
});

export const deleteDocumentError = (error: string) => ({
  type: DELETE_DOCUMENT_ERROR,
  payload: {
    error,
  },
});

export const uploadContractRequest = () => ({
  type: UPLOAD_CONTRACT_REQUEST,
});

export const uploadContractSuccess = () => ({
  type: UPLOAD_CONTRACT_SUCCESS,
});

export const uploadContractError = (error: string) => ({
  type: UPLOAD_CONTRACT_ERROR,
  payload: {
    error,
  },
});

export const resetDocumentsState = () => ({
  type: RESET_DOCUMENTS_STATE,
});
