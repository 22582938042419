import React from 'react';
import { IconContainer } from './index.styles';

interface Props {
  iconSrc: string;
  hideIcon?: boolean;
  size?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  onClick?: (a: any) => void;
}

export type ImageSize = { imgHeight: number | string; imgWidth: number; minWidth?: number };

const defaultSizes = {
  xxxs: { imgHeight: 'auto', imgWidth: 0.75, minWidth: 0.75 },
  xxs: { imgHeight: 1.5, imgWidth: 0.938 },
  xs: { imgHeight: 'auto', imgWidth: 1.063 },
  sm: { imgHeight: 'auto', imgWidth: 1.125 },
  md: { imgHeight: 'auto', imgWidth: 1.688 },
  lg: { imgHeight: 'auto', imgWidth: 2.188 },
  xl: { imgHeight: 'auto', imgWidth: 2.813 },
  xxl: { imgHeight: 'auto', imgWidth: 3.75, minWidth: 3.2 },
};

const Icon = ({ iconSrc, hideIcon, size, ...props }: Props) => (
  <IconContainer hideIcon={hideIcon} {...(defaultSizes[size || 'xs'] as ImageSize)}>
    <img src={iconSrc} alt="iconSrc" {...props} />
  </IconContainer>
);

export default Icon;
