// eslint-disable-next-line import/named
import { DefaultTheme } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

import { grid } from '../media';

export const mainTheme: DefaultTheme = {
  keyColors: {
    first: '#82459b',
    second: '#f6a026',
    third: '#ef434e',
    darkGrey: '#4a4d4f',
    lightGrey: '#6d7278',
  },

  fontColors: {
    primary: '#000000',
    secondary: '#9b9b9b',
    tertiary: '#1a2860',
    white: '#fff',
    darkGrey: '#4b4b4a',
  },

  fontFamily: {
    primary: `'FaricyNew', Arial, sans-serif`,
    secondary: `'EbonyHv', Arial, sans-serif`,
  },

  bgColors: {
    primary: '#ffffff',
    secondary: '#f3f5f7',
  },

  borderColors: {
    primary: '#a26d81',
    secondary: '#4f4854',
    black: '#000',
    lightGrey: '#d9dde3',
    grey: '#cbd0d8',
    darkGrey: '#838a9d',
  },

  outlineColor: '#a26d818a',
  gradient: {
    toRight: 'linear-gradient(to right, #82459b, #ef434e, #f6a026)',
    toLeft: 'linear-gradient(to left, #82459b, #ef434e, #f6a026)',
    toTop: 'linear-gradient(to top, #82459b, #ef434e, #f6a026)',
    toBottom: 'linear-gradient(to bottom, #82459b, #ef434e, #f6a026)',
    toRightDeg: 'linear-gradient(42deg, #54345e 23%, #dd404b 51%, #ea9939 82%)',
  },

  borderRadius: '16px',
  borderWidth: {
    xxs: '1px',
    xs: '2px',
    sm: '5px',
    md: '7px',
  },
  borderStyle: 'solid',

  transition: 'background-color 0.3s, color 0.3s, opacity 0.3s',

  fontSize: {
    xxxs: '0.875rem',
    xxs: '1rem',
    xs: '1.125rem',
    sm: '1.313rem',
    md: '1.5rem',
    lg: '1.688rem',
    xl: '2rem',
    xxl: '2.625rem',
    xxxl: '3.125rem',
  },

  dialogMaxSize: {
    xs: 474,
    sm: 700,
    md: 784,
    lg: 900,
  },

  inputMaxSize: {
    xs: 190,
    sm: 220,
    md: 250,
    lg: 290,
  },

  buttonMaxSize: {
    xs: 150,
    sm: 165,
    md: 200,
  },
};

export const customMaterialBreakpoints = createMuiTheme({
  breakpoints: {
    values: {
      xs: grid.xs,
      sm: grid.sm,
      md: grid.md,
      lg: grid.lg,
      xl: grid.xl,
      xxl: grid.xxl,
    },
  },
});
