import React, { ExoticComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../redux/reducers';
import Loader from '../../components/Loader';
import AccessDeniedError from '../../components/AccessDeniedError';
import { checkAccessToEngagement } from '../../redux/auth/thunks';

const withCheckAccessToEngagement = (Component: React.ComponentType | ExoticComponent | React.FunctionComponent) => (
  props: any
) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector<AppState, boolean>(({ auth }) => auth.isLoading);
  const isUserConnectedToEngagement = useSelector<AppState, boolean>(({ auth }) => auth.isUserConnectedToEngagement);

  useEffect(() => {
    if (checkAccessToEngagement) {
      dispatch(checkAccessToEngagement(id));
    }
  }, [id]);

  if (isLoading) {
    return (
      <Loader isLoading={isLoading}>
        <Component {...props} />
      </Loader>
    );
  }

  return isUserConnectedToEngagement ? <Component {...props} /> : <AccessDeniedError />;
};

export default withCheckAccessToEngagement;
