import React from 'react';

import Header from '../../Header';
import { BaseContainer, ColumnContainer } from '../../containers';
import Footer from '../../Footer';

interface Props {
  children: React.ReactNode;
}

const BaseLayout = ({ children }: Props) => (
  <BaseContainer>
    <ColumnContainer>
      <Header />
      {children}
      <Footer />
    </ColumnContainer>
  </BaseContainer>
);

export default BaseLayout;
