import { Action } from 'redux';
import { pick, debounce } from 'lodash/fp';
import { AppState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import {
  ChangePasswordPayload,
  ExtendedUser,
  NewUser,
  UploadRequiredDocumentPayload,
} from '../../constants/user/actionTypes';
import {
  setUserData,
  getUserData,
  userRequestFail,
  updateUserRequest,
  updateUserSuccess,
  inviteUserRequest,
  inviteUserSuccess,
  inviteUserError,
  getSuggestionUserListRequest,
  getSuggestionUserListSuccess,
  getSuggestionUserListError,
  setFullUserData,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  getUserDocumentsRequest,
  getUserDocumentsSuccess,
  getUserDocumentsError,
  uploadRequireDocumentRequest,
  uploadRequireDocumentSuccess,
  uploadRequireDocumentError,
} from './actions';
import api from '../../services/api';
import { userInfoTypes } from '../../constants/user/userTypes';
import { USERNAME } from '../../constants/auth';
import { addToPartyEngagement } from '../engagement/thunks';
import { logoutByStatusCode } from '../../utils/auth';
import { getRequiredDocumentsList } from '../documents/thunks';

export const getUserInfo = (
  infoType = userInfoTypes.ALL,
  type?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(getUserData());

    const { data } = await api.people.getUser(infoType);

    dispatch(infoType === userInfoTypes.ALL ? setFullUserData(data as ExtendedUser) : setUserData(data));
  } catch (e) {
    dispatch(userRequestFail('Cannot get user data'));
    if (type === 'auth') {
      throw e;
    }
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const updateUser = (values: ExtendedUser): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(updateUserRequest());

    const { data } = await api.people.updateUser(values);

    dispatch(updateUserSuccess(data));
  } catch (e) {
    dispatch(userRequestFail('User cannot be updated!'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const inviteUser = (
  user: NewUser,
  userRole?: string,
  engagementId?: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(inviteUserRequest());

    await api.people.createUser(user);

    dispatch(inviteUserSuccess());
    if (userRole && engagementId) {
      dispatch(
        addToPartyEngagement(
          userRole,
          { ...pick(['firstName', 'lastName', 'company'], user), userName: user.email },
          engagementId
        )
      );
    }
  } catch (e) {
    dispatch(inviteUserError('User cannot be added!'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

const innerGetSuggestionUserList = async (
  searchString: string,
  userType: string,
  userRole: string,
  dispatch: Function
) => {
  try {
    dispatch(getSuggestionUserListRequest(userType));

    const { data } = await api.people.searchUser(searchString, userType);

    dispatch(getSuggestionUserListSuccess(data, userRole));
  } catch (e) {
    dispatch(getSuggestionUserListError('Cannot find any user', userType));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

const debounceGetSuggestionUserList = debounce(1000, innerGetSuggestionUserList);

export const getSuggestionUserList = (
  searchString: string,
  userType: string,
  userRole: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> =>
  debounceGetSuggestionUserList(searchString, userType, userRole, dispatch);

export const getUserDocumentsList = (userName?: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getUserDocumentsRequest());

    const { data } = await api.documents.getUserDocumentsList(userName);

    dispatch(getUserDocumentsSuccess(data));
  } catch (e) {
    dispatch(getUserDocumentsError('Cannot find any user documents'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const changeUserPassword = (
  changePasswordPayload: ChangePasswordPayload
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(changePasswordRequest());

    await api.person.changePassword(changePasswordPayload);

    dispatch(changePasswordSuccess());
  } catch (e) {
    const message = e.message.includes('401') ? 'Old password is wrong' : 'Cannot change password';
    dispatch(changePasswordError(message));
  }
};

export const uploadUserRequiredDocument = ({
  userName,
  documentType,
  file,
}: UploadRequiredDocumentPayload): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(uploadRequireDocumentRequest());
    const loggedInUserName = localStorage.getItem(USERNAME);

    const data = new FormData();
    data.append('userName', userName || loggedInUserName || '');
    data.append('fileData', file);
    data.append('documentType', documentType);

    await api.documents.uploadUserRequiredDocument(data);
    dispatch(uploadRequireDocumentSuccess());
    !userName ? dispatch(getUserDocumentsList()) : dispatch(getRequiredDocumentsList(userName));
  } catch (e) {
    dispatch(uploadRequireDocumentError('Cannot upload document'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};
