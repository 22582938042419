import styled, { css } from 'styled-components';

export const StyledMainWrapper = styled.div`
  ${({ theme: { bgColors } }) => css`
    margin: 0 auto;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${bgColors.primary};
  `}
`;
