import styled, { css } from 'styled-components';
import { ErrorMessage as Message } from 'formik';

const styles = css`
  position: ${(props: { isNotAbsolute?: boolean }) => (props.isNotAbsolute ? 'static' : 'absolute')};
  bottom: -21px;
  left: 10px;
  color: ${({ theme: { keyColors } }) => keyColors.third};
  font-size: ${({ theme: { fontSize } }) => fontSize.xxs};
`;

export const ErrorMessage = styled(Message)`
  ${styles}
`;

export const CustomErrorMessage = styled.span`
  ${styles}
`;
