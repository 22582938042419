import { Action } from 'redux';
import { AppState } from '../reducers';
import { ThunkAction } from 'redux-thunk';
import { EngagementParty, EngagementPayload, SDSFilePayload } from '../../constants/engagement/actionTypes';
import {
  getEngagementRequest,
  getEngagementSuccess,
  createEngagementRequest,
  createEngagementSuccess,
  updateEngagementRequest,
  updateEngagementSuccess,
  deleteEngagementRequest,
  deleteEngagementSuccess,
  setEngagementError,
  deletePartyFromEngagementRequest,
  deletePartyFromEngagementSuccess,
  deletePartyFromEngagementError,
  addToPartyEngagementRequest,
  addToPartyEngagementSuccess,
  addToPartyEngagementError,
  uploadSDSFileRequest,
  uploadSDSFileSuccess,
  uploadAttachedDocumentRequest,
  uploadAttachedDocumentSuccess,
  uploadAttachedDocumentError,
  deleteAttachedDocumentRequest,
  deleteAttachedDocumentSuccess,
  deleteAttachedDocumentError,
  finaliseEngagementRequest,
  finaliseEngagementSuccess,
  finaliseEngagementError,
  toggleFinaliseSDSModal,
} from './actions';
import { history } from '../../history';
import api from '../../services/api';
import { ROUTES } from '../../constants/routing';
import { statuses } from '../../constants/engagement/constants';
import { logoutByStatusCode } from '../../utils/auth';

export const getEngagement = (id: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(getEngagementRequest());

    const { data } = await api.engagement.getEngagement(id);

    dispatch(getEngagementSuccess(data));
  } catch (e) {
    dispatch(setEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response?.status);
  }
};

export const createEngagement = (
  engagementPayload: EngagementPayload
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(createEngagementRequest());

    const {
      data: { id },
    } = await api.engagement.createEngagement(engagementPayload);

    dispatch(createEngagementSuccess(id));
    history.push(`/engagement/${id}/supply-chain`);
  } catch (e) {
    dispatch(setEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const updateEngagement = (
  engagementPayload: EngagementPayload
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(updateEngagementRequest());

    await api.engagement.updateEngagement(engagementPayload);

    dispatch(updateEngagementSuccess());
  } catch (e) {
    dispatch(setEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const deleteEngagement = (id: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(deleteEngagementRequest());

    await api.engagement.deleteEngagement(id);

    dispatch(deleteEngagementSuccess());
    history.push(ROUTES.DASHBOARD);
  } catch (e) {
    dispatch(setEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const deletePartyFromEngagement = (
  engagementPartyType: string,
  engagementId: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(deletePartyFromEngagementRequest());

    await api.engagement.deleteUserFromEngagement(engagementId, engagementPartyType);

    dispatch(deletePartyFromEngagementSuccess(engagementPartyType));
  } catch (e) {
    dispatch(deletePartyFromEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const addToPartyEngagement = (
  engagementPartyType: string,
  engagementParty: EngagementParty,
  engagementId: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(addToPartyEngagementRequest());

    await api.engagement.addUserToEngagement(engagementId, engagementParty.userName, engagementPartyType);

    dispatch(addToPartyEngagementSuccess(engagementPartyType, engagementParty));
  } catch (e) {
    dispatch(addToPartyEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const uploadSDSFile = (
  id: string,
  { sdsStatus, ir35Status, sdsFile }: SDSFilePayload
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(uploadSDSFileRequest());

    const data = new FormData();
    data.append('engagementId', id);
    data.append(statuses.SDS_STATUS, sdsStatus);
    data.append(statuses.IR35_STATUS, ir35Status);
    data.append('fileData', new Blob([sdsFile.arrayBuffer], { type: sdsFile.type }));

    await api.engagement.uploadSDSFile(data);

    dispatch(uploadSDSFileSuccess());
    dispatch(getEngagement(id));
  } catch (e) {
    dispatch(setEngagementError('Error connection'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const uploadAttachedDocument = (
  engagementId: string,
  file: File
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(uploadAttachedDocumentRequest());

    const data = new FormData();
    data.append('fileData', file);
    data.append('engagementId', engagementId);

    await api.documents.uploadEngagementDocument(data);
    dispatch(uploadAttachedDocumentSuccess());
    dispatch(getEngagement(engagementId));
  } catch (e) {
    dispatch(uploadAttachedDocumentError('Cannot upload document'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const deleteEngagementDocument = (
  documentId: string,
  engagementId: string
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch): Promise<void> => {
  try {
    dispatch(deleteAttachedDocumentRequest());

    await api.documents.deleteDocument(documentId);
    dispatch(deleteAttachedDocumentSuccess());
    dispatch(getEngagement(engagementId));
  } catch (e) {
    dispatch(deleteAttachedDocumentError('Cannot delete document'));
    logoutByStatusCode(dispatch, e.response.status);
  }
};

export const finaliseEngagement = (engagementId: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(finaliseEngagementRequest());

    await api.engagement.finaliseEngagement(engagementId);

    dispatch(finaliseEngagementSuccess());
  } catch (e) {
    dispatch(finaliseEngagementError('Cannot finalise document'));
    logoutByStatusCode(dispatch, e.response.status);
  } finally {
    dispatch(toggleFinaliseSDSModal());
  }
};

export const acceptEngagement = (sdsId: string): ThunkAction<void, AppState, null, Action<string>> => async (
  dispatch
): Promise<void> => {
  try {
    dispatch(finaliseEngagementRequest());

    await api.engagement.acceptSDSFile(sdsId);

    dispatch(finaliseEngagementSuccess());
  } catch (e) {
    dispatch(finaliseEngagementError('Cannot accept document'));
    logoutByStatusCode(dispatch, e.response.status);
  } finally {
    dispatch(toggleFinaliseSDSModal());
  }
};
