import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  & button {
    margin: 0 15px;
  }
`;
