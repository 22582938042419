import styled from 'styled-components';

import { mediaMinWidth } from '../../../assets/styles/media';
import { FormRow as Row } from '../index.styles';

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;

  button {
    margin: 0 auto 25px 0;

    ${mediaMinWidth.xs} {
      margin: 0 0 25px auto;
    }
  }
`;

export const FormRow = styled(Row)`
  ${mediaMinWidth.lg} {
    margin: 0 0 45px;
  }

  ${mediaMinWidth.xxl} {
    margin: 0 0 60px;
  }
`;

export const FormContent = styled.div`
  padding-top: 20px;
`;
